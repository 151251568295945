import { FC, useCallback, useMemo }         from "react";
import clsx                                 from "clsx";
import { useHistory, useLocation }          from "react-router-dom";
import { getAnalyticsCategory }             from "@/analytics/analytics";
import Divider                              from "@/designSystem/Divider/Divider";
import Link                                 from "@/designSystem/Link/Link";
import { CONSTANTS }                        from "@/helpers/constants";
import { capitalizeFirstLetter }            from "@/helpers/index";
import { useAnalyticsTracking }             from "@/hooks/useAnalyticsTracking";
import { ModalQrCode }                      from "@/molecules/UnistallExtensionModal/UninstallExtensionModal";
import { WrapperModal }                     from "@/molecules/WrapperModal/WrapperModal";
import { ReactComponent as FreespokeLogo }  from "@/static/icons/freespoke_torch.svg";
import { ReactComponent as ChromeLogo }     from "@/static/logo/ChromeLogo.svg";
import { ReactComponent as BraveLogo }      from "@/static/logo/braveLogo.svg";
import { ReactComponent as BrowserDivider } from "@/static/logo/browserDivider.svg";
import { ReactComponent as EdgeLogo }       from "@/static/logo/edgeLogo.svg";
import { ReactComponent as FirefoxLogo }    from "@/static/logo/firefoxLogo.svg";
import BraveVideo                           from "@/static/video/extension/freespoke-brave-ext-2024-08-14_b.mp4";
import ChromeVideo                          from "@/static/video/extension/freespoke-chrome-ext-2024-08-14_b.mp4";
import EdgeVideo                            from "@/static/video/extension/freespoke-edge-ext-2024-08-14_b.mp4";
import FirefoxVideo                         from "@/static/video/extension/freespoke-firefox-ext-2024-08-14_b.mp4";
import "./ExtensionModalVariants.less";

interface ExtensionModalVariantsProps {
    className?: string;
}

const EXTENSIONS = {
    chrome: {
        logo: ChromeLogo,
        video: ChromeVideo,
        url: CONSTANTS.LINKS.CHROME_EXTENSION,
    },
    firefox: {
        logo: FirefoxLogo,
        video: FirefoxVideo,
        url: CONSTANTS.LINKS.FIREFOX_EXTENSION,
    },
    brave: {
        logo: BraveLogo,
        video: BraveVideo,
        url: CONSTANTS.LINKS.CHROME_EXTENSION,
    },
    edge: {
        logo: EdgeLogo,
        video: EdgeVideo,
        url: CONSTANTS.LINKS.CHROME_EXTENSION,
    },
};

export const ExtensionModalVariants: FC<ExtensionModalVariantsProps> = ({ className }) => {
    const history = useHistory();
    const location = useLocation();
    const hash = location.hash;
    const extensionType = getExtensionTypeFromHash(hash);
    const { trackEvent } = useAnalyticsTracking();
    const pageName = useMemo(() => getAnalyticsCategory(location.pathname), [location.pathname]);

    const Logo = EXTENSIONS[extensionType].logo;

    const onClose = () => {
        history.push({ ...location, hash: "" });
        trackEvent({
            action: `${pageName} make freespoke search your default pop up close click`,
            name: "click",
        });
    };

    const handleContinueClick = useCallback(() => {
        trackEvent({
            action: `${pageName} make freespoke search your default pop up continue to add click`,
            name: "click",
        });
    }, [pageName, trackEvent]);

    return (
        <WrapperModal isModalVisible onClose={onClose}>
            <div className={clsx("ExtensionModalVariants", className)}>
                <div className="logo">
                    <FreespokeLogo className="freespoke-logo" />
                    <BrowserDivider className={clsx("browser-divider", extensionType && extensionType)} />
                    <Logo className={clsx("browser-logo", extensionType && extensionType)} />
                </div>
                <h3 className="title">Make Freespoke search your default</h3>
                <video controls width="100%" height={"auto"} className="video" autoPlay loop muted>
                    <source src={EXTENSIONS[extensionType].video} type="video/mp4" />
                </video>
                <Link className="continue-link" href={EXTENSIONS[extensionType].url} onClick={handleContinueClick}>
                    Continue to add Freespoke to {capitalizeFirstLetter(extensionType)}
                </Link>
                <Divider className="divider" />
                <ModalQrCode />
            </div>
        </WrapperModal>
    );
};

function getExtensionTypeFromHash(hash: string) {
    if (hash.includes("firefox")) {
        return "firefox";
    }
    if (hash.includes("brave")) {
        return "brave";
    }
    if (hash.includes("edge")) {
        return "edge";
    }
    return "chrome";
}
