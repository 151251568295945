import React, { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState }                        from "react";
import { useMatomo }                                                                                      from "@datapunt/matomo-tracker-react";
import { InputRef }                                                                                       from "antd";
import useBreakpoint                                                                                      from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                                                                               from "clsx";
import { useHistory, useLocation }                                                                        from "react-router-dom";
import { animated, config, useSpring, useTransition }                                                     from "react-spring";
import { AnalyticsMeta, IWebLocationTypeaheadResult as IWebTypeaheadLocationResult, IWebTypeaheadResult } from "@/@types/custom";
import useGetSuggestions                                                                                  from "@/api/useGetSearchTopics";
import useGetTypeahead                                                                                    from "@/api/useGetTypeahead";
import { A_B_VERSION }                                                                                    from "@/config";
import Avatar                                                                                             from "@/designSystem/Avatar/Avatar";
import Card                                                                                               from "@/designSystem/Card/Card";
import CustomSearch                                                                                       from "@/designSystem/Icons/CustomSearch";
import CustomSubmit                                                                                       from "@/designSystem/Icons/CustomSubmit";
import Input                                                                                              from "@/designSystem/Input/Input";
import Link                                                                                               from "@/designSystem/Link/Link";
import Notification                                                                                       from "@/designSystem/Notification/Notification";
import Title                                                                                              from "@/designSystem/Title/Title";
import { createUpdatedURL, getQueryParamsFromUrl }                                                        from "@/helpers/index";
import { useAppBar }                                                                                      from "@/hooks/useAppBar";
import { ReactComponent as LocationPinOutlinedIcon }                                                      from "@/static/icons/location_address.svg";
import { ReactComponent as SearchIcon }                                                                   from "@/static/icons/search.svg";
import "./SearchBar.less";

interface SearchBarProps {
    className?: string;
    dropdown?: boolean;
    placeholder?: string;
    parent: "homepage" | "header";
    analyticsMeta?: AnalyticsMeta;
    autoFocusOnMount?: boolean;
    setIsSearchBarFocused?: (isFocused: boolean) => void;
}

interface SearchObject {
    q: string;
    place_id?: string;
}

const SearchBar: FC<React.PropsWithChildren<SearchBarProps>> = ({
    className,
    dropdown = true,
    placeholder = "Try a search...",
    parent,
    analyticsMeta,
    autoFocusOnMount,
    setIsSearchBarFocused,
}) => {
    const [showSuggestions, setShowSuggestions] = useState<boolean | null>(null);
    const [searchObject, setSearchObject] = useState<SearchObject>({ q: "" });
    const [focusedItemIndex, setFocusedItemIndex] = useState<number | null>(null);
    const inputContainerRef = useRef() as RefObject<HTMLDivElement>;
    const inputRef = useRef<InputRef | null>(null);
    const dropdownRef = useRef() as RefObject<HTMLDivElement>;
    const history = useHistory();
    const location = useLocation();
    const { trackEvent } = useMatomo();
    const { closeHamburger } = useAppBar();

    const { data: suggestionsData } = useGetSuggestions();
    const { data: typeaheadData } = useGetTypeahead(showSuggestions ? searchObject.q : null);

    const searchValueIsShort = !searchObject || searchObject.q.length < 3;
    const hasPopularSearchTerms = !!suggestionsData?.popular_search_terms?.length;
    const hasTypeahead = !!typeaheadData?.suggestions?.length;
    const hasTopics = searchValueIsShort && !!suggestionsData?.topics?.length;
    const transitions = useTransition(
        showSuggestions && ((searchObject.q && hasTypeahead) || (searchValueIsShort && (hasTopics || hasPopularSearchTerms))),
        {
            from: { opacity: 0, transform: "scale(0)" },
            enter: { opacity: 1, transform: "scale(1)" },
            leave: { opacity: 0, transform: "scale(0)" },
            config: config.default,
        }
    );
    const selectedTab = useMemo(() => {
        if (location.pathname.includes("/search/news")) {
            return "news";
        } else if (location.pathname.includes("/search/images")) {
            return "images";
        } else if (location.pathname.includes("/search/videos")) {
            return "videos";
        } else if (location.pathname.includes("/search/shopping")) {
            return "shopping";
        } else if (location.pathname.includes("/search/maps")) {
            return "maps";
        } else {
            return "web";
        }
    }, [location.pathname]);

    useEffect(() => {
        setShowSuggestions(false);
        const params = getQueryParamsFromUrl(location.search);
        if (params.q && params.q !== searchObject.q) {
            setSearchObject({ q: params.q, place_id: params.place_id || undefined });
        } else if (!params.q) {
            setSearchObject({ q: "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    // reset focused item index on data change
    useEffect(() => {
        setFocusedItemIndex(null);
    }, [typeaheadData, searchObject.q]);

    // update text of search bar whenever you focus on a typeahead item
    useEffect(() => {
        if (focusedItemIndex === null || focusedItemIndex === undefined || !typeaheadData?.suggestions || !inputRef.current) {
            return;
        }

        const newQ = typeaheadData.suggestions?.[focusedItemIndex]?.suggest ?? "";
        setSearchObject(prev => {
            // prevent race condition
            setTimeout(() => {
                if (inputRef?.current?.input) {
                    inputRef.current.input.value = newQ;
                }
            }, 0);
            return {
                q: prev.q,
                place_id: (typeaheadData?.suggestions?.[focusedItemIndex] as Partial<IWebTypeaheadLocationResult>)?.place_id,
            };
        });
    }, [focusedItemIndex, typeaheadData?.suggestions]);

    // Close dropdown if user clicks outside of search bar or dropdown div
    useEffect(() => {
        if (autoFocusOnMount) {
            setTimeout(() => {
                inputRef?.current?.focus();
                setIsSearchBarFocused?.(true);
            }, 200);
        }

        if (!autoFocusOnMount) {
            inputRef?.current?.blur();
        }

        const handleClickOutside = (event: any) => {
            if (
                inputContainerRef.current &&
                !inputContainerRef.current.contains(event.target) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setShowSuggestions(null);
                setIsSearchBarFocused?.(false);
            }
        };

        document.addEventListener("click", handleClickOutside, false);

        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reportClick = useCallback(
        (name: "popular searches" | "popular topic", action: string | undefined) => () => {
            // click search term from search bar drop down menu, action is the search term
            trackEvent({
                category: A_B_VERSION === "a" ? "search bar" : "search barb",
                name: action || "missing name",
                action: `search bar ${name || "missing action"}`,
            });
            closeHamburger && closeHamburger();
            setShowSuggestions(false);
        },
        [closeHamburger, trackEvent]
    );

    const reportTypeaheadClick = useCallback(
        (suggest: string | undefined, placeId: string | undefined) => () => {
            // click search term from search bar drop down menu, action is the search term
            trackEvent({
                category: A_B_VERSION === "a" ? "search bar" : "search barb",
                name: `${placeId ? "Location - " : "General - "} ${suggest}`,
                action: "search bar typeahead click",
            });
            closeHamburger && closeHamburger();
            setShowSuggestions(false);
        },
        [closeHamburger, trackEvent]
    );

    const onSubmit = useCallback(
        (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (searchObject.q) {
                // this is needed when user selects a dropdown suggestion via 'up/down' arrows or 'tab' and clicks 'enter' on the keyboard
                const dropdownItemSelected = focusedItemIndex !== null && hasTypeahead && typeaheadData?.suggestions?.[focusedItemIndex]?.suggest;

                const trimmed = dropdownItemSelected ? dropdownItemSelected?.trim() : searchObject.q.trim();

                const queryParamsObj = new URLSearchParams({ q: trimmed, ...(searchObject.place_id && { place_id: searchObject?.place_id }) });

                // Event story023
                trackEvent({
                    category: analyticsMeta?.category ? analyticsMeta.category : "searchbar",
                    action: `search bar search - ${analyticsMeta?.action || parent}`,
                    name: "search",
                });

                trackEvent({
                    category: A_B_VERSION === "a" ? "search bar" : "search barb",
                    action: `search bar search - ${analyticsMeta?.action || parent}`,
                    name: "search",
                });

                // Route to query
                if (selectedTab === "maps") {
                    const newUrl = createUpdatedURL({ q: trimmed, place_id: null, placeId: null }, "/search/maps", location.search);
                    if (newUrl === location.pathname + location.search) {
                        history.go(0);
                    } else {
                        history.push(newUrl);
                    }
                } else if (selectedTab) {
                    const newUrl = `/search/${selectedTab}?${queryParamsObj}`;

                    if (newUrl === location.pathname + location.search) {
                        history.go(0);
                    } else {
                        history.push(newUrl);
                    }
                } else {
                    const newUrl = `/search/web?${queryParamsObj}`;

                    if (newUrl === location.pathname + location.search) {
                        history.go(0);
                    } else {
                        history.push(newUrl);
                    }
                }
                setShowSuggestions(false);
                closeHamburger && closeHamburger();
            }
        },
        [
            searchObject,
            focusedItemIndex,
            hasTypeahead,
            typeaheadData?.suggestions,
            trackEvent,
            analyticsMeta?.category,
            analyticsMeta?.action,
            parent,
            selectedTab,
            closeHamburger,
            history,
            location.search,
            location.pathname,
        ]
    );

    const handleInputChange = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setSearchObject({ q: value });
            if (value && !showSuggestions) setShowSuggestions(true);
        },
        [setSearchObject, setShowSuggestions, showSuggestions]
    );

    const clearSearchQuery = useCallback(() => setSearchObject({ q: "" }), []);

    const showDropdown = useCallback(() => setShowSuggestions(true), []);

    const onKeyDown = useCallback(
        (e: any) => {
            const typeaheadDataLength = typeaheadData?.suggestions?.length || 0;

            if (!typeaheadDataLength) {
                return;
            }
            if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Tab") {
                e.preventDefault();
                setFocusedItemIndex(currentValue => {
                    if (e.key === "ArrowUp") {
                        return typeof currentValue !== "number" || currentValue === 0 ? typeaheadDataLength - 1 : currentValue - 1;
                    }
                    return typeof currentValue !== "number" ? 0 : currentValue === typeaheadDataLength - 1 ? 0 : currentValue + 1;
                });
            }
        },
        [typeaheadData?.suggestions?.length]
    );

    const handleSearchTermClick = useCallback(
        (suggest: string, placeId?: string) => {
            reportTypeaheadClick(suggest, placeId)();

            /* This addresses an edge case where the suggest variable matches
             * url but not search field. This happens when a user is on a
             * results page, changes the search term, then selects a
             * type-ahead option for the current page.
             */
            if (suggest) {
                const { q: urlSearchValue } = Object.fromEntries(new URLSearchParams(location.search));
                const normalizedSearchValue = searchObject?.q.toLowerCase();
                const normalizedSuggestion = suggest?.toLowerCase();
                const normalizedUrlSearchValue = urlSearchValue?.toLowerCase();

                if (normalizedUrlSearchValue === normalizedSuggestion && normalizedSuggestion !== normalizedSearchValue) {
                    setSearchObject({ q: suggest, place_id: placeId });
                    setShowSuggestions(false);
                }
            }
        },
        [location.search, reportTypeaheadClick, searchObject]
    );

    const renderContent = useMemo(() => {
        // Typeahead suggestions
        if (hasTypeahead && searchObject.q.length > 2) {
            return typeaheadData?.suggestions?.map((item, i) => (
                <TypeaheadItem
                    key={item.id}
                    isFocused={focusedItemIndex === i}
                    item={item}
                    onClick={handleSearchTermClick}
                    selectedTab={selectedTab}
                />
            ));
        }

        // We only display this section when user clicked inside the search input, but hasn't typed anything yet
        if (searchValueIsShort && (hasPopularSearchTerms || hasTopics)) {
            return (
                <>
                    {/* Popular search terms */}
                    {hasPopularSearchTerms && (
                        <>
                            <Title className="title" level={3}>
                                Popular Searches
                            </Title>
                            {suggestionsData?.popular_search_terms?.map(searchTerm => (
                                <Link
                                    to={`/search/web?${new URLSearchParams({ q: searchTerm.value || "", source: "hql" })}`}
                                    key={searchTerm.value}
                                    className="search-term"
                                    onClick={reportClick("popular searches", searchTerm.value)}
                                >
                                    <span>
                                        <span>
                                            <SearchIcon className="magnifying-icon" />
                                        </span>
                                        <span>{searchTerm.value}</span>
                                    </span>
                                </Link>
                            ))}
                        </>
                    )}
                </>
            );
        }
    }, [
        focusedItemIndex,
        handleSearchTermClick,
        hasPopularSearchTerms,
        hasTopics,
        hasTypeahead,
        reportClick,
        searchObject.q,
        searchValueIsShort,
        suggestionsData?.popular_search_terms,
        typeaheadData?.suggestions,
        selectedTab,
    ]);

    const [inputIsFocused, setInputIsFocused] = useState(false);
    const { md } = useBreakpoint();
    const showFullSearchButton = md === false && parent === "header";
    const [customSubmitContainerStyle] = useSpring(
        inputIsFocused
            ? { display: "contents", opacity: 1, position: "relative", pointerEvents: "auto" }
            : { display: "none", opacity: 0, position: "absolute", right: 0, pointerEvents: "none" },
        [inputIsFocused]
    );

    const handleOnFocus = useCallback(() => {
        setInputIsFocused(true);
        setIsSearchBarFocused?.(true);
    }, [setIsSearchBarFocused]);

    // Causes issues with clicking on clear button
    // const handleOnBlur = useCallback(() => {
    //     setTimeout(() => {
    //         if (inputRef?.current) {
    //             setInputIsFocused(false);
    //             setIsSearchBarFocused?.(false);
    //         }
    //     }, 100);
    // }, [setIsSearchBarFocused]);

    return (
        <div className={clsx("SearchBar", className && className)} ref={inputContainerRef} data-cid="SearchBar">
            <form onSubmit={onSubmit}>
                <Input
                    data-testid="search-bar-input"
                    ref={inputRef}
                    tabIndex={99}
                    // onBlur={onBlur} // TODO: figure out why this block click event on the SearchBarItem (Firefox only)
                    // autoFocus - this causes issues when switching browser tabs
                    autoComplete="off"
                    className={clsx("search-bar-wrapper", showFullSearchButton && "full-search-btn", !inputIsFocused && "force-rounded", "dark")}
                    name="searchBar"
                    onChange={handleInputChange}
                    onClick={() => {
                        if (searchValueIsShort) {
                            showDropdown();
                        }
                    }}
                    // onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    onKeyDown={onKeyDown}
                    prefix={
                        <CustomSearch
                            style={{
                                visibility: inputIsFocused ? "hidden" : "visible",
                                opacity: inputIsFocused ? 0 : 1,
                                transition: "visibility 0.3s linear,opacity 0.3s linear",
                            }}
                        />
                    }
                    placeholder={placeholder}
                    size={showFullSearchButton ? "small" : "large"}
                    suffix={
                        <CustomSubmit
                            containerStyle={customSubmitContainerStyle}
                            onSubmit={onSubmit}
                            onClose={clearSearchQuery}
                            closeClassName="closeIcon"
                            searchClassName="searchButton"
                        />
                    }
                    maxLength={1000}
                    type="search"
                    value={searchObject.q}
                    onPaste={e => {
                        if (e.clipboardData.getData("text").length > 1000) {
                            Notification.error({ message: "Search query cannot exceed 1000 characters" });
                        }
                    }}
                />
            </form>
            {dropdown &&
                (hasTopics || hasPopularSearchTerms || hasTypeahead) &&
                transitions(
                    (styles, item) =>
                        item && (
                            <animated.div className="dropdown" style={styles}>
                                <Card>
                                    <div ref={dropdownRef}>{renderContent}</div>
                                </Card>
                            </animated.div>
                        )
                )}
        </div>
    );
};

export default SearchBar;

type TypeaheadItemProps = {
    item: IWebTypeaheadResult | IWebTypeaheadLocationResult;
    isFocused: boolean;
    onClick: (suggest: string, placeId?: string) => void;
    selectedTab: "web" | "news" | "images" | "videos" | "shopping" | "maps" | undefined;
};

const TypeaheadItem = ({ item, isFocused, onClick, selectedTab }: TypeaheadItemProps) => {
    const locItem = item as Partial<IWebTypeaheadLocationResult>;
    const location = useLocation();
    const searchUrl = useMemo(() => {
        const destinationTab = locItem.place_id && selectedTab !== "maps" ? "web" : selectedTab;
        return createUpdatedURL(
            {
                q: item.suggest || "",
                ...(locItem.place_id && { place_id: locItem.place_id }),
            },
            `/search/${destinationTab}`,
            location.search
        );
    }, [item.suggest, locItem.place_id, location.search, selectedTab]);

    return (
        <Link
            to={searchUrl}
            key={item.suggest}
            className={clsx("search-term", isFocused && "active", !!locItem.place_id && "location-term")}
            data-testid="location-term"
            onClick={() => onClick(item.suggest || "", locItem.place_id)}
        >
            <div className="typeahead-flair">
                {!locItem.place_id && <SearchIcon className="typeahead-icon magnifying-icon" />}
                {locItem.place_id &&
                    (locItem.image_url ? (
                        <Avatar className="typeahead-image" shape="square" size={36} src={locItem.image_url} alt={locItem.suggest} />
                    ) : (
                        <div className="typeahead-image-placeholder">
                            <LocationPinOutlinedIcon className="placeholder-image" />
                        </div>
                    ))}
            </div>
            <div className={clsx("typeahead-item", isLocationTypeahead(item) ? "place" : "text")} data-testid="typeahead-item">
                <span dangerouslySetInnerHTML={{ __html: item.highlight?.replace(/(\\n)+/g, "<br />") || "" }} />
                {locItem.description && <span className="typeahead-address">{locItem.description}</span>}
            </div>
        </Link>
    );
};

function isLocationTypeahead(item: IWebTypeaheadResult | IWebTypeaheadLocationResult): item is IWebTypeaheadLocationResult {
    return (item as IWebTypeaheadLocationResult).place_id !== undefined;
}
