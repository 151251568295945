import { FC }                from "react";
import clsx                  from "clsx";
import { useGeneralContext } from "@/context/useGeneralContext";
import Button                from "@/designSystem/Button/Button";
import "./OutageBanner.less";

interface OutageBannerProps {
    className?: string;
}

export const OutageBanner: FC<React.PropsWithChildren<OutageBannerProps>> = ({ className }) => {
    const { showOutage, dismissOutage } = useGeneralContext();

    if (!showOutage) {
        return null;
    }

    return (
        <div className={clsx("OutageBanner", className && className)}>
            <span className="custom-cta">
                <span className="emoji">&#9888;</span>
                <span className="description">
                    Freespoke is currently experiencing technical difficulties. Certain features may be slower than normal or unavailable.
                </span>
            </span>
            <Button onClick={dismissOutage} aria-label="Close">
                Dismiss
            </Button>
        </div>
    );
};
