import axios                    from "axios";
import useSWR                   from "swr";
import { Product, TaggedImage } from "@/@types/custom";
import { useBackendAccess }     from "@/api/useBackendAccess";
import { BACKEND_URL }          from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export interface UseGetShopHomepageResponse {
    hero_links?: { title: string; ctaUrl?: string; ctaLabel?: string; entities: HeroLink[] };
    holiday_hero_links?: { title: string; ctaUrl?: string; ctaLabel?: string; entities: HeroLink[] };
    featured_brand?: FeaturedBrand;
    category_cards?: { title: string; ctaUrl?: string; ctaLabel?: string; entities: CategoryCard[] };
    editors_pick?: { title: string; ctaUrl?: string; ctaLabel?: string; entities: EditorsPick[] };
    featured_collection?: FeaturedCollection;
    top_gifts?: FeaturedCollection;
    sponsored_brand?: SponsoredBrand;
}

export interface HeroLink {
    id: string;
    title?: string;
    slug: string;
    image?: string;
    type: string;
}

export interface FeaturedBrand {
    id: string;
    title: string;
    summary: string;
    images: string[];
    slug: string;
    type: string;
    url: string;
    product_data: ProductData;
    ctaLabel?: string;
}

export interface ProductData {
    result_count: number;
    products: Product[];
}

export interface Brand {
    id: string;
    name: string;
    url: string;
    description: string;
}

export interface CategoryCard {
    id: string;
    title: string;
    slug: string;
    type: "collection" | "organization";
    image?: string;
}

export interface EditorsPick {
    id: string;
    title: string;
    slug: string;
    image?: string;
    type: string;
    products: Product[];
}

export interface FeaturedCollection {
    id: string;
    title: string;
    summary: string;
    images: string[];
    slug: string;
    type: string;
    product_data: ProductData;
    image: string | undefined;
    url: string;
    featuredCollection?: string;
    ctaLabel?: string;
}

export interface SponsoredBrand {
    id: string;
    name: string;
    hero_images: string[];
    logo: string;
    url: string;
    description: string;
    slug: string;
    hero_images_with_tags?: TaggedImage[];
}

const useGetShopHomepage = () => {
    const { guestAccess } = useBackendAccess();

    const url = `${BACKEND_URL}/v2/shop/homepage`;

    const fetcher = (URL: string) => axios.get<UseGetShopHomepageResponse>(URL, { headers: guestAccess?.authHeaders }).then(res => res.data);
    const { data, error, mutate } = useSWR(guestAccess?.authHeaders ? url : null, fetcher, config);

    return {
        data,
        isLoading: !data,
        error,
        refresh: mutate,
    };
};

export default useGetShopHomepage;
