import { FC, memo, useCallback, useMemo }           from "react";
import { useMatomo }                                from "@datapunt/matomo-tracker-react";
import { useLocation }                              from "react-router-dom";
import { getAnalyticsCategory }                     from "@/analytics/analytics";
import { A_B_VERSION }                              from "@/config";
import Link                                         from "@/designSystem/Link/Link";
import { getAppStoreLink }                          from "@/helpers/index";
import { useCustomTrackingLinks }                   from "@/hooks/useCustomTrackingLinks";
import app_play_badge_black                         from "@/static/logo/app-store-badge-black.svg";
import app_play_badge_white                         from "@/static/logo/app-store-badge-white.svg";
import { ReactComponent as AppPlayBadgeUniform }    from "@/static/logo/apple-app-uniform.svg";
import google_play_badge                            from "@/static/logo/google-play-badge.png";
import { ReactComponent as GooglePlayBadgeUniform } from "@/static/logo/google-play-uniform.svg";
import "./AppDownloadBadge.less";

export interface AppDownloadBadgeProps {
    type: "ios" | "ios-alt" | "android";
    className?: string;
    uniform?: boolean;
    iosURL?: string;
    reportClick?: () => void;
}

const AppDownloadBadge: FC<React.PropsWithChildren<AppDownloadBadgeProps>> = ({ type, className, reportClick, iosURL, uniform = false }) => {
    const isIOS = type === "ios" || type === "ios-alt";
    const { trackEvent } = useMatomo();
    const { pathname } = useLocation<{ clearFilters?: boolean }>();
    const { getLinks } = useCustomTrackingLinks();

    const recordAppDownloadClickEvent = useCallback(() => {
        // Matomo analytics click App Download link
        const pageName = getAnalyticsCategory(pathname);

        if (pageName === "election") {
            trackEvent({
                category: "election",
                action: "election app download cta click",
                name: "click",
            });
        } else if (pageName === "home") {
            trackEvent({
                category: A_B_VERSION === "a" ? "home" : "homeb",
                action: "home nav CTA click",
                name: `click - app download ${isIOS ? "ios" : "android"}`,
            });
        } else if (pageName === "join") {
            trackEvent({
                category: "join",
                action: "join app download cta click",
                name: "click",
            });
        } else if (pageName === "story page") {
            trackEvent({
                category: "story page",
                action: "story pop up app download CTA click",
                name: "click",
            });
        } else {
            trackEvent({
                category: A_B_VERSION === "a" ? "app download" : "app downloadb",
                action: `app download - ${pageName} ${isIOS ? "ios" : "android"}`,
                name: "click",
            });
        }
    }, [isIOS, pathname, trackEvent]);

    const renderBadge = () => {
        switch (type) {
            case "ios":
                return uniform ? <AppPlayBadgeUniform /> : <img src={app_play_badge_black} alt="app store badge" />;
            case "ios-alt":
                return <img src={app_play_badge_white} alt="app store badge" />;
            case "android":
                return uniform ? <GooglePlayBadgeUniform /> : <img src={google_play_badge} alt="google play badge" />;
            default:
                break;
        }
    };

    const link = useMemo(() => {
        if (isIOS && iosURL) {
            return iosURL;
        }
        const customLinks = getLinks("navBar");
        if (customLinks) {
            return isIOS ? customLinks.IOS_APP : customLinks.ANDROID_APP;
        }
        return getAppStoreLink(isIOS ? "ios" : "android");
    }, [getLinks, iosURL, isIOS]);

    return (
        <div className={`AppDownloadBadge ${className ? className : ""} ${isIOS ? "ios" : "android"}`}>
            <Link
                href={link}
                className="download-link"
                onClick={reportClick ? reportClick : recordAppDownloadClickEvent}
                data-cid={`AppDownloadBadge-${isIOS ? "ios" : "android"}`}
            >
                {renderBadge()}
            </Link>
        </div>
    );
};

export default memo(AppDownloadBadge);
