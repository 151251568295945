import axios                                                from "axios";
import { useLocation }                                      from "react-router-dom";
import useSWR                                               from "swr";
import { IWebLocationTypeaheadResult, IWebTypeaheadResult } from "@/@types/custom";
import { laggy }                                            from "@/api/swrMiddleware";
import { useBackendAccess }                                 from "@/api/useBackendAccess";
import { BACKEND_URL, DISABLE_FEATURES_LIST }               from "@/config";
import { StorageService }                                   from "@/helpers/storage";
import useDebounce                                          from "@/hooks/useDebounce";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false, use: [laggy] };

interface useGetTypeaheadResponse {
    suggestions: (IWebTypeaheadResult | IWebLocationTypeaheadResult)[] | undefined;
    typeahead_token?: string;
}

export type useGetTypeaheadParams = string | null;

const useGetTypeahead = (q: useGetTypeaheadParams) => {
    const { guestAccess } = useBackendAccess();
    const debouncedQuery = useDebounce(q?.trim(), 50);
    const { latitude, longitude } = useTypeaheadLocation();
    const url = `${BACKEND_URL}/typeahead/v2/web`;
    const fetcher = () =>
        axios
            .get(url, {
                headers: guestAccess?.authHeaders,
                params: {
                    q: debouncedQuery,
                    include: DISABLE_FEATURES_LIST?.includes("typeahead_places") ? undefined : "places",
                    typeahead_token: StorageService.TypeaheadToken.get() || "", // NOTE: the API requires `typeahead_token` query param to be always present, on the first call when you don't have the `typeahead_token` send it empty
                    client_id: StorageService.ClientId.get() || "",
                    client_type: "web",
                    latitude,
                    longitude,
                },
            })
            .then(res => res.data);

    const { data, error, isValidating, isLoading, mutate } = useSWR<useGetTypeaheadResponse>(
        guestAccess?.authHeaders && debouncedQuery && debouncedQuery.length > 2 ? [url, debouncedQuery] : null,
        fetcher,
        config
    );

    // Set typeahead token
    if (data?.typeahead_token && !StorageService.TypeaheadToken.get()) {
        StorageService.TypeaheadToken.set(data.typeahead_token);
        // We need to delete `typeahead_token` from data, otherwise we wil keep reassigning the same token
        if (data) {
            mutate({ ...data, typeahead_token: undefined }, false); // 'false' -> won't auto refresh data using API call
        }
    }

    return {
        data,
        isFetching: !guestAccess?.authHeaders || isValidating,
        isLoading,
        error,
    };
};

export default useGetTypeahead;

const useTypeaheadLocation = () => {
    const location = useLocation();
    const searchParamsGeocode = new URLSearchParams(location.search);
    const latitude = searchParamsGeocode.get("m_latitude");
    const longitude = searchParamsGeocode.get("m_longitude");
    if (latitude && longitude) {
        return { latitude, longitude };
    }

    const { geocode } = StorageService.LocationContext.get() ?? {};
    return geocode ?? {};
};
