import { FC }                   from "react";
import AntTitle, { TitleProps } from "antd/lib/typography/Title";
import clsx                     from "clsx";
import "./Title.less";

const Title: FC<React.PropsWithChildren<TitleProps>> = ({ children, className, ...props }) => {
    return (
        <AntTitle className={clsx("AntTitle", className)} {...props}>
            {children}
        </AntTitle>
    );
};

export default Title;
