import { FC, useEffect, useMemo, useState }             from "react";
import useBreakpoint                                    from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                             from "clsx";
import { isChrome, isEdge, isFirefox, isIOS, isMobile } from "react-device-detect";
import { useLocation }                                  from "react-router-dom";
import { DISPLAY_OUTAGE_BANNER }                        from "@/config";
import Link                                             from "@/designSystem/Link/Link";
import { CONSTANTS }                                    from "@/helpers/constants";
import { getRedirectURL }                               from "@/helpers/index";
import { HINT_TYPES, StorageService }                   from "@/helpers/storage";
import { useAuth }                                      from "@/hooks/useAuth";
import { useExtensionDetection }                        from "@/hooks/useExtensionDetection";
import useIsMounted                                     from "@/hooks/useIsMounted";
import AppDownloadBadge                                 from "@/molecules/AppDownloadBadge/AppDownloadBadge";
import ShareCompressedV3                                from "@/molecules/ShareCompressedV3/ShareCompressedV3";
import { ReactComponent as BraveIcon }                  from "@/static/icons/browser-greyscale-brave.svg";
import { ReactComponent as ChromeIcon }                 from "@/static/icons/browser-greyscale-chrome.svg";
import { ReactComponent as FirefoxIcon }                from "@/static/icons/browser-greyscale-firefox.svg";
import "./DynamicCTA.less";

interface DynamicCTAProps {
    className?: string;
    onClickFunc?: (type: string) => void;
    hideButton?: boolean;
    excludedTypes?: ("sign-up" | "log-in" | "premium" | "mobile-app" | "extension")[];
    extensionLinkText?: string;
}

export const DynamicCTA: FC<React.PropsWithChildren<DynamicCTAProps>> = ({
    className,
    onClickFunc,
    hideButton,
    excludedTypes,
    extensionLinkText,
}) => {
    const isMounted = useIsMounted();

    const { xs } = useBreakpoint();
    const location = useLocation();
    const redirectURL = getRedirectURL(location);

    const { extensionCheckCompleted, isChromeExtensionInstalled, isFirefoxExtensionInstalled } = useExtensionDetection();

    const { isAuthenticated, hasPremiumWithCurrentAccount, browsingViaMobileAppWthPremium, isLoading: isLoadingAuth } = useAuth();
    const hasAccount = useMemo(() => StorageService.HasHint.get(HINT_TYPES.HAS_ACCOUNT), []);

    const [hidden, setHidden] = useState<boolean>(true);

    const isFreespokeBrowser = !!navigator?.userAgent?.toLowerCase().includes("freespoke"); // User is using our mobile app browser
    const hasFreespokeExtensionInstalled = isChromeExtensionInstalled || isFirefoxExtensionInstalled; // User is using Firefox or Chrome browser with Freespoke extension installed
    // @ts-ignore
    const isBraveBrowser = navigator?.brave && navigator.brave.isBrave();
    const isSupportedBrowser = isChrome || isEdge || isBraveBrowser || isFirefox;

    const skipSignUp = useMemo(() => excludedTypes?.includes("sign-up"), [excludedTypes]);
    const skipLogin = useMemo(() => excludedTypes?.includes("log-in"), [excludedTypes]);
    const skipPremium = useMemo(() => excludedTypes?.includes("premium"), [excludedTypes]);
    const skipMobileApp = useMemo(() => excludedTypes?.includes("mobile-app"), [excludedTypes]);
    const skipExtension = useMemo(() => excludedTypes?.includes("extension"), [excludedTypes]);

    const isElectionAboutPage = useMemo(() => location.pathname.includes("/about/election2024"), [location.pathname]);

    // Initially we hide the button until we find out if user has extension installed or not
    useEffect(() => {
        if (extensionCheckCompleted && isMounted()) {
            setHidden(false);
        }
    }, [extensionCheckCompleted, isChromeExtensionInstalled, isFirefoxExtensionInstalled, isMounted]);

    const extensionButton = useMemo(() => {
        if (isFirefox) {
            return (
                <Link
                    className="cta-button"
                    to={CONSTANTS.LINKS.FIREFOX_EXTENSION_HOW_TO_INSTALL_MODAL}
                    onClick={() => {
                        onClickFunc?.("Firefox extension");
                    }}
                >
                    <span>{extensionLinkText || "Add Freespoke to Firefox"}</span>
                    <FirefoxIcon className="browser-icon" />
                </Link>
            );
        }

        if (isBraveBrowser) {
            return (
                <Link
                    className="cta-button extension"
                    to={CONSTANTS.LINKS.BRAVE_EXTENSION_HOW_TO_INSTALL_MODAL}
                    onClick={() => {
                        onClickFunc?.("Brave extension");
                    }}
                >
                    <span>{extensionLinkText || "Add Freespoke to Brave"}</span>
                    <BraveIcon className="browser-icon" />
                </Link>
            );
        }

        if (isEdge) {
            return (
                <Link
                    className="cta-button"
                    to={CONSTANTS.LINKS.EDGE_EXTENSION_HOW_TO_INSTALL_MODAL}
                    onClick={() => {
                        onClickFunc?.("Edge extension");
                    }}
                >
                    <span>{extensionLinkText || "Add Freespoke to Edge"}</span>
                </Link>
            );
        }

        return (
            <Link
                className="cta-button"
                onClick={() => {
                    onClickFunc?.("Chrome extension");
                }}
                to={CONSTANTS.LINKS.CHROME_EXTENSION_HOW_TO_INSTALL_MODAL}
            >
                <span>{extensionLinkText || "Add Freespoke to Chrome"}</span>
                <ChromeIcon className="browser-icon" />
            </Link>
        );
    }, [isBraveBrowser, onClickFunc, extensionLinkText]);

    const renderCTA = useMemo(() => {
        if (!extensionCheckCompleted) {
            return <div className="cta-button">Loading...</div>;
        }

        // User is not logged in
        if (!isAuthenticated && !isLoadingAuth && !skipLogin && !DISPLAY_OUTAGE_BANNER) {
            if (!hasAccount && !skipSignUp) {
                <Link
                    className="cta-button"
                    to={"/signup"}
                    onClick={() => {
                        onClickFunc?.("signup CTA");
                    }}
                >
                    Sign Up
                </Link>;
            }

            return (
                <Link
                    className="cta-button"
                    to={{
                        pathname: "/login",
                        search: `?redirect_uri=${redirectURL}`,
                    }}
                    onClick={() => {
                        onClickFunc?.("login CTA");
                    }}
                >
                    Log In
                </Link>
            );
        }

        // User is logged in, but doesn't have Premium
        if (isAuthenticated && !hasPremiumWithCurrentAccount && !browsingViaMobileAppWthPremium && !skipPremium && !DISPLAY_OUTAGE_BANNER) {
            return (
                <Link
                    className="cta-button extension"
                    to={CONSTANTS.LINKS.PREMIUM.PREMIUM}
                    onClick={() => {
                        onClickFunc?.("get premium CTA");
                    }}
                >
                    Get Premium
                </Link>
            );
        }

        // User is using:
        // - any mobile device and NOT Freespoke Browser App
        if (isMobile && !isFreespokeBrowser && (!skipMobileApp || hasPremiumWithCurrentAccount)) {
            if (isIOS) {
                return <AppDownloadBadge type="ios" />;
            }

            return <AppDownloadBadge type="android" />;
        }

        // User is using:
        // - any non-mobile device and supported browser (Chrome/Brave/Edge or Firefox)
        if (!isMobile && !xs && isSupportedBrowser && !hasFreespokeExtensionInstalled && (!skipExtension || hasPremiumWithCurrentAccount)) {
            return extensionButton;
        }

        return (
            <ShareCompressedV3
                url={window.location.href}
                type="freespoke"
                analyticsMeta={isElectionAboutPage ? { action: "share election about" } : undefined}
            >
                <button
                    className="cta-button"
                    onClick={() => {
                        onClickFunc?.("share election about");
                    }}
                >
                    Share
                </button>
            </ShareCompressedV3>
        );
    }, [
        browsingViaMobileAppWthPremium,
        extensionButton,
        extensionCheckCompleted,
        hasAccount,
        hasFreespokeExtensionInstalled,
        hasPremiumWithCurrentAccount,
        isAuthenticated,
        isElectionAboutPage,
        isFreespokeBrowser,
        isLoadingAuth,
        isSupportedBrowser,
        onClickFunc,
        redirectURL,
        skipExtension,
        skipLogin,
        skipMobileApp,
        skipPremium,
        skipSignUp,
        xs,
    ]);

    return <div className={clsx("DynamicCTA", className, hidden && "hidden")}>{!hideButton && <>{renderCTA}</>}</div>;
};
