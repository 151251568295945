import { useCallback, useMemo } from "react";
import { useLocation }          from "react-router-dom";

export interface CustomTrackingLinks {
    IOS_APP: string;
    ANDROID_APP: string;
    FIREFOX_EXTENSION: string;
    CHROME_EXTENSION: string;
}

const customTrackingMap: Record<"topSection" | "bottomSection" | "navBar", CustomTrackingLinks> = {
    topSection: {
        IOS_APP:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTU0MjcsIm5iZiI6MTcwOTE1NTQyNywiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay9VenhId1ZROXJDYiIsImMiOiJVU0JBcHAiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.uudHhhc3_uQzMm1McikZwudLGX8dAicLoddIPB7UrUM",
        ANDROID_APP:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTU0NjgsIm5iZiI6MTcwOTE1NTQ2OCwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay90Mm9uakEyY3NDYiIsImMiOiJVU0JBbmQiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.Blpux2u2VRtEqRc3OEmXDN6GgQvqsiNoqBt_fUqKaIE",
        FIREFOX_EXTENSION:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTUzNjgsIm5iZiI6MTcwOTE1NTM2OCwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay84Q0RWRkVhZHNDYiIsImMiOiJVU0JGb3giLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.kx7gSkSeqDEgE6eXGlF2xnU4TQOj22U3sLHVwRgeM0s",
        CHROME_EXTENSION:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTUzMDMsIm5iZiI6MTcwOTE1NTMwMywiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay80bUJId0pnZHNDYiIsImMiOiJVU0JDaHJvbWUiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.QWP4sVXsaKlNsI7JaTIS_O4RKzkegsQSKNLHr0Ivbho",
    },
    bottomSection: {
        IOS_APP:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTU0NDAsIm5iZiI6MTcwOTE1NTQ0MCwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay9VenhId1ZROXJDYiIsImMiOiJCb3R0b21BcHAiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.pCLpxyty0LxEgrZSBno3XczHcW0y1pwSbaSt9YdoeOQ",
        ANDROID_APP:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTU0ODAsIm5iZiI6MTcwOTE1NTQ4MCwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay90Mm9uakEyY3NDYiIsImMiOiJCb3R0b21BbmQiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.6zpR43j3tWH2XPsfddQTW5Uq4nAyEYt4SFHL3P5WcPc",
        FIREFOX_EXTENSION:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTUzODMsIm5iZiI6MTcwOTE1NTM4MywiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay84Q0RWRkVhZHNDYiIsImMiOiJCb3R0b21Gb3giLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.xaD4r5MkCdGhuzXFwXm1vqt06vxgjAiFHoyFZvKZff4",
        CHROME_EXTENSION:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTUzMTgsIm5iZiI6MTcwOTE1NTMxOCwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay80bUJId0pnZHNDYiIsImMiOiJCb3R0b21DaHJvbWUiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.Bc7z_L618ZND51EB-0cPImpSlLGtV95E3hSdekegt0Q",
    },
    navBar: {
        IOS_APP:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTU0MDgsIm5iZiI6MTcwOTE1NTQwOCwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay9VenhId1ZROXJDYiIsImMiOiJOYXZBcHAiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.ZVeEb3kCVQTGpMFHmG1o-R21A9u4d8eKaEaWJMXqOko",
        ANDROID_APP:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTU0NTYsIm5iZiI6MTcwOTE1NTQ1NiwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay90Mm9uakEyY3NDYiIsImMiOiJOYXZBbmQiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.0dAvjVT2vZeXR5zizGttw-MfCBweit6GatV0s9BDufo",
        FIREFOX_EXTENSION:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTUzMzgsIm5iZiI6MTcwOTE1NTMzOCwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay84Q0RWRkVhZHNDYiIsImMiOiJOYXZGb3giLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.FGuhq58cuNB2cInQM3j72t-9R0cQ6u0iSrlF7qDi1KM",
        CHROME_EXTENSION:
            "https://fspclick.com/t/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjQ4NjI3NTUyNTgsIm5iZiI6MTcwOTE1NTI1OCwiaWQiOiJodHRwczovL2ZyZWVzcG9rZS5hcHAubGluay80bUJId0pnZHNDYiIsImMiOiJOYXZDaHJvbWUiLCJwIjoiRGVjaWRlIiwiaXAiOiJkMzkxZjVlIn0.bEWj3PZCxd-d14oeM-9p6u5y3iRU_-PLO6mn_CtNg3Q",
    },
} as const;

function generateCustomTrackingLink(customLinks: CustomTrackingLinks, ld_trk: string): CustomTrackingLinks {
    return {
        IOS_APP: `${customLinks.IOS_APP}?ld_trk=${ld_trk}`,
        ANDROID_APP: `${customLinks.ANDROID_APP}?ld_trk=${ld_trk}`,
        FIREFOX_EXTENSION: `${customLinks.FIREFOX_EXTENSION}?ld_trk=${ld_trk}`,
        CHROME_EXTENSION: `${customLinks.CHROME_EXTENSION}?ld_trk=${ld_trk}`,
    };
}

// On /about, if url params have "partner_id=decide", and "ld_trk" existing and set,
// we modify the call to action URLs to use fspclick.com links and append "ld_trk" to them.
// So fspclick.com/xxxx?ld_trk={url.params["ld_trk"}

export const useCustomTrackingLinks = () => {
    const location = useLocation();

    const customTracking = useMemo(() => {
        if (location.pathname === "/about") {
            const urlParams = new URLSearchParams(location.search);
            const partnerId = urlParams.get("partner_id");
            const ldTrk = urlParams.get("ld_trk") || "";
            if (partnerId === "decide") {
                return ldTrk;
            }
        }
        return "";
    }, [location.pathname, location.search]);

    const getLinks = useCallback(
        (type: "topSection" | "bottomSection" | "navBar") => {
            if (customTracking) {
                return generateCustomTrackingLink(customTrackingMap[type], customTracking);
            }
        },
        [customTracking]
    );

    return {
        getLinks,
    };
};
