import { useCallback, useMemo, useState }         from "react";
import { Skeleton }                               from "antd";
import useBreakpoint                              from "antd/lib/grid/hooks/useBreakpoint";
import SkeletonAvatar                             from "antd/lib/skeleton/Avatar";
import clsx                                       from "clsx";
import { useResizeDetector }                      from "react-resize-detector";
import { animated }                               from "react-spring";
import useGetShopCategories                       from "@/api/shop/useGetShopCategories";
import { DISPLAY_OUTAGE_BANNER }                  from "@/config";
import Avatar                                     from "@/designSystem/Avatar/Avatar";
import Dropdown                                   from "@/designSystem/Dropdown/Dropdown";
import ChevronDown                                from "@/designSystem/Icons/ChevronDown";
import SearchOutlined                             from "@/designSystem/Icons/SearchOutlined";
import Link                                       from "@/designSystem/Link/Link";
import Menu                                       from "@/designSystem/Menu/Menu";
import { CONSTANTS }                              from "@/helpers/constants";
import { useAnalyticsTracking }                   from "@/hooks/useAnalyticsTracking";
import { useAppBar }                              from "@/hooks/useAppBar";
import { useMobileAppDetection }                  from "@/hooks/useMobileAppDetection";
import Hamburger                                  from "@/molecules/Hamburger/Hamburger";
import { OutageBanner }                           from "@/molecules/OutageBanner/OutageBanner";
import ShareCompressedV2                          from "@/molecules/ShareCompressedV2/ShareCompressedV2";
import NewsletterSignupDrawer                     from "@/organisms/NewsletterSignupDrawer/NewsletterSignupDrawer";
import { ReactComponent as FreespokeLogoDesktop } from "@/static/images/christmas/shop-logo.svg";
import { ReactComponent as FreespokeLogoMobile }  from "@/static/logo/logo_small.svg";
import "./ChristmasShopAppBarHeader.less";

export const ChristmasShopAppBarHeader = () => {
    const { closeHamburger } = useAppBar();
    const { sm, md, lg, xl } = useBreakpoint();
    const { ref } = useAppBarResize();
    const [showHolidayDrawer, setShowHolidayDrawer] = useState(false);
    const { isMobileApp } = useMobileAppDetection();

    const { trackEvent } = useAnalyticsTracking();
    const recordLinkClick = useCallback(
        (linkText: string) => {
            closeHamburger?.();
            trackEvent({
                category: "shop us",
                action: `shop nav bar menu category click - ${linkText}`,
                name: "click",
            });
        },
        [closeHamburger, trackEvent]
    );

    return (
        <>
            <animated.header className={clsx("ChristmasShopAppBarHeader")} ref={ref}>
                {DISPLAY_OUTAGE_BANNER && <OutageBanner />}

                <NewsletterSignupDrawer
                    isOpen={showHolidayDrawer}
                    onDrawerClose={() => setShowHolidayDrawer(false)}
                    title="Your place to find American-Made brands worth supporting"
                    description="Shop USA is the place to find American-made products. It's part of our mission to elevate American brands with local values who
                    may be filtered by big tech. Sign up to learn about new brands and collections from our newsletter."
                    submitBtnLabel="Get Shop Deals"
                    newsletterType="shop"
                    analyticsPrefix="shop"
                    maskClosable
                />
                {!isMobileApp && (
                    <div className="top-section">
                        <div className="width-limiter">
                            <Link
                                to="/"
                                className="home-link"
                                onClick={() => {
                                    trackEvent({ category: "shop us", action: "shop nav bar find news and search click", name: "click" });
                                    closeHamburger?.();
                                }}
                            >
                                <SearchOutlined className="search-icon" />
                                Find news and search on Freespoke
                            </Link>
                            {md && (
                                <ShareCompressedV2
                                    triggerAsContainer
                                    type="christmas"
                                    url={window.location.href}
                                    className="share-shop-link"
                                    analyticsMeta={{ category: "share", action: "share shop nav bar home" }}
                                >
                                    <FreespokeLogoMobile className="share-torch" />
                                    Share American-made: <span className="share-text">Send to Friends</span>
                                </ShareCompressedV2>
                            )}
                        </div>
                    </div>
                )}
                <div className={clsx("wrapper")}>
                    <div className="left-section">
                        <Link
                            to="/shop"
                            className="logo"
                            onClick={() => {
                                trackEvent({ category: "shop us", action: "shop nav bar logo click", name: "click" });
                                closeHamburger?.();
                            }}
                        >
                            <FreespokeLogoDesktop className="freespoke-logo" />
                        </Link>
                        <div className="shop-nav-links">
                            {lg && (
                                <>
                                    <CategoriesMenuDropdown />
                                    <Link to="/shop/collections/list" onClick={() => recordLinkClick("Collections")}>
                                        Collections
                                    </Link>
                                    <Link to="/shop/brands/list" onClick={() => recordLinkClick("Brands")}>
                                        Brands
                                    </Link>
                                </>
                            )}
                            {xl && (
                                <Link to="/shop/featured-brand" onClick={() => recordLinkClick("Featured Brand")}>
                                    Featured Brand
                                </Link>
                            )}
                            {xl && (
                                <Link href={CONSTANTS.LINKS.SHOP_USA_PARTNER_LINK} target="_blank" onClick={() => recordLinkClick("Submit a Brand")}>
                                    Submit a Brand
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="right-section">
                        <button
                            className="get-discounts-btn"
                            onClick={() => {
                                trackEvent({ category: "shop us", action: "shop nav bar cta click", name: "click - Get Shop Discounts" });
                                setShowHolidayDrawer(true);
                            }}
                        >
                            Get Shop Discounts
                        </button>
                        {sm && (
                            <Link
                                to="/about"
                                className="about-us-link"
                                onClick={() => {
                                    trackEvent({ category: "shop us", action: "shop nav bar about freespoke click", name: "click" });
                                    closeHamburger?.();
                                }}
                            >
                                About Freespoke
                            </Link>
                        )}

                        <Hamburger
                            className="hamburger"
                            analyticsMeta={{
                                category: "shop us",
                                name: "click",
                                action: "shop nav bar hamburger menu open / close",
                            }}
                        />
                    </div>
                </div>
            </animated.header>
        </>
    );
};

const useAppBarResize = () => {
    const onResize = useCallback((_width?: number, height?: number) => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-header-height", `${height}px`);
    }, []);

    // A Hook that listens on the outer HTML element height change
    const { ref } = useResizeDetector({
        handleHeight: true,
        handleWidth: false,
        refreshMode: "debounce",
        refreshRate: 50,
        onResize,
    });

    return { ref };
};

const CategoriesMenuDropdown = () => {
    const { data: { categories } = {} } = useGetShopCategories({ page: 1, per_page: 20 });
    const { trackEvent } = useAnalyticsTracking();
    const { closeHamburger } = useAppBar();

    const alphabeticalCategories = useMemo(() => {
        if (!categories) {
            return undefined;
        }
        return [...categories].sort((a, b) => a.title.localeCompare(b.title));
    }, [categories]);

    const recordLinkClick = useCallback(
        (categoryTitle: string) => {
            trackEvent({
                category: "shop us",
                action: "shop nav bar menu categories click",
                name: categoryTitle,
            });
            closeHamburger?.();
        },
        [closeHamburger, trackEvent]
    );
    return (
        <Dropdown
            overlay={
                <Menu className="categories-menu-desktop">
                    {alphabeticalCategories?.map(category => (
                        <Menu.Item className="menu-item" key={category.id}>
                            <Link to={`/shop/categories/${category.id}/${category.slug}`} onClick={() => recordLinkClick(category.title)}>
                                <div className="category-link-inner">
                                    <div className="category-link-row">
                                        {category.logo && <Avatar src={category.logo} className="category-logo" />}
                                        <span className="category-link-text">{category.title}</span>
                                    </div>
                                </div>
                            </Link>
                        </Menu.Item>
                    ))}

                    {!alphabeticalCategories &&
                        Array.from({ length: 8 }).map((_, idx) => (
                            <div className="menu-item" key={idx}>
                                <div className="category-link-inner">
                                    <div className="category-link-row skeleton">
                                        <SkeletonAvatar size={24} />
                                        <Skeleton loading paragraph={{ rows: 1 }} active title={false} className="skeleton-line" />
                                    </div>
                                </div>
                            </div>
                        ))}
                </Menu>
            }
            getPopupContainer={trigger => trigger}
            overlayClassName="categories-nav-dropdown"
            trigger={["click"]}
        >
            <button className="categories-menu-trigger" type="button">
                Categories
                <ChevronDown className="categories-menu-chevron" />
            </button>
        </Dropdown>
    );
};
