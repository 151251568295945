export type getAnalyticsCategoryResponse =
    | "freespoke topic"
    | "freespoke topic 2.0"
    | "freespoke topics categories"
    | "search results page"
    | "story page"
    | "best of web"
    | "censored stories"
    | "about us"
    | "home"
    | "join"
    | "whats hot"
    | string;

export function getAnalyticsCategory(pathname: string): getAnalyticsCategoryResponse {
    if (pathname.startsWith("/search/topic")) {
        return "freespoke topic";
    } else if (pathname.startsWith("/topic")) {
        return "freespoke topic 2.0";
    } else if (pathname.startsWith("/search/categories")) {
        return "freespoke topics categories";
    } else if (
        pathname.startsWith("/search/web") ||
        pathname.startsWith("/search/news") ||
        pathname.startsWith("/search/images") ||
        pathname.startsWith("/search/shopping") ||
        pathname.startsWith("/search/videos")
    ) {
        return "search results page";
    } else if (pathname.startsWith("/story")) {
        return "story page";
    } else if (pathname.startsWith("/category/best-of-the-web")) {
        return "best of web";
    } else if (pathname.startsWith("/category/censored-stories")) {
        return "censored stories";
    } else if (pathname.startsWith("/about-shop-usa")) {
        return "about shop usa";
    } else if (pathname.startsWith("/about/election2024")) {
        return "about election";
    } else if (pathname.startsWith("/about")) {
        return "about us";
    } else if (pathname.startsWith("/join")) {
        return "join";
    } else if (pathname.startsWith("/what-is-hot")) {
        return "whats hot";
    } else if (pathname.startsWith("/election/2022") || pathname.startsWith("/election/2024")) {
        return "election";
    } else if (pathname.startsWith("/shop") || pathname.startsWith("/products") || pathname.startsWith("/product")) {
        return "shop us";
    } else if (pathname.startsWith("/terms-of-service")) {
        return "terms of service";
    } else if (pathname.startsWith("/privacy-policy")) {
        return "privacy policy";
    } else if (pathname.startsWith("/notice-of-collection")) {
        return "notice of collection";
    } else if (pathname.startsWith("/reset-password")) {
        return "reset password";
    } else if (pathname.startsWith("/search/maps")) {
        return "maps";
    } else if (pathname.startsWith("/news/what-is-hot")) {
        return "newsfeed whats hot";
    } else if (pathname.startsWith("/news/breaking")) {
        return "newsfeed breaking";
    } else if (pathname.startsWith("/news/censored")) {
        return "newsfeed censored";
    } else if (pathname.startsWith("/news/best-of-web")) {
        return "newsfeed bow";
    } else if (pathname.startsWith("/news")) {
        return "newsfeed trending";
    } else if (pathname.startsWith("/account")) {
        return "no ads";
    } else if (pathname === "/") {
        return "home";
    } else {
        return `unknown pathname: ${pathname}`;
    }
}

export function getAnalyticsShopPageName(pathname: string) {
    if (pathname.startsWith("/shop/categories")) {
        return "shop category";
    } else if (pathname.startsWith("/shop/collections")) {
        return "shop collection";
    } else if (pathname.startsWith("/shop/brands")) {
        return "shop brand";
    } else {
        return "shop home";
    }
}
