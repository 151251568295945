import { useCallback, useMemo } from "react";
import { useMatomo }            from "@datapunt/matomo-tracker-react";
import { useLocation }          from "react-router-dom";
import { getAnalyticsCategory } from "@/analytics/analytics";

export interface ITrackParams {
    category?: string;
    name?: string;
    action: string;
}

export const useAnalyticsTracking = () => {
    const location = useLocation();
    const category = useMemo(() => getAnalyticsCategory(location.pathname), [location.pathname]);

    const { trackEvent } = useMatomo();

    const track = useCallback(
        (params: ITrackParams) => {
            trackEvent({
                category: params.category ?? category,
                name: params.name,
                action: params.action,
            });
        },
        [category, trackEvent]
    );

    return { trackEvent: track };
};
