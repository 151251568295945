import { FC, Ref, useMemo }                                              from "react";
import useBreakpoint                                                     from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                                              from "clsx";
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from "react-router-dom";

export type LinkProps = RequireOnlyOne<RouterLinkProps, "to" | "href"> & {
    popupAllowed?: boolean;
    innerRef?: Ref<HTMLAnchorElement>;
};

/**
 * This component will either work as a normal React Router Link
 * or a standard HTMLAnchorElement. You have to either pass "to"
 * or "href" as a prop. If you pass "to", this component will function
 * as a normal React Router Link. If you pass "href", this component
 * will function as a normal HTMLAnchorElement. Both options will
 * include styling from Ant Design.
 *
 * Why? Because we need a component that will handle both internal
 * and external routing. This solution accomplishes both use cases
 * from a single component.
 *
 * NOTE: On "lg" screens the default behavior for external links is to open them in a new tab
 */
const Link: FC<React.PropsWithChildren<LinkProps>> = ({
    children,
    to,
    replace,
    innerRef,
    className,
    onClick,
    rel = "noopener",
    target,
    href,
    popupAllowed = true,
    ...rest
}) => {
    const { lg } = useBreakpoint();
    const { pathname } = useLocation();

    // NOTE: Remove after "Election 2022" is removed from mobile app
    const toLink = useMemo(() => {
        if (pathname?.startsWith("/app/election/2022")) {
            if (typeof to === "string" && to?.startsWith("/election/2022")) {
                return `/app${to}`;
            }
        }
        return to;
    }, [pathname, to]);

    return toLink ? (
        <RouterLink
            {...rest}
            ref={innerRef}
            className={clsx("ant-typography", className && className)}
            to={toLink}
            replace={replace}
            onClick={onClick}
            onAuxClick={onClick}
        >
            {children}
        </RouterLink>
    ) : (
        <a
            {...rest}
            ref={innerRef}
            href={href}
            className={clsx("ant-typography", className && className)}
            target={target || (lg ? "_blank" : "")}
            rel={rel}
            onClick={onClick}
            onAuxClick={onClick}
        >
            {children}
        </a>
    );
};

export default Link;
