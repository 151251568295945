import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import useBreakpoint                                                   from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                                            from "clsx";
import { useLocation }                                                 from "react-router-dom";
import { getAnalyticsCategory }                                        from "@/analytics/analytics";
import { useDarkModeContext }                                          from "@/context/useDarkModeContext";
import Link                                                            from "@/designSystem/Link/Link";
import Paragraph                                                       from "@/designSystem/Paragraph/Paragraph";
import Title                                                           from "@/designSystem/Title/Title";
import NewsSearchResult                                                from "@/molecules/NewsSearchResult/NewsSearchResult";
import liberty_torch                                                   from "@/static/icons/freespoke_torch.svg";
import loader_torch                                                    from "@/static/images/freespoke_loader_torch.gif";
import loader_torch_dark                                               from "@/static/images/freespoke_loader_torch_dark.gif";
import "./FreespokeLoader.less";

export interface FreespokeLoaderProps {
    className?: string;
}

const FreespokeLoader: FC<React.PropsWithChildren<FreespokeLoaderProps>> = ({ className }) => {
    const { pathname } = useLocation();
    const [isStillLoading, setIsStillLoading] = useState(false);
    const [isFallbackPresent, setIsFallbackPresent] = useState(false);
    const isMounted = useRef(true);
    const { darkMode } = useDarkModeContext();

    const { xs } = useBreakpoint();
    const screenSize = xs ? "small" : "large";

    useEffect(() => {
        setTimeout(function () {
            if (isMounted.current) {
                setIsStillLoading(true);
            }
        }, 5000);

        setTimeout(function () {
            if (isMounted.current) {
                setIsFallbackPresent(true);
            }
        }, 30000);

        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleReloadClick = useCallback(() => {
        window.location.reload();
    }, []);

    const analyticsMeta = useMemo(
        () => ({
            category: getAnalyticsCategory(pathname),
            action: "topic 2 Response highlights click",
        }),
        [pathname]
    );

    return (
        <div className={clsx("FreespokeLoader", className && className)} data-cid="FreespokeLoader">
            {!isFallbackPresent ? (
                <div className="loading-container">
                    <img src={darkMode ? loader_torch_dark : loader_torch} alt="freespoke loading torch" className="loading-img" />
                    <Title className="loading-text">{isStillLoading ? "Still loading..." : "Loading..."}</Title>
                </div>
            ) : (
                <div className="fallback-container">
                    <div className="fallback-header">
                        <div className="inner-container">
                            <Title className="fallback-title" level={1}>
                                Maybe... We’re more popular than We Thought
                            </Title>
                            <Paragraph className="fallback-text">
                                Our site is experiencing a lot of traffic right now, and causing some delays. Try reloading your page in a second.
                            </Paragraph>
                            <button className="reload-link" onClick={handleReloadClick}>
                                Reload Page
                            </button>
                            <Paragraph className="fallback-text mb-0">
                                P.S. - You can check out some of our recommended reading below while you wait.
                            </Paragraph>
                        </div>
                    </div>
                    <div className="fallback-body">
                        <div className="fallback-about-container">
                            <img src={liberty_torch} alt="freespoke liberty torch" className="about-torch-icon" />
                            <Paragraph className="about-text">
                                Freespoke is the new frontier for those people looking to search with America’s founding principles of innovation,
                                freedom and anti-censorship. We know you’re capable of finding answers, you just need a platform that doesn’t bury the
                                full view.
                            </Paragraph>
                            <Link to="/about" className="about-link">
                                Learn more about Freespoke
                            </Link>
                        </div>
                        <div className="fallback-news-container">
                            <Title className="fallback-news-title" level={2}>
                                {topicHighlights[0]?.title}
                            </Title>
                            {topicHighlights[0]?.newsArticles.map(article => (
                                <div key={article.id}>
                                    <NewsSearchResult
                                        article={article}
                                        size={screenSize}
                                        showUrl
                                        publisherDisplayType="top"
                                        analyticsMeta={analyticsMeta}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(FreespokeLoader);

const topicHighlights = [
    {
        title: "The Free Speech Battleground ",
        description: "“In short, the free speech landscape now looks very different than it did even just five years ago.”",
        publisherIcon: "/images/TopicsV2/ipsos.svg",
        publisherName: "Ipsos",
        newsArticles: [
            {
                bias: "left",
                description:
                    "America was built on the premise of free speech, but today's news is filled with examples of limiting expression, including book bans, social media suspensions, and laws restricting classroom discussions on race, sexual orientation and gender identity.",
                id: "80Nzv05Q9n5F9jp-LOf9i",
                images: ["/images/TopicsV2/article1.svg"],
                publisherIcon: "/images/TopicsV2/cbs.svg",
                publisherName: "CBS News",
                title: "How free speech is under attack in the U.S.",
                url: "https://www.cbsnews.com/news/how-free-speech-is-under-attack-in-the-u-s/",
            },
            {
                bias: "right",
                description:
                    "The First Amendment to the U.S. Constitution begins: “Congress shall make no law … abridging the freedom of speech.” Commitment to this freedom, once universally considered fundamental, may be on the rebound. In reviving a lawsuit over the University of Michigan’s censorship code, the U.S. Court of Appeal...",
                id: "80Nzv05Q9n5F9ap-LOf9i",
                images: ["/images/TopicsV2/article2.svg"],
                publisherIcon: "/images/TopicsV2/heritage.svg",
                publisherName: "The Heritage Foundation",
                title: "A Campus Free Speech Case Shows How Fragile Liberty Can Be",
                url: "https://www.heritage.org/civil-society/commentary/campus-free-speech-case-shows-how-fragile-liberty-can-be",
            },
        ],
    },
];
