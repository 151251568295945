import { FC, useCallback }       from "react";
import clsx                      from "clsx";
import { useLocation }           from "react-router-dom";
import { AnalyticsMeta }         from "@/@types/custom";
import { getAnalyticsCategory }  from "@/analytics/analytics";
import { DISPLAY_OUTAGE_BANNER } from "@/config";
import Link                      from "@/designSystem/Link/Link";
import SwitchLite                from "@/designSystem/Switch/SwitchLite";
import { useAnalyticsTracking }  from "@/hooks/useAnalyticsTracking";
import { useAuth }               from "@/hooks/useAuth";
import "./AdFreeToggle.less";

interface AdFreeToggleProps {
    className?: string;
    togglePosition?: "left" | "right" | "premium";
    text?: string;
    analyticsMeta?: AnalyticsMeta;
    showAsPrimary?: boolean;
    adFreeText?: string;
}

export const AdFreeToggle: FC<React.PropsWithChildren<AdFreeToggleProps>> = ({
    className,
    togglePosition = "left",
    text,
    adFreeText,
    analyticsMeta,
    showAsPrimary,
}: AdFreeToggleProps) => {
    const auth = useAuth();
    const { trackEvent } = useAnalyticsTracking();
    const location = useLocation();
    const pageName = getAnalyticsCategory(location.pathname);

    const reportClick = useCallback(() => {
        trackEvent({
            category: analyticsMeta?.category ? analyticsMeta?.category : "ads",
            action: analyticsMeta?.action ? analyticsMeta?.action : `ads get premium click - ${pageName}`,
            name: "click",
        });
    }, [analyticsMeta?.action, analyticsMeta?.category, pageName, trackEvent]);

    if (DISPLAY_OUTAGE_BANNER) {
        return null;
    }

    if (togglePosition === "premium") {
        if (!auth.hasPremiumWithCurrentAccount) {
            text = "Get Premium";
        } else {
            togglePosition = "left";
            text = "Premium On";
        }
    }
    if (auth.hasPremiumWithCurrentAccount) {
        text = adFreeText ?? text;
    }

    const linkTo = auth.hasPremiumWithCurrentAccount ? "/account/profile/premium" : "/premium";

    return (
        <Link className={clsx("AdFreeToggle", className, showAsPrimary && "primary")} to={linkTo} onClick={reportClick}>
            {togglePosition === "left" && <SwitchLite className="switch" size="small" checked={!!auth.hasPremiumWithCurrentAccount} />}
            <span className="text">{text ? text : "Ad Free"}</span>
            {togglePosition === "right" && <SwitchLite className="switch" size="small" checked={!!auth.hasPremiumWithCurrentAccount} />}
        </Link>
    );
};
