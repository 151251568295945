import { ReactElement, cloneElement, useCallback, useMemo } from "react";
import { useMatomo }                                        from "@datapunt/matomo-tracker-react";
import useBreakpoint                                        from "antd/lib/grid/hooks/useBreakpoint";
import { TooltipPlacement }                                 from "antd/lib/tooltip";
import clsx                                                 from "clsx";
import { AnalyticsMeta }                                    from "src/@types/custom";
import { createShareUrl }                                   from "@/helpers/index";
import useClipboard                                         from "@/hooks/useClipboard";
import ShareDropdown, { ShareDropdownItems }                from "@/molecules/ShareDropdown/ShareDropdown";
import { ReactComponent as EmailOutlined }                  from "@/static/icons/email-outlined.svg";
import { ReactComponent as FacebookOutlined }               from "@/static/icons/facebook-outlined.svg";
import { ReactComponent as LinkOutlined }                   from "@/static/icons/link-outlined.svg";
import { ReactComponent as TwitterOutlined }                from "@/static/icons/twitter-outlined.svg";

interface ShareCompressedV3Props {
    className?: string;
    url: string;
    type: "freespoke" | "election" | "christmas";
    placement?: TooltipPlacement;
    triggerAsContainer?: boolean;
    analyticsMeta?: Omit<AnalyticsMeta, "category"> & { name?: string; category?: string };
    children: ReactElement;
}

interface ShareType {
    twitter: {
        text: string;
    };
    email: {
        subject: string;
        getEmailBody: (url: string) => string;
    };
    native: {
        title?: string;
        text: string;
    };
}

export const shareTypes: Record<ShareCompressedV3Props["type"], ShareType> = {
    freespoke: {
        twitter: {
            text: "Check out what I found on Freespoke:",
        },
        email: {
            subject: "🔥 New Freespoke Link Shared with You!",
            getEmailBody: (url: string) => emailBodyForSharingFreespoke(url),
        },
        native: {
            text: "Check out what I found on Freespoke! ",
        },
    },
    election: {
        twitter: {
            text: "Check out Freespoke's Election Portal!",
        },
        email: {
            subject: "Someone Shared the Freespoke Election Portal with You!",
            getEmailBody: (url: string) => emailBodyForSharingElection(url),
        },
        native: {
            text: "Check out Freespoke's Election Portal! ",
        },
    },
    christmas: {
        twitter: {
            text: "Check out Shop USA by Freespoke, an American-made store to shop gifts and support local businesses this holiday season:",
        },
        email: {
            subject: "Someone Shared Shop USA with You 🎁",
            getEmailBody: (url: string) => emailBodyForSharingChristmasShop(url),
        },
        native: {
            title: "Freespoke Shop",
            text: "Check out Shop USA by Freespoke, an American-made store to shop gifts and support local businesses this holiday season:",
        },
    },
};

const ShareCompressedV3 = ({ className, url, placement, type, children, analyticsMeta, triggerAsContainer }: ShareCompressedV3Props) => {
    const { copy } = useClipboard({ defaultText: url });
    const { trackEvent } = useMatomo();
    const { lg } = useBreakpoint();

    const supportsNativeShare = useMemo(() => lg === false && navigator?.share !== undefined, [lg]);

    const handleButtonClick = useCallback(() => {
        navigator
            .share({
                title: shareTypes[type].native.title,
                text: shareTypes[type].native.text,
                url,
            })
            .then(() =>
                trackEvent({
                    category: analyticsMeta?.category || "share",
                    name: analyticsMeta?.name || "share - native",
                    action: analyticsMeta?.action || "share",
                })
            )
            .catch(err => console.error(err));
    }, [analyticsMeta?.action, analyticsMeta?.category, analyticsMeta?.name, trackEvent, type, url]);

    const reportClick = useCallback(
        (label: string) => {
            if (label === "Copy link") {
                copy();

                trackEvent({
                    category: analyticsMeta?.category || "share",
                    name: analyticsMeta?.name || "share - copy link",
                    action: analyticsMeta?.action || "share",
                });
            } else {
                trackEvent({
                    category: analyticsMeta?.category || "share",
                    name: analyticsMeta?.name || `share - ${label.toLocaleLowerCase()}`,
                    action: analyticsMeta?.action || "share",
                });
            }
        },
        [analyticsMeta?.action, analyticsMeta?.category, analyticsMeta?.name, copy, trackEvent]
    );

    const items: ShareDropdownItems = useMemo(
        () => [
            {
                icon: <FacebookOutlined />,
                label: "Facebook",
                href: createShareUrl.facebook(url),
                onClick: () => reportClick("Facebook"),
            },
            {
                icon: <TwitterOutlined />,
                label: "Twitter",
                href: createShareUrl.twitter(url, { text: shareTypes[type].twitter.text, via: "FreespokeSearch", hashtags: ["Freespoke"] }),
                onClick: () => reportClick("Twitter"),
            },
            {
                icon: <EmailOutlined />,
                label: "Email",
                href: createShareUrl.email({ subject: shareTypes[type].email.subject, body: shareTypes[type].email.getEmailBody(url) }),
                onClick: () => reportClick("Email"),
            },
            {
                icon: <LinkOutlined />,
                label: "Copy link",
                onClick: () => reportClick("Copy link"),
            },
        ],
        [reportClick, type, url]
    );

    if (supportsNativeShare) {
        const trigger = cloneElement(children, { onClick: handleButtonClick });
        return <div className={clsx("ShareCompressedV3", className && className)}>{trigger}</div>;
    }

    return (
        <div className={clsx("ShareCompressedV3", className && className)}>
            <ShareDropdown placement={placement} items={items} triggerAsContainer={triggerAsContainer}>
                {children}
            </ShareDropdown>
        </div>
    );
};

export default ShareCompressedV3;

const emailBodyForSharingFreespoke = (url: string) => `Hey there,

I hope this message finds you well. I wanted to share something interesting I found on https://freespoke.com: 

Check out what I found here: ${url}

Freespoke is an amazing search engine that ensures you get results from all viewpoints, making it easy to explore various perspectives on any topic. I thought you might find this link intriguing. 

Happy searching on Freespoke!`;

const emailBodyForSharingElection = (
    url: string
) => `I hope this message finds you well. I wanted to share something interesting I found on Freespoke! 

Freespoke has year-round coverage on all things U.S. elections: results, polling, and the hottest stories. Stay updated on how your political landscape is changing.

You can check out Freespoke's Election Portal here: ${url}`;

const emailBodyForSharingChristmasShop = (
    url: string
) => `I hope this message finds you well. I wanted to share an American-made shopping site I found called Shop USA.  

Shop USA has tons of brands and products all made in the USA. Their goal is to help provide an easy way to shop all things American-made this holiday season. You can find unique gifts for all your friends and family while supporting local American Businesses. 

You can check out Shop USA by Freespoke here: ${url}`;
