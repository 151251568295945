import { useEffect, useRef } from "react";
import { useAuth }           from "@/hooks/useAuth";

export const useRefreshTokenOnInit = (delayMS?: number) => {
    const auth = useAuth();
    const refreshedTokenOnInit = useRef(false);

    // The purpose of this useEffect is to refresh the token manually and silently to detect any changes in the user's premium status.
    // Only do this once on the first render and only if the user is authenticated and does not have premium status.
    useEffect(() => {
        if (!refreshedTokenOnInit.current && auth?.isAuthenticated && !auth.hasPremiumWithCurrentAccount) {
            setTimeout(() => {
                auth.signinSilent().catch(e => console.error(e));
                refreshedTokenOnInit.current = true;
            }, delayMS ?? 0);
        }
    }, [auth, delayMS]);
};
