import { FC, useCallback }      from "react";
import clsx                     from "clsx";
import { useAnalyticsTracking } from "@/hooks/useAnalyticsTracking";
import { useAppBar }            from "@/hooks/useAppBar";
import { useClickOutside }      from "@/hooks/useClickOutside";
import useIsMounted             from "@/hooks/useIsMounted";
import AppBarHeader             from "@/molecules/AppBarHeader/AppBarHeader";
import AppBarMenuV2             from "@/molecules/AppBarMenuV2/AppBarMenuV2";
import "./AppBar.less";

interface AppBarProps {}

const AppBar: FC<React.PropsWithChildren<AppBarProps>> = () => {
    const { trackEvent } = useAnalyticsTracking();
    const isMounted = useIsMounted();
    const { closeHamburger, showHamburger } = useAppBar();

    const { ref } = useClickOutside({
        onClickOutside: () => {
            if (isMounted() && showHamburger) {
                closeHamburger?.();
            }
        },
    });

    const simulatedOnBlur = useCallback(() => {
        trackEvent({
            category: "nav bar",
            name: "hamburger menu on-blur",
            action: "click",
        });
    }, [trackEvent]);

    return (
        <>
            <div className="AppBar">
                <div className={clsx("container", showHamburger && "open")} ref={ref}>
                    <AppBarHeader />
                    <AppBarMenuV2 />
                </div>
                {showHamburger && <div className="AppBarSimulatedOnBlur" onClick={simulatedOnBlur} />}
            </div>
        </>
    );
};

export default AppBar;
