import { FC, useCallback, useState }              from "react";
import useBreakpoint                              from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                       from "clsx";
import { isPast }                                 from "date-fns";
import { useLocation }                            from "react-router-dom";
import { ENABLE_ELECTION_BANNER }                 from "@/config";
import Link                                       from "@/designSystem/Link/Link";
import { DISMISSIBLE_COMPONENTS, StorageService } from "@/helpers/storage";
import { ReactComponent as CloseIcon }            from "@/static/icons/close.svg";
import "./Election2024Banner.less";

interface Election2024BannerProps {
    className?: string;
}

const LOCAL_STORAGE_VALUE = DISMISSIBLE_COMPONENTS.election_2024_rnc_banner;

export const Election2024Banner: FC<React.PropsWithChildren<Election2024BannerProps>> = ({ className }) => {
    const location = useLocation();
    const { sm } = useBreakpoint();

    // NOTE: we have to read the storage on every render, otherwise when user dismissed it might be still displayed on other components (such app bar header)
    const hasDismissed = StorageService.HasDismissedComponentWithinSession.get(LOCAL_STORAGE_VALUE);
    const [showElectionPopup, setShowElectionPopup] = useState<boolean>(!hasDismissed);

    const onElectionBannerDismiss = useCallback(() => {
        setShowElectionPopup(false);
        StorageService.HasDismissedComponentWithinSession.set(LOCAL_STORAGE_VALUE, true);
    }, []);

    const isElectionPage = location.pathname.startsWith("/election/2024");

    if (!ENABLE_ELECTION_BANNER || hasDismissed || !showElectionPopup || isElectionPage || isPast(new Date("July 18, 2024 23:59:59"))) return null;

    return (
        <div className={clsx("Election2024Banner", className && className)}>
            <Link to="/election/2024" className="custom-cta">
                <span className="emoji">🐘</span>
                <span className="description inline-block">
                    {sm ? <>Follow the Republican Convention live with up to date commentary!</> : <>Republican National Convention. </>}
                </span>
                <span className="code inline-block">{sm ? "Click for Live Coverage." : "View Now."}</span>
            </Link>
            <button aria-label="Close" className="close-banner" onClick={onElectionBannerDismiss}>
                <CloseIcon />
            </button>
        </div>
    );
};
