import { FC, createContext, useContext, useMemo } from "react";

type MobileAppDetectionContext = {
    isMobileApp: boolean;
};
const mobileAppDetectionContext = createContext<MobileAppDetectionContext>({ isMobileApp: false });

export const MobileAppDetectionProvider: FC<React.PropsWithChildren<MobileAppDetectionContext>> = ({ children, isMobileApp }) => {
    const value = useMemo(() => ({ isMobileApp }), [isMobileApp]);
    return <mobileAppDetectionContext.Provider value={value}>{children}</mobileAppDetectionContext.Provider>;
};

export const useMobileAppDetection = () => useContext(mobileAppDetectionContext);
