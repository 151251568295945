import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useBreakpoint                                     from "antd/lib/grid/hooks/useBreakpoint";
import clsx                                              from "clsx";
import { isChrome, isFirefox, isMobile }                 from "react-device-detect";
import { createPortal }                                  from "react-dom";
import { useLocation }                                   from "react-router-dom";
import { ICampaignVariant }                              from "@/@types/custom";
import { getAnalyticsCategory }                          from "@/analytics/analytics";
import useGetCampaignVariant                             from "@/api/marketing/useGetCampaignVariant";
import { useDarkModeContext }                            from "@/context/useDarkModeContext";
import { useGeneralContext }                             from "@/context/useGeneralContext";
import Button                                            from "@/designSystem/Button/Button";
import Link                                              from "@/designSystem/Link/Link";
import Title                                             from "@/designSystem/Title/Title";
import { StorageService }                                from "@/helpers/storage";
import { useAnalyticsTracking }                          from "@/hooks/useAnalyticsTracking";
import { useAuth }                                       from "@/hooks/useAuth";
import { useClickOutside }                               from "@/hooks/useClickOutside";
import { useExtensionDetection }                         from "@/hooks/useExtensionDetection";
import useIsMounted                                      from "@/hooks/useIsMounted";
import AppDownloadBadge                                  from "@/molecules/AppDownloadBadge/AppDownloadBadge";
import { ExtensionDownloadLink }                         from "@/molecules/ExtensionDownloadLink/ExtensionDownloadLink";
import { ReactComponent as CheckMark }                   from "@/static/icons/checkmark-2.svg";
import { ReactComponent as CloseIcon }                   from "@/static/icons/close-2.svg";
import { ReactComponent as ShieldFlame }                 from "@/static/icons/shield-flame.svg";
import "./DynamicButtonVariantWithPopup.less";

interface DynamicButtonVariantWithPopupProps {
    className?: string;
    btnClassName?: string;
    enableAutoPopup?: boolean; // whether this component should trigger auto-popup logic, setting this to true doesn't guarantee that popup will be visible
    campaignVariant?: ICampaignVariant; // override for use with previews
    hideButton?: boolean;
}

let autoPopupHasBeenDisplayed = false;

export const DynamicButtonVariantWithPopup: FC<React.PropsWithChildren<DynamicButtonVariantWithPopupProps>> = ({
    className,
    btnClassName,
    campaignVariant,
    enableAutoPopup,
    hideButton,
}) => {
    const auth = useAuth();
    const { trackEvent } = useAnalyticsTracking();
    const { darkMode } = useDarkModeContext();
    const { md } = useBreakpoint();
    const { extensionCheckCompleted, isChromeExtensionInstalled, isFirefoxExtensionInstalled } = useExtensionDetection();
    const isMounted = useIsMounted();
    const location = useLocation();
    const { shouldTriggerPopup } = useGeneralContext();

    const urlParams = new URLSearchParams(location.search);
    const pageName = getAnalyticsCategory(location.pathname);
    const { data: variantData } = useGetCampaignVariant({
        type: "download-popup",
        mkt_campaign: urlParams.get("mkt_campaign") || "",
    });
    const [displayPopup, setDisplayPopup] = useState<boolean | null>(null);
    const [skipDismissOnClickOutside, setSkipDismissOnClickOutside] = useState<boolean>(false);
    const [hidden, setHidden] = useState<boolean>(true);

    const isFreespokeBrowser = !!navigator?.userAgent?.toLowerCase().includes("freespoke"); // User is using our mobile app browser
    const hasFreespokeExtensionInstalled = isChromeExtensionInstalled || isFirefoxExtensionInstalled; // User is using Firefox or Chrome browser with Freespoke extension installed

    // Initially we hide the button until we find out if user has extension installed or not
    useEffect(() => {
        if (extensionCheckCompleted && isMounted()) {
            setHidden(false);
        }
    }, [extensionCheckCompleted, isChromeExtensionInstalled, isFirefoxExtensionInstalled, isMounted]);

    const variant: "override-campaign" | "custom-campaign" | "app" | "ad-free" | "extension" | "raaf_top_story_traffic_676" | null = useMemo(() => {
        if (campaignVariant) {
            // This is really just for previews
            return "override-campaign";
        }

        if (variantData) {
            return "custom-campaign";
        }

        if (!extensionCheckCompleted || auth.isLoading) {
            return null;
        }

        // TEMP: remove it once we can manage campaigns via admin panel
        if (StorageService.MarketingCampaignId.get() === "raaf_top_story_traffic_676") {
            return "raaf_top_story_traffic_676";
        }

        // User is using:
        //  - Freespoke Browser App OR
        //  - Firefox or Chrome browser with Freespoke extension installed
        if (isFreespokeBrowser || hasFreespokeExtensionInstalled) {
            if (!auth.hasPremiumWithCurrentAccount) {
                return "ad-free";
            }
            return null;
        }

        // User is using:
        // - any mobile device and NOT Freespoke Browser App
        if (isMobile) {
            return "app";
        }

        // User is using:
        // - Desktop Firefox OR
        // - Desktop Chrome
        if (isFirefox || isChrome) {
            return "extension";
        }

        // Default
        if (!auth.hasPremiumWithCurrentAccount) {
            return "ad-free";
        }

        return null;
    }, [
        auth.hasPremiumWithCurrentAccount,
        auth.isLoading,
        campaignVariant,
        extensionCheckCompleted,
        hasFreespokeExtensionInstalled,
        isFreespokeBrowser,
        variantData,
    ]);

    const analyticsVariantName = useMemo(() => (variant === "custom-campaign" || variant === "override-campaign" ? "campaign" : variant), [variant]);

    const { ref } = useClickOutside({
        onClickOutside: () => {
            if (skipDismissOnClickOutside) {
                return;
            }
            if (isMounted()) {
                setDisplayPopup(false);
            }
        },
    });

    const handleCloseButtonClick = useCallback(() => {
        setSkipDismissOnClickOutside(false);
        setDisplayPopup(false);

        trackEvent({
            category: "Pop Up",
            name: "",
            action: `pop up ${analyticsVariantName} close - ${pageName}`,
        });
    }, [analyticsVariantName, pageName, trackEvent]);

    const isAutoPopupPage = useMemo(() => !location.pathname.startsWith("/story"), [location.pathname]);

    const shouldAutoPopup = (isAutoPopupPage || shouldTriggerPopup) && !isFreespokeBrowser;

    // Auto pop up logic
    useEffect(() => {
        if (
            variant !== "override-campaign" &&
            variant !== "custom-campaign" &&
            (!StorageService.MarketingCampaignId.get()?.startsWith("ra") || StorageService.HasShownAppOrExtensionPopup.get())
        ) {
            return;
        }

        if (variant === "custom-campaign" && (!variantData || StorageService.HasShownCampaignPopup.get(variantData.id))) {
            return;
        }

        let timeout: ReturnType<typeof setTimeout> | null = null;

        if (enableAutoPopup && shouldAutoPopup && !timeout && !displayPopup && !hidden) {
            timeout = setTimeout(() => {
                if (!autoPopupHasBeenDisplayed && isMounted() && shouldAutoPopup && enableAutoPopup && !displayPopup) {
                    setSkipDismissOnClickOutside(true);
                    autoPopupHasBeenDisplayed = true;
                    setDisplayPopup(true);
                    if (variant === "custom-campaign") {
                        StorageService.HasShownCampaignPopup.set(variantData!.id, true);
                    } else {
                        StorageService.HasShownAppOrExtensionPopup.set(true);
                    }
                }
            }, 1000);
        }
    }, [displayPopup, hidden, isAutoPopupPage, isMounted, shouldAutoPopup, enableAutoPopup, variant, variantData]);

    useEffect(() => {
        if (displayPopup) {
            trackEvent({
                category: "Pop Up",
                name: "",
                action: `pop up ${analyticsVariantName} view - ${pageName}`,
            });
            trackEvent({
                category: "home",
                // action: `pop up ${analyticsVariantName} view - ${pageName}`,
                action: `home nav CTA click`,
                name: "click",
            });
        }
    }, [analyticsVariantName, displayPopup, pageName, trackEvent, variant]);

    const reportDownloadBadgeClick = useCallback(() => {
        trackEvent({
            category: "Pop Up",
            action: `pop up app cta click - ${pageName}`,
            name: "click",
        });
    }, [pageName, trackEvent]);

    const reportAddExtensionClick = useCallback(
        (_browser: string) => {
            trackEvent({
                category: "Pop Up",
                action: `pop up ${analyticsVariantName} cta click - ${pageName}`,
                name: "click",
            });
        },
        [analyticsVariantName, pageName, trackEvent]
    );

    // const reportAdFreeClick = useCallback(() => {
    //     trackEvent({
    //         category: "Pop Up",
    //         action: `pop up ad-free cta click - ${pageName}`,
    //         name: "click",
    //     });
    // }, [pageName, trackEvent]);

    const reportCampaignClick = useCallback(() => {
        trackEvent({
            category: "Pop Up",
            action: `pop up campaign cta click - ${pageName}`,
            name: "click",
        });
    }, [pageName, trackEvent]);

    const popupContent = useMemo(() => {
        if (campaignVariant || (variant === "custom-campaign" && variantData)) {
            const data = (campaignVariant || variantData)!;
            return {
                btnTitle: data.cta_btn_text || (!data.btn_link && !data.btn_text ? "Download Freespoke" : "Support Our Mission"),
                content: data.content?.map(x => x.html),
                subtitle: data.subtitle,
                title: data.title,
                footer:
                    data.btn_link && data.btn_text ? (
                        <Link className="ad-free-btn" href={data.btn_link} onClick={reportCampaignClick}>
                            {data.btn_text}
                        </Link>
                    ) : md ? (
                        <ExtensionDownloadLink onClickAddExtension={reportAddExtensionClick} />
                    ) : (
                        <div className="app-button-container">
                            <AppDownloadBadge type="ios-alt" reportClick={reportDownloadBadgeClick} />
                            <AppDownloadBadge type="android" reportClick={reportDownloadBadgeClick} />
                        </div>
                    ),
            };
        }

        switch (variant) {
            case "raaf_top_story_traffic_676":
                return {
                    btnTitle: "Download Freespoke",
                    title: "Full Privacy. No Censorship. Freespoke is the Free Speech Search Engine",
                    subtitle: "Check out our Free App:",
                    content: ["Uncensored Web Search", "100% Privacy Protection", "Shows You Stories That Google Won’t"],
                    footer: (
                        <div className="app-button-container">
                            <AppDownloadBadge type="ios-alt" reportClick={reportDownloadBadgeClick} />
                            <AppDownloadBadge type="android" reportClick={reportDownloadBadgeClick} />
                        </div>
                    ),
                };
            case "extension":
                return {
                    btnTitle: "Download Freespoke",
                    title: "Full Privacy. No Censorship. Freespoke is the Free Speech Search Engine",
                    subtitle: "Protect Your Search Data with Freespoke",
                    content: ["Have full anonymity", "Get fuller search results", "See news from across the spectrum"],
                    footer: <ExtensionDownloadLink onClickAddExtension={reportAddExtensionClick} />,
                };
            case "app":
                return {
                    btnTitle: "Download Freespoke",
                    title: "Full Privacy. No Censorship. Freespoke is the Free Speech Search Engine",
                    subtitle: "There's an app for this...",
                    content: ["Uncensored web search", "News from all perspectives", "Full privacy protection"],
                    footer: (
                        <div className="app-button-container">
                            <AppDownloadBadge type="ios-alt" reportClick={reportDownloadBadgeClick} />
                            <AppDownloadBadge type="android" reportClick={reportDownloadBadgeClick} />
                        </div>
                    ),
                };
            case "ad-free":
                return null;
            // now covered by Get Premium Button
            // return {
            //     btnTitle: "Support Freespoke",
            //     title: "Support <em>Freespoke</em> and <em>Free Speech</em> by Subscribing",
            //     subtitle: "Remove Ads and Support Our Mission",
            //     content: ["Remove distracting ads ", "Help support Freespoke", "Only see the content you love"],
            //     footer: (
            //         <Link className="ad-free-btn" href={CONSTANTS.LINKS.AD_FREE_PREMIUM_SIGNUP} onClick={reportAdFreeClick}>
            //             Go Ad-free
            //         </Link>
            //     ),
            // };
            default:
                return null;
        }
    }, [campaignVariant, md, reportAddExtensionClick, reportCampaignClick, reportDownloadBadgeClick, variant, variantData]);

    const content = useMemo(
        () =>
            popupContent ? (
                <div className={clsx("DynamicButtonVariantWithPopup modal", darkMode && "ff-darkmode")} ref={ref}>
                    <Title level={1} className="title">
                        <span dangerouslySetInnerHTML={{ __html: popupContent.title }} />
                    </Title>
                    <div className="body">
                        <div className="PopupContent">
                            <div className="popup-content-inner">
                                <ShieldFlame className="content-icon" />
                                <Title className="content-title" level={2}>
                                    {popupContent.subtitle}
                                </Title>
                                <ul className="list">
                                    {popupContent.content?.map((item, i) => (
                                        <ListItem key={i} html={item} />
                                    ))}
                                </ul>
                            </div>
                            {popupContent.footer}
                        </div>
                        <button className="close-button" onClick={handleCloseButtonClick}>
                            <CloseIcon className="icon" />
                        </button>
                    </div>
                </div>
            ) : null,
        [darkMode, handleCloseButtonClick, popupContent, ref]
    );

    if (!content) {
        return null;
    }

    return (
        <>
            <div className={clsx("DynamicButtonVariantWithPopup", className && className, hidden && "hidden")}>
                {!hideButton && (
                    <Button className={clsx("button", btnClassName && btnClassName)} onClick={() => setDisplayPopup(true)} type="primary">
                        {popupContent?.btnTitle}
                    </Button>
                )}
                {displayPopup &&
                    popupContent &&
                    (md
                        ? content
                        : createPortal(<div className={clsx(darkMode && "ff-darkmode")}>{content}</div>, document.body, "dynamic-button-cta"))}
            </div>
        </>
    );
};

interface ListItemProps {
    html: string;
}

const ListItem: FC<React.PropsWithChildren<ListItemProps>> = ({ html }) => {
    const { md } = useBreakpoint();

    return (
        <li className="ListItem">
            {md === false && <CheckMark className="icon-checkmark" />}
            <span dangerouslySetInnerHTML={{ __html: html }} />
        </li>
    );
};
