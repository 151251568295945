// eslint-disable-next-line no-restricted-imports
import { OidcClientSettings }              from "oidc-client-ts";
import { OPENID_CLIENT_ID, OPENID_ISSUER } from "@/config";

export const oidcConfig: OidcClientSettings = {
    authority: OPENID_ISSUER!,
    client_id: OPENID_CLIENT_ID!,
    redirect_uri: window.location.origin,
    response_type: "code", // Use "code" for Authorization Code Flow with PKCE
    scope: "openid", // Scopes you are requesting from the OIDC provider
    post_logout_redirect_uri: window.location.origin + "/", // Where to redirect after logout,
};
