import { FC }                 from "react";
import { Modal }              from "antd";
import clsx                   from "clsx";
import { useDarkModeContext } from "@/context/useDarkModeContext";
import useLockBodyScroll      from "@/hooks/useLockBodyScroll";
import "./WrapperModal.less";

interface WrapperModalProps {
    className?: string;
    isModalVisible: boolean;
    onClose: () => void;
    children: JSX.Element;
    onlyLightMode?: boolean;
}

export const WrapperModal: FC<WrapperModalProps> = ({ className, children, isModalVisible, onClose, onlyLightMode }) => {
    const { darkMode } = useDarkModeContext();
    useLockBodyScroll(true);

    return (
        <Modal
            wrapClassName="WrapperModal-outer"
            className={clsx("WrapperModal", className, darkMode && !onlyLightMode && "ff-darkmode")}
            open={isModalVisible}
            onCancel={onClose}
            footer={null}
        >
            {children}
        </Modal>
    );
};
