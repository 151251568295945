// Default OGP images and everything related to Open Graph Protocol

/*
 * Store all OGP images inside the /public/_ogp folder. Do not put them in the src/static folder, otherwise you could break things when you update that image due to cache.
 * if you need to update the image replace the original and keep the same name, and (!Important) bump up the version, ex: ?version=2
 */
export const DEFAULT_OGP_IMAGE_ABSOLUTE_PATH = `${window.origin}/_ogp/freespoke_default_ogp_image.jpg?version=4`;
export const TWITTER_OGP_IMAGE_ABSOLUTE_PATH = `${window.origin}/_ogp/freespoke_twitter_ogp_image.jpg?version=2`;
export const ELECTION_DEFAULT_OGP_IMAGE_ABSOLUTE_PATH = `${window.origin}/_ogp/election2024_default_ogp_image.png`;
export const ELECTION_TWITTER_OGP_IMAGE_ABSOLUTE_PATH = `${window.origin}/_ogp/election2024_twitter_ogp_image.png`;
export const OGP_DEFAULT_TITLE = "Freespoke Search";
export const OGP_DEFAULT_DESCRIPTION =
    "Freespoke is your new alternative to Big Tech search engines. Search the web without censorship and with anonymous searching - Try Freespoke today!";
