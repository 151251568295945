import React, { useEffect, useState } from "react";
import useIsMounted                   from "@/hooks/useIsMounted";

type DelayedProps = {
    children: React.ReactNode;
    timeToWait?: number;
};

const Delayed = ({ children, timeToWait = 500 }: DelayedProps) => {
    const [isShown, setIsShown] = useState(false);
    const isMounted = useIsMounted();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isMounted()) {
                setIsShown(true);
            }
        }, timeToWait);
        return () => clearTimeout(timer);
    }, [isMounted, timeToWait]);

    return isShown ? <>{children}</> : null;
};

export default Delayed;
