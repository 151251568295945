import { FC, useCallback, useEffect, useMemo, useRef, useState }                       from "react";
import { useMatomo }                                                                   from "@datapunt/matomo-tracker-react";
import { Collapse, Skeleton }                                                          from "antd";
import CollapsePanel                                                                   from "antd/lib/collapse/CollapsePanel";
import useBreakpoint                                                                   from "antd/lib/grid/hooks/useBreakpoint";
import SkeletonAvatar                                                                  from "antd/lib/skeleton/Avatar";
import clsx                                                                            from "clsx";
import { useResizeDetector }                                                           from "react-resize-detector/build/withPolyfill";
import { useLocation }                                                                 from "react-router-dom";
import { animated, useSpring }                                                         from "react-spring";
import { AnalyticsMeta }                                                               from "@/@types/custom";
import useGetShopCategories                                                            from "@/api/shop/useGetShopCategories";
import useGetShopHomepage                                                              from "@/api/shop/useGetShopHomepage";
import { A_B_VERSION, DISPLAY_OUTAGE_BANNER, HIDE_AUTH_BUTTONS, HIDE_PREMIUM_BUTTONS } from "@/config";
import { useDarkModeContext }                                                          from "@/context/useDarkModeContext";
import Button                                                                          from "@/designSystem/Button/Button";
import Link                                                                            from "@/designSystem/Link/Link";
import SwitchLite                                                                      from "@/designSystem/Switch/SwitchLite";
import { CONSTANTS }                                                                   from "@/helpers/constants";
import { getRedirectURL, isTruthy }                                                    from "@/helpers/index";
import { getExtensionBrowserType, getHowToInstallExtensionModalLink }                  from "@/hooks/extension/useGetExtensionLinkData";
import { useAnalyticsTracking }                                                        from "@/hooks/useAnalyticsTracking";
import { useAppBar }                                                                   from "@/hooks/useAppBar";
import { useAuth }                                                                     from "@/hooks/useAuth";
import useLockBodyScroll                                                               from "@/hooks/useLockBodyScroll";
import { useMobileAppDetection }                                                       from "@/hooks/useMobileAppDetection";
import { AdFreeToggle }                                                                from "@/molecules/AdFreeToggle/AdFreeToggle";
import AppDownloadBadge                                                                from "@/molecules/AppDownloadBadge/AppDownloadBadge";
import { DynamicButtonVariantWithPopup }                                               from "@/organisms/DynamicButtonVariantWithPopup/DynamicButtonVariantWithPopup";
import { ReactComponent as ChevronDown }                                               from "@/static/icons/chevron-down.svg";
import { ReactComponent as FlameIcon }                                                 from "@/static/icons/freespoke-flame-outlined.svg";
import { ReactComponent as MagnifyingGlass }                                           from "@/static/icons/magnifying-glass.svg";
import { ReactComponent as NewsIcon }                                                  from "@/static/icons/news.svg";
import { ReactComponent as ShopIcon }                                                  from "@/static/icons/shop.svg";
import "./AppBarMenuV2.less";

const KEY_NAME_ESC = "Escape";
const KEY_EVENT_TYPE = "keyup";

interface AppBarMenuV2Props {
    className?: string;
}

const AppBarMenuV2: FC<React.PropsWithChildren<AppBarMenuV2Props>> = () => {
    const { closeHamburger, showHamburger } = useAppBar();
    const { lg, xl } = useBreakpoint();
    const { trackEvent } = useMatomo();
    const { darkMode, toggleDarkMode } = useDarkModeContext();
    const location = useLocation();
    const redirectURL = getRedirectURL(location);

    const { isAuthenticated } = useAuth();

    const isShopUSA = location.pathname.startsWith("/shop");

    // Prevent body from scrolling when menu is open
    useLockBodyScroll(!!showHamburger);

    const [isMoreResourcesExpanded, setIsMoreResourcesExpanded] = useState<boolean | null>(null);

    // Close hamburger menu when location changes
    useEffect(() => {
        closeHamburger?.();
    }, [closeHamburger, location]);

    const handleEscKey = useCallback(
        (event: any) => {
            if (event?.key === KEY_NAME_ESC && showHamburger) {
                closeHamburger?.();
            }
        },
        [closeHamburger, showHamburger]
    );

    useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
    }, [handleEscKey]);

    // Animation for menu open/close
    const menuSpring = useSpring(
        showHamburger
            ? {
                  transform: `translateY(0%)`,
                  opacity: 1,
              }
            : {
                  transform: `translateY(-150%)`,
                  opacity: 0,
              }
    );

    const onResize = useCallback((_width?: number, height?: number) => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-menu-height", `${height}px`);
    }, []);

    const { ref } = useResizeDetector({
        handleHeight: true,
        handleWidth: false,
        refreshMode: "debounce",
        refreshRate: 300,
        onResize,
    });

    const handleMoreResourcesClick = useCallback(() => {
        setIsMoreResourcesExpanded(!isMoreResourcesExpanded);
    }, [isMoreResourcesExpanded]);

    const onDarkModeToggleClick = useCallback(() => {
        const reportShop = isShopUSA && !xl;
        trackEvent({
            category: reportShop ? "shop us" : "nav bar",
            action: `${reportShop ? "shop " : ""}nav bar ${reportShop ? "hamburger menu " : ""}dark mode toggle - ${darkMode ? "off" : "on"}`, // darkMode represents the state before it gets toggled
            name: "click",
        });

        toggleDarkMode();
    }, [darkMode, isShopUSA, toggleDarkMode, trackEvent, xl]);

    const reportDownloadBadgeClick = useCallback(() => {
        trackEvent({
            category: "nav bar",
            action: "nav bar app download cta click",
            name: "click",
        });
    }, [trackEvent]);

    const handleNavLinkClick = useCallback(
        (label: string) => {
            trackEvent({
                category: isShopUSA && !xl ? "shop us" : "nav bar",
                action: isShopUSA && !xl ? `shop nav bar hamburger menu ${label} click` : `nav bar feature click - ${label}`,
                name: "click",
            });
        },
        [isShopUSA, trackEvent, xl]
    );

    const handleMoreResourcesLinkClick = useCallback(
        (label: string) => {
            trackEvent({
                category: "nav bar",
                action: `nav bar more resources click - ${label}`,
                name: "click",
            });
        },
        [trackEvent]
    );

    const getIcon = useCallback((linkName: "magnifying glass" | "flame" | "page" | "shop") => {
        switch (linkName) {
            case "magnifying glass":
                return <MagnifyingGlass className="nav-icon" />;
            case "flame":
                return <FlameIcon className="nav-icon" />;
            case "shop":
                return <ShopIcon className="nav-icon shop-icon" />;
            case "page":
                return <NewsIcon className="nav-icon" />;
            default:
                return <FlameIcon className="nav-icon" />;
        }
    }, []);

    const navMenuLinkMobile = useCallback(
        ({ label, to, icon }: any, idx: any) => {
            return (
                <li className="nav-item" key={idx}>
                    <Link to={to} className="nav-link" onClick={() => handleNavLinkClick(label)}>
                        {label}
                        {getIcon(icon)}
                    </Link>
                </li>
            );
        },
        [getIcon, handleNavLinkClick]
    );

    const navMenuLinkDesktop = useCallback(
        ({ label, to, icon, description }: any, idx: any) => {
            return (
                <li key={idx} className="nav-item">
                    <Link to={to} className="nav-link" onClick={() => handleNavLinkClick(label)}>
                        <div className="header">
                            <p className="title">{label}</p>
                            {getIcon(icon)}
                        </div>
                        <p className="description">{description}</p>
                    </Link>
                </li>
            );
        },
        [getIcon, handleNavLinkClick]
    );

    const moreResourcesLink = useCallback(
        (link: any, idx: any) => {
            return (
                <Link key={idx} className="resource-link" {...link} onClick={() => handleMoreResourcesLinkClick(link.label)}>
                    {link.label}
                </Link>
            );
        },
        [handleMoreResourcesLinkClick]
    );

    const { isMobileApp } = useMobileAppDetection();

    const menuMobile = useMemo(
        () => (
            <div>
                {(!HIDE_AUTH_BUTTONS || isAuthenticated) && !DISPLAY_OUTAGE_BANNER && (
                    <div className="nav-buttons">
                        <Link className="link" to={isAuthenticated ? "/account/profile" : "/signup"}>
                            {isAuthenticated ? "View My Account" : "Sign Up"}
                        </Link>
                        <Link
                            className="link"
                            to={
                                isAuthenticated
                                    ? "/logout"
                                    : {
                                          pathname: "/login",
                                          search: `?redirect_uri=${redirectURL}`,
                                      }
                            }
                        >
                            {isAuthenticated ? "Log Out" : "Log In"}
                        </Link>
                    </div>
                )}

                {isShopUSA && <ShopNavAddOn />}

                <div className="navigation-mobile">
                    <ul className="nav-list">
                        {navLinks({ hideShop: isShopUSA }).map(navMenuLinkMobile)}
                        {!isShopUSA && (
                            <li key="nav-acc">
                                <NavMenuAccordion onLinkClick={handleMoreResourcesLinkClick} />
                            </li>
                        )}
                        {!HIDE_PREMIUM_BUTTONS && !DISPLAY_OUTAGE_BANNER && (
                            <li key="ad-free" className="nav-item">
                                <AdFreeToggle
                                    className="ad-free-toggle"
                                    togglePosition="right"
                                    analyticsMeta={{ category: "nav bar", action: "nav bar ad free toggle" }}
                                    text="Premium Off"
                                    adFreeText="Premium On"
                                />
                            </li>
                        )}
                        <li key="darkmode" className="nav-item">
                            <Button className="darkmode-toggle" onClick={onDarkModeToggleClick} type="ghost">
                                <span className="nav-text">Dark Mode</span>
                                <SwitchLite checked={darkMode} size="small" />
                            </Button>
                        </li>
                        <DynamicButtonVariantWithPopup btnClassName="dynamic-cta-button" enableAutoPopup hideButton />
                    </ul>
                    <div className="nav-footer">
                        {!isMobileApp && (
                            <>
                                <p className="footer-text">Get the Freespoke App for the Best Experience.</p>
                                <div className="badge-container">
                                    <AppDownloadBadge className="app-download-badge" uniform type="ios" reportClick={reportDownloadBadgeClick} />
                                    <AppDownloadBadge className="app-download-badge" uniform type="android" reportClick={reportDownloadBadgeClick} />
                                </div>
                            </>
                        )}
                        <div className="version-block">
                            <span className="version-text">{`${process.env.REACT_APP_VERSION} ${
                                A_B_VERSION === "a" ? "A" : "B"
                            } (build ${process.env.REACT_APP_COMMIT_HASH?.slice(0, 7)})`}</span>
                        </div>
                    </div>
                </div>
            </div>
        ),
        [
            darkMode,
            handleMoreResourcesLinkClick,
            isAuthenticated,
            isMobileApp,
            isShopUSA,
            navMenuLinkMobile,
            onDarkModeToggleClick,
            redirectURL,
            reportDownloadBadgeClick,
        ]
    );

    const menuDesktop = useMemo(
        () => (
            <div className="navigation-desktop">
                <div className="nav-header">
                    <div className="block">
                        <p className="header-text">Get the Freespoke App for the Best Experience.</p>
                        <AppDownloadBadge className="app-download-badge" uniform type="ios" reportClick={reportDownloadBadgeClick} />
                        <AppDownloadBadge className="app-download-badge" uniform type="android" reportClick={reportDownloadBadgeClick} />
                    </div>
                    <div className="block">
                        {!HIDE_PREMIUM_BUTTONS && (
                            <AdFreeToggle
                                className="ad-free-toggle"
                                togglePosition="left"
                                text="Premium Off"
                                adFreeText="Premium On"
                                analyticsMeta={{ category: "nav bar", action: "nav bar ad free toggle" }}
                            />
                        )}
                        <button className="darkmode-toggle" onClick={onDarkModeToggleClick}>
                            <SwitchLite checked={darkMode} size="small" />
                            <span className="text">Dark Mode</span>
                        </button>
                    </div>
                </div>
                <ul className="nav-list">
                    {navLinks().map(navMenuLinkDesktop)}
                    <li key="more" className={clsx("nav-item more-resources-button", isMoreResourcesExpanded && "expanded")}>
                        <button className="nav-link" onClick={handleMoreResourcesClick}>
                            <div className="header">
                                <p className="title">More Resources</p>
                                <ChevronDown className="nav-icon" />
                            </div>
                            <p className="description">Explore links to areas like our blogs, support, and more.</p>
                        </button>
                    </li>
                </ul>
                {isMoreResourcesExpanded && (
                    <div className="more-resources-container">
                        {lg && (
                            <div className="version-block">
                                <span className="version-text">{`${process.env.REACT_APP_VERSION} ${
                                    A_B_VERSION === "a" ? "A" : "B"
                                } (build ${process.env.REACT_APP_COMMIT_HASH?.slice(0, 7)})`}</span>
                            </div>
                        )}
                        <div className="resource-column" />
                        <div className="resource-column">{moreResourcesLinksDesktop.columnOne.map(moreResourcesLink)}</div>
                        <div className="resource-column">{moreResourcesLinksDesktop.columnTwo.map(moreResourcesLink)}</div>
                        <div className="resource-column">{moreResourcesLinksDesktop.columnThree.map(moreResourcesLink)}</div>
                        <div className="resource-column">{moreResourcesLinksDesktop.columnFour.map(moreResourcesLink)}</div>
                    </div>
                )}
            </div>
        ),
        [
            darkMode,
            handleMoreResourcesClick,
            isMoreResourcesExpanded,
            lg,
            moreResourcesLink,
            navMenuLinkDesktop,
            onDarkModeToggleClick,
            reportDownloadBadgeClick,
        ]
    );

    return (
        <animated.div className={clsx("AppBarMenuV2", isShopUSA && "shop-menu")} ref={ref} style={menuSpring}>
            {!lg || (isShopUSA && !xl) ? menuMobile : menuDesktop}
        </animated.div>
    );
};

export default AppBarMenuV2;

interface NavMenuAccordionProps {
    onLinkClick: (label: string) => void;
}

const NavMenuAccordion: FC<React.PropsWithChildren<NavMenuAccordionProps>> = ({ onLinkClick }) => {
    const [isExpanded, setIsExpanded] = useState<boolean | null>(null);
    const outerRef = useRef<HTMLDivElement | null>(null);

    const handleOnClick = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

    useEffect(() => {
        if (isExpanded) {
            outerRef?.current?.scrollIntoView();
        }
    }, [isExpanded]);

    return (
        <div id="accordionGroup" className="NavMenuAccordion" ref={outerRef}>
            <button
                className="accordion-button"
                type="button"
                aria-expanded={isExpanded ? true : "false"}
                aria-controls="accordionList"
                id="navAccordion"
                onClick={handleOnClick}
            >
                <span className={clsx("accordion-title", isExpanded && "expanded")}>More Resources</span>
                <ChevronDown className={clsx("accordion-icon", isExpanded && "expanded")} />
            </button>

            <ul className={clsx("accordion-list", !isExpanded && "hidden")} id="accordionList" role="region" aria-labelledby="navAccordion">
                {moreResourcesLinksMobile.map((link, i) => (
                    <Link
                        key={i}
                        className={clsx("accordion-link", i === moreResourcesLinksMobile.length - 1 && "last")}
                        {...link}
                        onClick={() => onLinkClick(link.label)}
                    >
                        {link.label}
                    </Link>
                ))}
            </ul>
        </div>
    );
};

type NavLinkProps = {
    hideShop?: boolean;
};
const navLinks = ({ hideShop }: NavLinkProps = {}) =>
    [
        { label: "Freespoke Search", to: "/", icon: "magnifying glass", description: "Search with privacy and discover unbiased balanced results." },
        { label: "Trending News", to: "/#news", icon: "page", description: "Real-time news coverage showing you all sides to the story." },
        !hideShop && { label: "Shop USA Store", to: "/shop", icon: "shop", description: "American made and Veteran owned brands and products." },
        { label: "About Freespoke", to: "/about-freespoke", icon: "flame", description: "Learn about us and the best way to start using Freespoke." },
    ].filter(isTruthy);

const moreResourcesLinksDesktop = {
    columnOne: [
        { label: "About Shop USA", to: "/about-shop-usa" },
        { label: "Privacy Policy", to: "/privacy-policy" },
        { label: "Terms", to: "/terms-of-service" },
        { label: "Notice of Collection", to: "/notice-of-collection" },
    ],
    columnTwo: [
        { label: "Twitter", href: CONSTANTS.LINKS.TWITTER },
        { label: "Facebook", href: CONSTANTS.LINKS.FACEBOOK },
        { label: "Instagram", href: CONSTANTS.LINKS.INSTAGRAM },
        { label: "LinkedIn", href: CONSTANTS.LINKS.LINKED_IN },
        { label: "Youtube", href: CONSTANTS.LINKS.YOUTUBE },
    ],
    columnThree: [
        { label: "Stories of Suppression", to: "/category/censored-stories" },
        { label: "Best of the Web", to: "/category/best-of-the-web" },
        { label: "What's Hot", to: "/what-is-hot" },
        { label: "Featured Story", to: "/#featured" },
        { label: "Spangld Take", href: CONSTANTS.LINKS.SPANGLD },
    ],
    columnFour: [
        { label: "2024 Elections", to: "/election/2024" },
        { label: "Get in Touch", href: CONSTANTS.LINKS.FRESHDESK_NEW_TICKET },
        { label: "Freespoke Blog", href: CONSTANTS.LINKS.BLOG },
        { label: "Get the Newsletter", to: "/join/step-1" },
        {
            label: "Add Browser Extension",
            to: getHowToInstallExtensionModalLink(getExtensionBrowserType()),
        },
        { label: "Freespoke Premium", href: CONSTANTS.LINKS.PREMIUM.PREMIUM },
    ],
};

const moreResourcesLinksMobile = [
    ...moreResourcesLinksDesktop.columnFour,
    ...moreResourcesLinksDesktop.columnThree,
    ...moreResourcesLinksDesktop.columnTwo,
    ...moreResourcesLinksDesktop.columnOne,
];

const ShopNavAddOn = () => {
    const { lg } = useBreakpoint();
    const { closeHamburger } = useAppBar();
    const { data: { featured_brand } = {} } = useGetShopHomepage();
    const { trackEvent } = useAnalyticsTracking();
    const { darkMode } = useDarkModeContext();

    const handleLinkClick = useCallback(
        (analytics?: Required<Pick<AnalyticsMeta, "action" | "name">>) => {
            if (analytics) {
                trackEvent({ ...analytics, category: "shop us" });
            }
            closeHamburger?.();
        },
        [closeHamburger, trackEvent]
    );

    const featuredBrand = featured_brand?.title;
    return (
        <div className={`shop-nav-add-on ${darkMode && "darkmode"} `}>
            {lg === false && (
                <>
                    <CategoriesMenu />
                    <Link
                        to="/shop/collections/list"
                        className="shop-nav-links"
                        onClick={() => handleLinkClick({ action: "shop nav bar hamburger menu all collections click", name: "All Collections" })}
                    >
                        All Collections
                    </Link>
                    <Link
                        to="/shop/brands/list"
                        className="shop-nav-links"
                        onClick={() => handleLinkClick({ action: "shop nav bar hamburger menu all brands click", name: "All Brands" })}
                    >
                        All Brands
                    </Link>
                </>
            )}
            <Link
                to="/shop/featured-brand"
                className={clsx("shop-nav-links featured-brand-link", featuredBrand && "greyed-out")}
                onClick={() =>
                    handleLinkClick({ action: "shop nav bar hamburger menu featured brand click", name: featuredBrand ?? "Featured Brand" })
                }
            >
                Featured
                {featuredBrand ? (
                    <>
                        : <span className={`featured-brand-name ${darkMode && "darkmode"}`}> {featuredBrand}</span>
                    </>
                ) : (
                    " Brand"
                )}
            </Link>
            <Link href={CONSTANTS.LINKS.SHOP_USA_PARTNER_LINK} className="shop-nav-links">
                Submit a Brand
            </Link>
        </div>
    );
};

const CategoriesMenu = () => {
    const { data: { categories } = {} } = useGetShopCategories({ page: 1, per_page: 20 });
    const { darkMode } = useDarkModeContext();

    const alphabeticalCategories = useMemo(() => {
        if (!categories) {
            return undefined;
        }
        return [...categories].sort((a, b) => a.title.localeCompare(b.title));
    }, [categories]);

    const { trackEvent } = useAnalyticsTracking();
    const handleClick = useCallback(
        (categoryTitle: string) => {
            trackEvent({
                category: "shop us",
                action: "shop nav bar hamburger menu all categories click",
                name: categoryTitle,
            });
        },
        [trackEvent]
    );
    return (
        <Collapse className="categories-menu-mobile">
            <CollapsePanel
                showArrow={false}
                header={
                    <div className="shop-nav-links categories-menu-header">
                        <span>All Categories</span>
                        <ChevronDown className="categories-header-chevron" />
                    </div>
                }
                key="categories"
                className={`categories-menu-panel ${darkMode && "darkmode"}`}
            >
                <div className="categories-menu-links">
                    {alphabeticalCategories?.map(category => (
                        <Link
                            to={`/shop/categories/${category.id}/${category.slug}`}
                            key={category.id}
                            className={`categories-menu-link ${darkMode && "darkmode"}`}
                            onClick={() => handleClick(category.title)}
                        >
                            <span>{category.title}</span>
                        </Link>
                    ))}
                    {!alphabeticalCategories &&
                        Array.from({ length: 8 }).map((_, idx) => (
                            <div className="categories-menu-link" key={idx}>
                                <Skeleton loading paragraph={{ rows: 1 }} active title={false} className="skeleton-line" />
                                <SkeletonAvatar size={24} />
                            </div>
                        ))}
                </div>
            </CollapsePanel>
        </Collapse>
    );
};
