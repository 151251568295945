import { FC }                     from "react";
import clsx                       from "clsx";
import { Link }                   from "react-router-dom";
import { DISPLAY_OUTAGE_BANNER }  from "@/config";
import { useAuth }                from "@/hooks/useAuth";
import { ReactComponent as Star } from "@/static/icons/premium-star.svg";
import "./UserProfileAvatar.less";

interface UserProfileAvatarProps {
    className?: string;
    hasPremium?: boolean;
}

const UserProfileAvatar: FC<React.PropsWithChildren<UserProfileAvatarProps>> = ({ className }) => {
    const { hasPremiumWithCurrentAccount, user, isLoading, isAuthenticated } = useAuth();

    if (DISPLAY_OUTAGE_BANNER) {
        return null;
    }

    if (!isAuthenticated || isLoading) {
        return null;
    }

    const firstName = user?.profile.given_name;
    const lastName = user?.profile.family_name;
    const initials = `${firstName?.[0]}${lastName?.[0]}`.toUpperCase();

    return (
        <div className={clsx("UserProfileAvatar", className && className)}>
            <div className={`avatar-container${hasPremiumWithCurrentAccount ? "-star" : ""}`}>
                <Link className="avatar-initials" to="/account/profile" data-testid="header-avatar-initials">
                    {hasPremiumWithCurrentAccount ? <Star className="star" /> : ""}
                    {initials}
                </Link>
            </div>
        </div>
    );
};

export { UserProfileAvatar };
