import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { DISPLAY_OUTAGE_BANNER }                                       from "@/config";
import { StorageService }                                              from "@/helpers/storage";

const GeneralContext = createContext({
    shouldTriggerPopup: false,
    toggleTriggerPopup: (_state: boolean) => {},
    correctedQueryTerm: "",
    setCorrectedQueryTerm: (q: string) => {},
    showOutage: false,
    dismissOutage: () => {},
});

type GeneralProviderProps = {
    children: ReactNode;
};

export const GeneralProvider = ({ children }: GeneralProviderProps) => {
    const [shouldTriggerPopup, setShouldTriggerPopup] = useState(false);
    const [correctedQueryTerm, setCorrectedQueryTerm] = useState<string>("");
    const [showOutage, setShowOutage] = useState(() => DISPLAY_OUTAGE_BANNER && !StorageService.HasDismissedOutage.get());

    const toggleTriggerPopup = useCallback((state: boolean) => {
        setShouldTriggerPopup(state);
    }, []);

    const dismissOutage = useCallback(() => {
        setShowOutage(false);
        StorageService.HasDismissedOutage.set();
    }, []);

    return (
        <GeneralContext.Provider
            value={{ shouldTriggerPopup, toggleTriggerPopup, correctedQueryTerm, setCorrectedQueryTerm, showOutage, dismissOutage }}
        >
            {children}
        </GeneralContext.Provider>
    );
};

export const useGeneralContext = () => {
    return useContext(GeneralContext);
};
