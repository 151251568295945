import { FC, useCallback } from "react";
import { useMatomo }       from "@datapunt/matomo-tracker-react";
import clsx                from "clsx";
import { useAppBar }       from "@/hooks/useAppBar";
import "./Hamburger.less";

interface HamburgerProps {
    analyticsMeta: {
        category: string;
        name: string;
        action: string;
    };
    className?: string;
}

const Hamburger: FC<React.PropsWithChildren<HamburgerProps>> = ({ analyticsMeta, className }) => {
    const { closeHamburger, openHamburger, showHamburger, showAppBar } = useAppBar();
    const { trackEvent } = useMatomo();

    const onClick = useCallback(() => {
        if (showHamburger) closeHamburger?.();
        else openHamburger?.();

        trackEvent(analyticsMeta);
    }, [analyticsMeta, closeHamburger, openHamburger, showHamburger, trackEvent]);

    return (
        <div className={clsx("Hamburger", className)} onClick={onClick}>
            <div className={clsx("hamburger-inner", showHamburger && "open")}>
                <span className={clsx(!showAppBar && "dark")} />
                <span className={clsx(!showAppBar && "dark")} />
                <span className={clsx(!showAppBar && "dark")} />
                <span className={clsx(!showAppBar && "dark")} />
            </div>
        </div>
    );
};

export default Hamburger;
