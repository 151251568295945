import React, { useEffect, useMemo, useRef } from "react";
import { useLocation }                       from "react-router-dom";
import { DISPLAY_OUTAGE_BANNER }             from "@/config";
import Link                                  from "@/designSystem/Link/Link";
import { useAuth }                           from "@/hooks/useAuth";
import FreespokeLoader                       from "@/molecules/FreespokeLoader/FreespokeLoader";
import Delayed                               from "@/utils/Delayed";
import Login                                 from "./Login";
import { isSessionNotActiveError }           from "./auth.helpers";
import "./ProtectedRoute.less";

type Props = {
    children: React.ReactNode;
    redirectPath?: string;
};

export function ProtectedRoute({ children, redirectPath }: Props) {
    const auth = useAuth();
    const location = useLocation();

    // Get the redirect path from the URL query parameters on 1st render
    const redirectUrl = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get("redirect_url") || "";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasRefreshed = useRef(false);
    useEffect(() => {
        if (auth.error && !hasRefreshed.current) {
            hasRefreshed.current = true;
            auth.signinRedirect({ redirect_uri: redirectUrl || `${window.location.origin}/${redirectPath}` }).catch(e => console.error(e));
        }
    }, [auth, redirectPath, redirectUrl]);

    if (DISPLAY_OUTAGE_BANNER) {
        return null;
    }

    if (auth.isLoading) {
        return <FreespokeLoader />;
    }

    if (auth.error && !hasRefreshed.current) {
        return null;
    }

    if (auth.error) {
        if (isSessionNotActiveError(auth?.error)) {
            auth.signinRedirect().catch(e => console.error(e));
            return null;
        }

        const errorMessage = auth.error.message;

        return (
            <Delayed>
                <div className="ProtectedRoute">
                    <div className="error-block d-flex mt-2 w-100 h-100 text-center justify-content-center align-items-center">
                        <div className="wrapper">
                            <h1 className="error-title">Error</h1>
                            <p className="error-message text">{errorMessage || "An unexpected error has occured"}</p>
                            <p className="paragraph text">
                                Sometimes a quick refresh can fix the issue! Please try refreshing this page to see if that clears things up. If
                                you’re still facing trouble afterwards, reach out to us at help@freespoke.com
                            </p>
                            <Link to="/" className="paragraph back-to-freespoke-link">
                                Back to Freespoke
                            </Link>
                        </div>
                    </div>
                </div>
            </Delayed>
        );
    }

    if (auth.isAuthenticated) {
        return <>{children}</>;
    }

    return <Login redirectPath={redirectUrl || `${window.location.origin}/${redirectPath}`} />;
}
