import { memo, useEffect, useState } from "react";
import { useMatomo }                 from "@datapunt/matomo-tracker-react";
import { PublirAdsProvider }         from "@publir/publir-react-plugin";

/* polyfill for intersection-observer */
import "intersection-observer";
// eslint-disable-next-line no-restricted-imports
import { AuthProvider, AuthProviderProps, useAuth } from "react-oidc-context";
import { Route, BrowserRouter as Router, Switch }   from "react-router-dom";
import smoothscroll                                 from "smoothscroll-polyfill";
import { BackendAccessProvider }                    from "@/api/useBackendAccess";
import { PUBLIR_PUBLISHER_ID }                      from "@/config";
import { DarkModeProvider }                         from "@/context/useDarkModeContext";
import { GeneralProvider }                          from "@/context/useGeneralContext";
import Layout                                       from "@/designSystem/Layout/Layout";
import { sendMessageToAndroid, sendMessageToiOS }   from "@/feature/auth/auth.helpers";
import { oidcConfig }                               from "@/feature/auth/oidcConfig";
import { CONSTANTS }                                from "@/helpers/constants";
import { StorageService }                           from "@/helpers/storage";
import Root                                         from "@/pages/Root/Root";
import "./App.less";

const authConfig: AuthProviderProps = {
    ...oidcConfig,
    onSigninCallback: user => {
        if (user) {
            const refresh_token = user.refresh_token || "";
            const access_token = user.access_token || "";
            const id_token = user.id_token || "";
            // Send a message to the iOS app to notify that the user has logged in.
            sendMessageToiOS({ event_name: "AuthEventLogin", data: { id_token, access_token, refresh_token } });

            // Send a message to the Android app to notify that the user has logged in.
            sendMessageToAndroid({ event_name: "AuthEventLogin", data: { id_token, access_token, refresh_token } });
        }
    },
    onRemoveUser: () => {
        window.location.pathname = "";
    },
    automaticSilentRenew: true,
    monitorSession: false,
};

/*
 * fixes "Failed to execute 'scrollTo' on 'Window': No function was found that matched the signature provided."
 * on older devices
 */
smoothscroll.polyfill(); // kick off the polyfill!

declare var fbq: (arg0: string, arg1: string) => void;

function App() {
    const { enableLinkTracking } = useMatomo();
    const { trackEvent } = useMatomo();

    // Handle Post login
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const state = params.get("state");
        const code = params.get("code");
        if (state && code) {
            // This is a workaround for the issue where the user is redirected to the profile page after registering
            // This is a temporary solution until we have a proper solution for the issue
            if (StorageService.HasRecentlyRegisteredAccount.get()) {
                StorageService.HasRecentlyRegisteredAccount.delete();
                window.location.replace(CONSTANTS.LINKS.PREMIUM.PREMIUM);
            } else {
                // Remove the query params from the URL when the user is redirected back to the app after login
                window.history.replaceState({}, document.title, window.location.pathname);
            }
        }
    }, []);

    useEffect(() => {
        // Matomo Analytics
        // The session storage is used to help prevent "Landed on" duplicate events anytime user refreshes the page
        // Note: the session storage item lives until the tab is closed
        const isSessionInitialized = sessionStorage.getItem("_ff_session_init") === "true";

        if (!isSessionInitialized) {
            sessionStorage.setItem("_ff_session_init", "true");
            trackEvent({
                category: "Landed on",
                action: `${window.location.hostname}${window.location.pathname}`,
            });
        }

        // FB Pixel
        // eslint-disable-next-line no-undef
        if (typeof fbq !== "undefined") {
            // check if the user has previously granted consent to be tracked by the Zuckerbeast
            if (localStorage.getItem("pixel_consent") === "true") {
                // eslint-disable-next-line no-undef
                fbq("consent", "grant");
                // eslint-disable-next-line no-undef
                fbq("track", "PageView");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // For properly tracking outlinks - this makes Matomo rescan DOM everytime this component rerenders
    enableLinkTracking();

    return (
        <AuthProvider {...authConfig}>
            <AutoSignIn />
            <Layout>
                <div className="App">
                    <DarkModeProvider>
                        <BackendAccessProvider>
                            <PublirAdsProvider publisherId={PUBLIR_PUBLISHER_ID!}>
                                <GeneralProvider>
                                    <Router>
                                        <Switch>
                                            <Route path="/" component={Root} />
                                        </Switch>
                                    </Router>
                                </GeneralProvider>
                            </PublirAdsProvider>
                        </BackendAccessProvider>
                    </DarkModeProvider>
                </div>
            </Layout>
        </AuthProvider>
    );
}

export default App;

// attempt to restore session and automatically sign-in
// takes the form of a component in order to be wrapped in the AuthProvider
const AutoSignIn = memo(() => {
    const auth = useAuth();
    const [hasTriedSignIn, setHasTriedSignIn] = useState(false);
    useEffect(() => {
        if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignIn) {
            const oidcKey = new URLSearchParams(window.location.search).get("state");
            const storageKey = `oidc.${oidcKey}`;
            const exists = !!window.localStorage.getItem(storageKey);
            if (exists) {
                auth.signinRedirect().catch(e => console.error(e));
            } else {
                auth.signinSilent().catch(e => console.error(e));
            }

            setHasTriedSignIn(true);
        }
    }, [auth, hasTriedSignIn]);

    return null;
});
