import axios                from "axios";
import useSWRImmutable      from "swr/immutable";
import { IQuickLink }       from "@/@types/custom";
import { useBackendAccess } from "@/api/useBackendAccess";
import { BACKEND_URL }      from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export interface IUseGetSuggestionsResponse {
    topics?: {
        id: string | undefined;
        name: string | undefined;
        image_url: string | undefined;
    }[];
    popular_search_terms?: {
        value: string | undefined;
    }[];
    quick_links?: IQuickLink[];
}

export interface IUseGetSuggestionsParams {
    topicsLimit: number;
    searchTermsLimit: number;
    quickLinksLimit?: number;
}

const MAX_SUGGESTED_TOPICS = 10;
const MAX_POPULAR_SEARCH_TERMS = 5;
const MAX_QUICK_LINKS = 3;

const params: IUseGetSuggestionsParams = {
    topicsLimit: MAX_SUGGESTED_TOPICS,
    searchTermsLimit: MAX_POPULAR_SEARCH_TERMS,
    quickLinksLimit: MAX_QUICK_LINKS,
};

const useGetSuggestions = () => {
    const { guestAccess } = useBackendAccess();
    const url = `${BACKEND_URL}/suggestions/list`;
    const fetcher = () => axios.get(url, { headers: guestAccess?.authHeaders, params }).then(res => res.data);

    const { data, error, isValidating, isLoading, mutate } = useSWRImmutable<IUseGetSuggestionsResponse>(
        guestAccess?.authHeaders ? url : null,
        fetcher,
        config
    );

    return {
        data,
        isFetching: !guestAccess?.authHeaders || isValidating,
        isLoading,
        error,
        refresh: mutate,
    };
};

export default useGetSuggestions;
