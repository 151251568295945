import clsx from "clsx";
import "./Switch.less";

type SwitchLiteProps = {
    checked?: boolean;
    size?: "small" | "default";
    className?: string;
};

/**
 * This is a lite version of the Switch component from Ant Design, meant for display uses only
 */
const SwitchLite = ({ checked, size, className }: SwitchLiteProps) => {
    return (
        <div className={clsx("ant-switch ff-switch", className, checked && "ant-switch-checked", size === "small" && "ant-switch-small")}>
            <div className="ant-switch-handle" />
        </div>
    );
};

export default SwitchLite;
