import { useEffect, useState } from "react";
import { isChrome, isFirefox } from "react-device-detect";
import { StorageService }      from "@/helpers/storage";

declare var chrome: any;

const MAX_WAIT_TIME = 1000; // how long should we wait for the extension detection async tasks.

// NOTE: the checks for extension detection are async.
// You have 2 options to detect if the async check has been completed or still in process:
// 1. If isFirefoxExtensionInstalled or isChromeExtensionInstalled equal to 'null' then it's still being detected.
// 2. Use 'extensionCheckCompleted' value returned by this hook.

interface useExtensionDetectionReturn {
    isFirefoxExtensionInstalled: boolean | null; // null means that the check is still in progress
    isChromeExtensionInstalled: boolean | null; // null means that the check is still in progress
    extensionCheckCompleted: boolean; // use these value to know if the async checks have been completed
    isFreespokeExtensionEnabled: boolean; // this value is cached in the local storage, this could potentially be out of sync with the extension status if the user uninstalls the extension, at least until the async detection is completed.
}
export const useExtensionDetection = (): useExtensionDetectionReturn => {
    const [isFirefoxExtensionInstalled, setIsFirefoxExtensionInstalled] = useState<boolean | null>(isFirefox ? null : false);
    const [isChromeExtensionInstalled, setIsChromeExtensionInstalled] = useState<boolean | null>(isChrome ? null : false);
    const [extensionCheckCompleted, setExtensionCheckCompleted] = useState<boolean>(
        typeof isFirefoxExtensionInstalled === "boolean" && typeof isChromeExtensionInstalled === "boolean" ? true : false
    );

    // We store the extension status in the local storage so that we can use it in the app without waiting for the async checks.
    // For example, when making search requests we need to know if the extension is installed or not.
    useEffect(() => {
        if (isFirefoxExtensionInstalled === true || isChromeExtensionInstalled === true) {
            StorageService.HasFreespokeExtensionEnabled.set(true);
        } else if (isFirefoxExtensionInstalled === false && isChromeExtensionInstalled === false) {
            StorageService.HasFreespokeExtensionEnabled.set(false);
        }
    }, [isChromeExtensionInstalled, isFirefoxExtensionInstalled]);

    useEffect(() => {
        if (!extensionCheckCompleted && typeof isFirefoxExtensionInstalled === "boolean" && typeof isChromeExtensionInstalled === "boolean") {
            setExtensionCheckCompleted(true);
        }
    }, [isChromeExtensionInstalled, isFirefoxExtensionInstalled, extensionCheckCompleted]);

    useEffect(() => {
        if (isFirefox) {
            setTimeout(() => {
                const extensionDiv = document.getElementById("freespoke_firefox_extension_beacon");
                if (extensionDiv) {
                    setIsFirefoxExtensionInstalled(true);
                } else {
                    setIsFirefoxExtensionInstalled(false);
                }
            }, MAX_WAIT_TIME);
        } else if (isChrome && typeof chrome !== "undefined") {
            const chromeRuntimeCb = function (reply?: boolean) {
                if (reply === true) {
                    setIsChromeExtensionInstalled(true);
                } else {
                    setIsChromeExtensionInstalled(false);
                }
            };

            // If the extension doesn't respond withing 1 second then we assume user doesn't have it
            setTimeout(() => {
                setIsChromeExtensionInstalled(currentState => (typeof currentState !== "boolean" ? false : currentState));
            }, MAX_WAIT_TIME);

            // extensionIdDev is used for debugging only
            const extensionIdDev = localStorage.getItem("--app:extension_id_dev");
            if (extensionIdDev) {
                chrome?.runtime?.sendMessage(extensionIdDev, { message: "installed" }, chromeRuntimeCb);
            } else {
                const extensionIdProd = "oacbeelegehoakfnoaecnlifbhcohljl";
                chrome?.runtime?.sendMessage(extensionIdProd, { message: "installed" }, chromeRuntimeCb);
            }
        } else {
            setIsChromeExtensionInstalled(false);
            setIsFirefoxExtensionInstalled(false);
        }
    }, [extensionCheckCompleted]);

    return {
        isFirefoxExtensionInstalled,
        isChromeExtensionInstalled,
        extensionCheckCompleted,
        isFreespokeExtensionEnabled: StorageService.HasFreespokeExtensionEnabled.get(),
    };
};
