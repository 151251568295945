export enum UserAttribute {
    FREESPOKE_EXTENSION = "freespoke_extension",
    FREESPOKE_APP = "freespoke_app",
}

interface GetUserAttributesOptions {
    isFreespokeExtensionEnabled: boolean;
    isFreespokeApp: boolean;
}

export function getUserAttributes({ isFreespokeExtensionEnabled, isFreespokeApp }: GetUserAttributesOptions): UserAttribute[] | undefined {
    const userAttributes: UserAttribute[] = [];

    if (isFreespokeExtensionEnabled) {
        userAttributes.push(UserAttribute.FREESPOKE_EXTENSION);
    }
    if (isFreespokeApp) {
        userAttributes.push(UserAttribute.FREESPOKE_APP);
    }

    return userAttributes?.length ? userAttributes : undefined;
}
