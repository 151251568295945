import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState }            from "react";
import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, AxiosResponseHeaders } from "axios";
import useSWR                                                                                  from "swr";
import { GUEST_TOKEN_URL }                                                                     from "@/config";
import { StorageService }                                                                      from "@/helpers/storage";
import { useAuth }                                                                             from "@/hooks/useAuth";

export interface AuthHeaders extends AxiosRequestHeaders {
    Authorization: string;
    "x-token-hint": "guest" | "user";
}

type AccessItem = {
    token: string | undefined;
    error?: any;
    authHeaders: AuthHeaders | undefined;
};

type RequestWithAccessOptions = {
    url: string;
    headers?: AxiosRequestHeaders;
} & (
    | {
          method: "get" | "delete";
      }
    | {
          method: "post" | "patch";
          body: Record<string | number | symbol, unknown> | undefined;
      }
) &
    (
        | {
              access: "guest";
          }
        | {
              access: "user";
              fallbackToGuest: boolean;
          }
    );
type BackendAccess = {
    guestAccess: AccessItem | undefined;
    requestWithAccess: <T>({ access, url, headers, ...rest }: RequestWithAccessOptions) => Promise<
        | {
              data: T;
              headers: AxiosResponseHeaders;
          }
        | undefined
    >;
    isLoading: boolean;
};

const xSessionIdHeader = { "x-session-id": StorageService.SessionToken.get() };

const fetcher: (args: [string]) => any = async ([url]) => {
    const res = await axios.get(url, {
        headers: xSessionIdHeader,
    });
    return res.data;
};

const BackendAccessContext = createContext<BackendAccess>({
    guestAccess: undefined,
    requestWithAccess: async () => undefined,
    isLoading: false,
});

// internal is just an alias, and therefore uses the same env as prod, so we have to reference the origin here
const internalHostNames = ["internal.freespoke.com", "localhost"];

export const BackendAccessProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { user, error: userError, isAuthenticated, signinSilent, isLoading: isLoadingUser } = useAuth();

    const { data: guestData, error: guestError } = useSWR<{ token: string }>([`${GUEST_TOKEN_URL}/.netlify/functions/authorization`], fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    const guestAccess = useMemo<AccessItem | undefined>(() => {
        return guestData || guestError
            ? {
                  token: guestData?.token,
                  error: guestError,
                  authHeaders: guestData?.token
                      ? {
                            Authorization: `Bearer ${guestData.token}`,
                            "x-token-hint": "guest",
                        }
                      : undefined,
              }
            : undefined;
    }, [guestData, guestError]);

    const getUserAccess = useCallback(async (): Promise<AccessItem | undefined> => {
        const shouldReturnAccess = (isAuthenticated && !!user?.access_token) || !!userError;

        // TODO: set token from the mobile app
        // if (browsingViaMobileAppWthPremium) {
        // }

        if (!shouldReturnAccess) {
            return undefined;
        }

        const tokenUser =
            isAuthenticated && (user?.expired || (user?.expires_in ?? 0) < 30) ? await signinSilent({ silentRequestTimeoutInSeconds: 0.5 }) : user;

        return {
            token: tokenUser?.access_token,
            error: userError,
            authHeaders:
                isAuthenticated && tokenUser?.access_token
                    ? {
                          Authorization: `Bearer ${tokenUser.access_token}`,
                          "x-token-hint": "user",
                      }
                    : undefined,
        };
    }, [isAuthenticated, signinSilent, user, userError]);

    const requestWithAccess = useCallback(
        async <T,>({ url, headers, ...rest }: RequestWithAccessOptions) => {
            const body = rest.method === "post" || rest.method === "patch" ? rest.body : undefined;
            const request: AxiosRequestConfig = { url, method: rest.method, data: body, headers, withCredentials: true };

            const guestAuthHeaders = guestAccess?.authHeaders;
            const userAuthHeaders = (await getUserAccess())?.authHeaders;

            if (!userAuthHeaders && !guestAuthHeaders) {
                return undefined;
            }

            const guestRequest = { ...request, headers: { ...request.headers, ...guestAuthHeaders } };
            if (rest.access === "guest" || !userAuthHeaders) {
                const response = await axios.request(guestRequest);
                return {
                    data: response.data as T,
                    headers: response.headers,
                };
            }

            let res: AxiosResponse;
            try {
                res = await axios.request({ ...request, headers: { ...headers, ...userAuthHeaders } });
            } catch (e) {
                // Don't retry if the host is internal, so that potential issues will become
                // more apparent to the team, or if fallback was explicitly disabled
                if (internalHostNames.includes(window.location.hostname) || rest.fallbackToGuest === false) {
                    throw e;
                }
                res = await axios.request(guestRequest);
            }
            return {
                data: res.data as T,
                headers: res.headers,
            };
        },
        [getUserAccess, guestAccess]
    );

    const { hasTimedOut } = useTimeoutCheck(500);
    const isLoading = !guestAccess || (isLoadingUser && !hasTimedOut);

    const value = useMemo(
        () => ({
            guestAccess,
            requestWithAccess,
            isLoading,
        }),
        [guestAccess, isLoading, requestWithAccess]
    );

    return <BackendAccessContext.Provider value={value}>{children}</BackendAccessContext.Provider>;
};

export const useBackendAccess = () => {
    return useContext(BackendAccessContext);
};

const useTimeoutCheck = (timeoutMs: number) => {
    const [hasTimedOut, setHasTimedOut] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setHasTimedOut(true);
        }, timeoutMs);

        return () => {
            clearTimeout(timeout);
        };
    }, [timeoutMs]);

    return { hasTimedOut };
};
