import { FC, createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import useBreakpoint                                                               from "antd/lib/grid/hooks/useBreakpoint";
import { useInView }                                                               from "react-intersection-observer";

interface ISearchTabsVisibilityContext {
    tabsAreVisible: boolean | undefined;
    tabRef: ReturnType<typeof useInView>["ref"];
}

const SearchTabsVisibilityContext = createContext<ISearchTabsVisibilityContext>({
    tabsAreVisible: false,
    tabRef: () => {},
});

export const SearchTabsVisibilityContextProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { lg } = useBreakpoint();
    const [tabsAreVisible, setTabsAreVisible] = useState<boolean | undefined>();
    const ref = useRef<Element | null>(null);
    const { ref: inViewRef, inView } = useInView({
        triggerOnce: false,
        initialInView: true,
        rootMargin: `${lg ? "-76px" : "-60px"} 0px 0px 0px`,
    });

    const tabRef = useCallback(
        (node?: Element | null | undefined) => {
            if (typeof node !== "undefined") {
                ref.current = node;
                inViewRef(node);
            }
        },
        [inViewRef]
    );

    useEffect(() => {
        if (ref.current) {
            setTabsAreVisible(inView);
        }
    }, [inView]);

    useEffect(
        () => () => {
            setTabsAreVisible(undefined);
            ref.current = null;
        },
        []
    );

    return <SearchTabsVisibilityContext.Provider value={{ tabsAreVisible, tabRef }}>{children}</SearchTabsVisibilityContext.Provider>;
};

export const useSearchTabsVisibilityContext = () => {
    return useContext(SearchTabsVisibilityContext);
};
