import React, { FC, useCallback }            from "react";
import { useMatomo }                         from "@datapunt/matomo-tracker-react";
import clsx                                  from "clsx";
import { useLocation }                       from "react-router-dom";
import Link                                  from "@/designSystem/Link/Link";
import Text                                  from "@/designSystem/Text/Text";
import { CONSTANTS }                         from "@/helpers/constants";
import { NoticeOfCollection, PrivacyPolicy } from "@/legal/index";
import "./Footer.less";

type FooterProps = {
    forceVisible?: boolean;
    className?: string;
};

const Footer: FC<React.PropsWithChildren<FooterProps>> = ({ forceVisible = false, className }) => {
    const location = useLocation();
    const { trackEvent } = useMatomo();

    const recordEvent = useCallback(
        (type: string) => {
            trackEvent({
                category: "footer",
                name: type,
                action: "footer click",
            });
        },
        [trackEvent]
    );

    const recordClickOnNoticeOfCollection = useCallback(() => recordEvent("notice of collection"), [recordEvent]);
    const recordClickOnFreespokeTerms = useCallback(() => recordEvent("freespoke terms"), [recordEvent]);
    const recordClickOnPrivacyPolicy = useCallback(() => recordEvent("privacy policy"), [recordEvent]);
    const recordClickOnContactUs = useCallback(() => recordEvent("contact us"), [recordEvent]);

    const shouldHideFooter =
        hideFooterEqualityRoutes.includes(location.pathname) || hideFooterStartsWithRoutes.some(route => location.pathname.startsWith(route));

    // hides footer if not on desktop or is on desktop home page
    if (!forceVisible && shouldHideFooter) {
        return null;
    }

    return (
        <div className={clsx("Footer", className)}>
            <div className="container">
                <Text className="copy">© {new Date().getFullYear()} Freespoke</Text>
                <div className="links">
                    <Link to="/terms-of-service" onClick={recordClickOnFreespokeTerms}>
                        Freespoke's Terms
                    </Link>
                    <PrivacyPolicy onClick={recordClickOnPrivacyPolicy} />
                    <NoticeOfCollection onClick={recordClickOnNoticeOfCollection} />
                    <Link href="https://freespoke-support.freshdesk.com/support/tickets/new" onClick={recordClickOnContactUs}>
                        Contact Us
                    </Link>
                </div>
                <Text className="love">Made with ❤️ {"\u00A0"}in the U.S.A.</Text>
            </div>
        </div>
    );
};

export default React.memo(Footer);

const hideFooterEqualityRoutes = ["/", "/search/maps"];
const hideFooterStartsWithRoutes = ["/news", "/login", "/logout", "/register", CONSTANTS.LINKS.PREMIUM.SUBSCRIBE];
