/* eslint-disable no-multi-spaces */
// Note: react-scripts require env variables to be prefixed with REACT_APP
// prettier-config
export const GUEST_TOKEN_URL = process.env.REACT_APP_GUEST_TOKEN_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const ENABLE_ANALYTICS = process.env.REACT_APP_ENABLE_ANALYTICS === "true";
export const MATOMO_SITE_ID = process.env.REACT_APP_MATOMO_SITE_ID ? parseInt(process.env.REACT_APP_MATOMO_SITE_ID) : undefined;
export const MATOMO_TRACKER_URL = process.env.REACT_APP_MATOMO_TRACKER_URL;
export const MATOMO_SRC_URL = process.env.REACT_APP_MATOMO_SRC_URL;
export const ENABLE_DATADOG = process.env.REACT_APP_ENABLE_DATADOG === "true";
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV || "not set";
export const TOMTOM_MAPS_API_KEY = process.env.REACT_APP_TOMTOM_MAPS_API_KEY;
export const PUBLIR_PUBLISHER_ID = process.env.REACT_APP_PUBLIR_PUBLISHER_ID;
export const DISABLE_FEATURES_LIST = process.env.REACT_APP_DISABLE_FEATURES_LIST?.split(",");
export const DISABLE_FEATURES_ALL_TAB = process.env.REACT_APP_DISABLE_FEATURES_ALL_TAB?.split(",");
export const ENABLE_WEATHER_WIDGET = process.env.REACT_APP_ENABLE_WEATHER_WIDGET === "true";

// Used for A/B testing.
// If it deployed on "staging_b" or "master_b" it will use "b" version, else "a" version
// You can also overwrite it via "REACT_APP_AB_TEST_VERSION" env, useful when developing
const REACT_APP_BRANCH_NAME = process.env.REACT_APP_BRANCH_NAME; // REACT_APP_BRANCH_NAME env is injected by Netlify during build time
export const A_B_VERSION =
    process.env.REACT_APP_AB_TEST_VERSION || (REACT_APP_BRANCH_NAME === "staging_b" || REACT_APP_BRANCH_NAME === "master_b" ? "b" : "a");

export const SEARCH_EXPANDER_ID = process.env.REACT_APP_SEARCH_EXPANDER_ID;
export const SEARCH_EXPANDER_PROXY_URL = process.env.REACT_APP_SEARCH_EXPANDER_PROXY_URL;
export const SEARCH_EXPANDER_TIMEOUT_MS =
    process.env.REACT_APP_SEARCH_EXPANDER_TIMEOUT_MS &&
    !isNaN(parseFloat(process.env.REACT_APP_SEARCH_EXPANDER_TIMEOUT_MS)) &&
    parseFloat(process.env.REACT_APP_SEARCH_EXPANDER_TIMEOUT_MS) >= 0
        ? parseFloat(process.env.REACT_APP_SEARCH_EXPANDER_TIMEOUT_MS)
        : null;

// This should be the same value as the search expander proxy url, but we may have to disable it if third parties begin blocking our cloudflare worker
export const SEARCH_EXPANDER_THIRD_PARTY_PROXY_URL = process.env.REACT_APP_SEARCH_EXPANDER_THIRD_PARTY_PROXY_URL;

export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
export const REACT_APP_COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH;

export const OPENID_ISSUER = process.env.REACT_APP_OPENID_ISSUER;
export const OPENID_CLIENT_ID = process.env.REACT_APP_OPENID_CLIENT_ID;

export const HIDE_AUTH_BUTTONS = process.env.REACT_APP_HIDE_AUTH_BUTTONS === "true";
export const HIDE_PREMIUM_BUTTONS = process.env.REACT_APP_HIDE_PREMIUM_BUTTONS === "true";

export const REACT_APP_RECURLY_PUBLIC_KEY = process.env.REACT_APP_RECURLY_PUBLIC_KEY;

export const DISABLE_ADVANCED_FILTERS = process.env.REACT_APP_DISABLE_ADVANCED_FILTERS === "true";
export const ENABLE_ELECTION_BANNER = process.env.REACT_APP_ENABLE_ELECTION_BANNER === "true";

export const DISABLE_APPLE_PAY = process.env.REACT_APP_DISABLE_APPLE_PAY === "true";
export const DISABLE_HOMEPAGE = process.env.REACT_APP_DISABLE_HOMEPAGE === "true";
export const DISABLE_BREAKING_NEWS = process.env.REACT_APP_DISABLE_BREAKING_NEWS === "true";

export const DISPLAY_OUTAGE_BANNER = process.env.REACT_APP_DISPLAY_OUTAGE_BANNER === "true";
