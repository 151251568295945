import { FC }                          from "react";
import clsx                            from "clsx";
import { isFirefox }                   from "react-device-detect";
import Link                            from "@/designSystem/Link/Link";
import { capitalizeFirstLetter }       from "@/helpers/index";
import { useGetExtensionLinkData }     from "@/hooks/extension/useGetExtensionLinkData";
import { ReactComponent as TorchIcon } from "@/static/icons/freespokeTorchWhite.svg";
import "./ExtensionDownloadLink.less";

interface ExtensionDownloadLinkProps {
    className?: string;
    text?: string;
    onClickAddExtension?: (browser: "chrome" | "firefox") => void;
    hasIcon?: boolean;
}

export const ExtensionDownloadLink: FC<React.PropsWithChildren<ExtensionDownloadLinkProps>> = ({ className, text, onClickAddExtension, hasIcon }) => {
    const { type, howToInstallModalUrl } = useGetExtensionLinkData();
    return (
        <Link
            className={clsx("ExtensionDownloadLink", className && className)}
            to={howToInstallModalUrl}
            onClick={() => onClickAddExtension?.(isFirefox ? "firefox" : "chrome")}
        >
            {hasIcon && <TorchIcon className="icon" />}
            {text || `Add Freespoke to ${capitalizeFirstLetter(type)}`}
        </Link>
    );
};
