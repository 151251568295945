import { useCallback, useEffect, useRef } from "react";
import { useLocation }                    from "react-router-dom";
import { getUserAttributes }              from "@/api/api.helpers";
import { useBackendAccess }               from "@/api/useBackendAccess";
import { BACKEND_URL }                    from "@/config";
import { extractAPIError }                from "@/helpers/index";
import { useExtensionDetection }          from "@/hooks/useExtensionDetection";
import { useMobileAppDetection }          from "@/hooks/useMobileAppDetection";

const API_URL = `${BACKEND_URL}/v2/tel/pv`;

export const usePageViewTracking = () => {
    const prevLocation = useRef<Partial<Pick<Location, "pathname" | "search">>>({ pathname: undefined, search: undefined });
    const location = useLocation();
    const { isFreespokeExtensionEnabled, extensionCheckCompleted } = useExtensionDetection();
    const { requestWithAccess, isLoading } = useBackendAccess();
    const { isMobileApp: isFreespokeApp } = useMobileAppDetection();
    const isPlaywrightTestRunner = navigator.userAgent.toLowerCase().includes("playwright");

    const trackPageView = useCallback(
        async (url: string) => {
            // Prevent multiple requests
            const isSameLocation = prevLocation.current.pathname === location.pathname && prevLocation.current.search === location.search;
            if (isSameLocation || isLoading || !extensionCheckCompleted || isPlaywrightTestRunner) return;

            try {
                await requestWithAccess<void>({
                    access: "user",
                    method: "post",
                    url: API_URL,
                    body: { url, attributes: getUserAttributes({ isFreespokeExtensionEnabled, isFreespokeApp }) },
                    fallbackToGuest: true,
                });
            } catch (e) {
                throw new Error(extractAPIError(e));
            } finally {
                prevLocation.current = { pathname: location.pathname, search: location.search };
            }
        },
        [
            extensionCheckCompleted,
            isFreespokeApp,
            isFreespokeExtensionEnabled,
            isLoading,
            isPlaywrightTestRunner,
            location.pathname,
            location.search,
            requestWithAccess,
        ]
    );

    useEffect(() => {
        trackPageView(window.location.href);
    }, [location.pathname, location.search, trackPageView]);

    return { trackPageView };
};
