import { useEffect, useState } from "react";

type TStatus = "loading" | "idle" | "ready" | "error";

// This version of useScript allows attaching script to a specific HTML element via ref
export default function useAttachScript(src: string, ref: HTMLDivElement | HTMLElement | undefined | null): TStatus | null {
    // Keep track of script status ("idle", "loading", "ready", "error")
    const [status, setStatus] = useState<TStatus | null>(src ? "loading" : "idle");

    useEffect(
        () => {
            // Allow falsy src value if waiting on other data needed for
            // constructing the script URL passed to this hook.
            if (!src || !ref) {
                setStatus("idle");
                return;
            }

            // Fetch existing script element by src
            // It may have been added by another instance of this hook
            let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);

            if (!script && ref) {
                // Create script
                script = document.createElement("script");
                script.src = src;
                script.async = true;
                script.setAttribute("data-status", "loading");
                // Add script to document body
                ref.appendChild(script);

                // Store status in attribute on script
                // This can be read by other instances of this hook
                const setAttributeFromEvent = (event: { type: string }) => {
                    if (script) {
                        script.setAttribute("data-status", event.type === "load" ? "ready" : "error");
                    }
                };

                script.addEventListener("load", setAttributeFromEvent);
                script.addEventListener("error", setAttributeFromEvent);
            } else {
                // Grab existing script status from attribute and set to state.
                if (script) {
                    setStatus(script.getAttribute("data-status") as TStatus);
                }
            }

            // Script event handler to update status in state
            // Note: Even if the script already exists we still need to add
            // event handlers to update the state for *this* hook instance.
            const setStateFromEvent = (event: { type: string }) => {
                setStatus(event.type === "load" ? "ready" : "error");
            };

            // Add event listeners
            if (script) {
                script.addEventListener("load", setStateFromEvent);
                script.addEventListener("error", setStateFromEvent);
            }

            // Remove event listeners on cleanup
            return () => {
                if (script) {
                    script.removeEventListener("load", setStateFromEvent);
                    script.removeEventListener("error", setStateFromEvent);
                }
            };
        },
        [ref, src] // Only re-run effect if script src changes
    );

    return status;
}
