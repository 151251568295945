import { useMemo }           from "react";
import { isEdge, isFirefox } from "react-device-detect";
import { CONSTANTS }         from "@/helpers/constants";

export const useGetExtensionLinkData = () => {
    const data = useMemo(() => {
        const browserType = getExtensionBrowserType();
        const storeURL = getExtensionStoreLink(browserType);
        const howToInstallModalUrl = getHowToInstallExtensionModalLink(browserType);
        return { storeURL, type: browserType, howToInstallModalUrl };
    }, []);

    return data;
};

// @ts-ignore
const isBraveBrowser = navigator?.brave?.isBrave();

function getExtensionStoreLink(type: "firefox" | "chrome" | "brave" | "edge"): string {
    if (type === "firefox") {
        return CONSTANTS.LINKS.FIREFOX_EXTENSION;
    }
    return CONSTANTS.LINKS.CHROME_EXTENSION;
}

export function getHowToInstallExtensionModalLink(type: "firefox" | "chrome" | "brave" | "edge"): string {
    if (type === "firefox") {
        return CONSTANTS.LINKS.FIREFOX_EXTENSION_HOW_TO_INSTALL_MODAL;
    }
    if (type === "brave") {
        return CONSTANTS.LINKS.BRAVE_EXTENSION_HOW_TO_INSTALL_MODAL;
    }
    if (type === "edge") {
        return CONSTANTS.LINKS.EDGE_EXTENSION_HOW_TO_INSTALL_MODAL;
    }
    return CONSTANTS.LINKS.CHROME_EXTENSION_HOW_TO_INSTALL_MODAL;
}

export function getExtensionBrowserType(): "firefox" | "chrome" | "brave" | "edge" {
    if (isFirefox) {
        return "firefox";
    }
    if (isBraveBrowser) {
        return "brave";
    }
    if (isEdge) {
        return "edge";
    }
    return "chrome";
}
