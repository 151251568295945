import { FC, useCallback, useMemo, useRef } from "react";
import { Divider }                          from "antd";
import clsx                                 from "clsx";
import { isIOS }                            from "react-device-detect";
import { useHistory, useLocation }          from "react-router-dom";
import { getAnalyticsCategory }             from "@/analytics/analytics";
import Link                                 from "@/designSystem/Link/Link";
import { useGetExtensionLinkData }          from "@/hooks/extension/useGetExtensionLinkData";
import { useAnalyticsTracking }             from "@/hooks/useAnalyticsTracking";
import useAttachScript                      from "@/hooks/useAttachScript";
import AppDownloadBadge                     from "@/molecules/AppDownloadBadge/AppDownloadBadge";
import { WrapperModal }                     from "@/molecules/WrapperModal/WrapperModal";
import { ReactComponent as QRCode }         from "@/static/images/freespokeExtension-qr-code.svg";
import { ReactComponent as UninstallLogo }  from "@/static/logo/uninstallLogo.svg";
import "./UninstallExtensionModal.less";

interface UninstallModalProps {
    className?: string;
}

export const UninstallExtensionModal: FC<UninstallModalProps> = ({ className }) => {
    const { storeURL } = useGetExtensionLinkData();
    const { trackEvent } = useAnalyticsTracking();
    const location = useLocation();
    const history = useHistory();
    const pageName = useMemo(() => getAnalyticsCategory(location.pathname), [location.pathname]);

    const scriptRef = useRef(null);
    useAttachScript("https://tally.so/widgets/embed.js", scriptRef.current);

    const onClose = () => {
        history.push({ ...location, hash: "" });
    };

    const handleReinstallExtensionClick = useCallback(() => {
        trackEvent({
            action: `${pageName} uninstall extension reinstall extension cta click`,
            name: "click",
        });
    }, [pageName, trackEvent]);

    return (
        <WrapperModal isModalVisible onClose={onClose} onlyLightMode>
            <div className={clsx("UninstallExtensionModal", className)} ref={scriptRef}>
                <UninstallLogo className="logo" />
                <h3 className="text">You’ve uninstalled the Freespoke browser extension.</h3>
                <p className="sub-title text">
                    <span>
                        Was this a mistake?{" "}
                        <Link href={storeURL} className="reinstall" onClick={handleReinstallExtensionClick}>
                            Reinstall the extension.
                        </Link>{" "}
                    </span>
                    <br />
                    <span>Please share feedback, so we can improve</span>
                </p>
                <Divider className="divider" />

                <iframe
                    data-tally-src="https://tally.so/embed/n0L6K6?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                    width="100%"
                    height="400px"
                    title="Help Us Improve: Freespoke Extension Uninstall Survey"
                    style={{ border: "none" }}
                />

                <Divider className="divider" />

                <ModalQrCode />
                {/* Add space to the bottom of the modal on iOS */}
                {isIOS && <div style={{ height: 70 }} />}
            </div>
        </WrapperModal>
    );
};

export const ModalQrCode = () => {
    const location = useLocation();
    const { hash } = useLocation();
    const { trackEvent } = useAnalyticsTracking();
    const pageName = useMemo(() => getAnalyticsCategory(location.pathname), [location.pathname]);

    const handleClick = useCallback(() => {
        trackEvent({
            action:
                hash === "#uninstall-extension"
                    ? `${pageName} uninstall extension reinstall app download cta click`
                    : `${pageName} make freespoke search your default pop up app download cta click`,
            name: "click",
        });
    }, [hash, pageName, trackEvent]);

    return (
        <div className="ModalQrCode">
            <div className="qr-code-image-wrapper">
                <QRCode />
            </div>
            <div className="app-download-badge">
                <p className="get-freespoke text">Get the Freespoke app</p>
                <div className="app-badges">
                    <AppDownloadBadge className="badge" type="ios" reportClick={handleClick} />
                    <AppDownloadBadge className="badge" type="android" reportClick={handleClick} />
                </div>
            </div>
        </div>
    );
};
