import { CSSProperties, ChangeEventHandler } from "react";
import { animated }                          from "react-spring";
import CustomClose                           from "@/designSystem/Icons/CustomClose";
import CustomSearch                          from "@/designSystem/Icons/CustomSearch";
import "./CustomSubmit.less";

interface ICustomSubmitProps {
    containerStyle: CSSProperties;
    onClose: (e: ChangeEventHandler<HTMLOrSVGElement>) => void;
    onSubmit: ChangeEventHandler<HTMLFormElement>;
    closeClassName: string;
    searchClassName: string;
}

const CustomSubmit = ({ containerStyle, onClose, onSubmit, closeClassName, searchClassName }: ICustomSubmitProps) => {
    return (
        <animated.div className="CustomSubmit" style={containerStyle}>
            <CustomClose onClick={onClose} className={closeClassName} />
            <CustomSearch onClick={onSubmit} className={searchClassName} />
        </animated.div>
    );
};
export default CustomSubmit;
