import { FC, useCallback, useState }     from "react";
import clsx                              from "clsx";
import { signUpForANewsletter }          from "@/api/newsletterAPI";
import { AuthHeaders, useBackendAccess } from "@/api/useBackendAccess";
import Button                            from "@/designSystem/Button/Button";
import Form                              from "@/designSystem/Form/Form";
import MailOutlined                      from "@/designSystem/Icons/MailOutlined";
import RightArrow                        from "@/designSystem/Icons/RightArrow";
import Input                             from "@/designSystem/Input/Input";
import { isValidEmail }                  from "@/helpers/emailValidator";
import "./SignupForm.less";

interface SignupProps {
    className?: string;
    buttonLabel: string;
    successButtonLabel: string;
    hasSubmitIcon?: boolean;
    onSuccess: () => void;
    signUpFn?: (email: string, authHeaders: AuthHeaders | undefined) => Promise<void>;
}

const SignupForm: FC<React.PropsWithChildren<SignupProps>> = ({
    className,
    buttonLabel,
    hasSubmitIcon,
    successButtonLabel,
    onSuccess,
    signUpFn = signUpForANewsletter,
}) => {
    const { guestAccess } = useBackendAccess();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const onFinish = useCallback(
        (values: any) => {
            // Prevent additional calls while in loading or post-success state
            if (!loading || !success) {
                setLoading(true);

                signUpFn(values.email, guestAccess?.authHeaders)
                    .then(() => {
                        setSuccess(true);
                        onSuccess();
                    })
                    .catch(err => form.setFields([{ name: "email", errors: [err] }]))
                    .finally(() => setLoading(false));
            }
        },
        [form, guestAccess?.authHeaders, loading, onSuccess, signUpFn, success]
    );

    return (
        <Form onFinish={onFinish} form={form} validateTrigger="onSubmit" className={clsx("SignupForm", className && className)}>
            <div className="input-wrapper">
                <Form.Item
                    validateFirst
                    name="email"
                    rules={[
                        { required: true, message: "" },
                        () => ({
                            validator(_, value) {
                                if (!isValidEmail(value)) {
                                    return Promise.reject(new Error("Invalid email address"));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input
                        className="input"
                        size="large"
                        placeholder="Email Address"
                        autoComplete="email"
                        prefix={<MailOutlined className="mail-icon" />}
                        disabled={loading || success}
                    />
                </Form.Item>
                <Button
                    block
                    className="submit-button"
                    type="primary"
                    size="large"
                    onClick={() => form.submit()}
                    loading={loading}
                    disabled={success}
                >
                    {hasSubmitIcon && <RightArrow />}
                    <span>{success ? successButtonLabel : buttonLabel}</span>
                </Button>
            </div>
        </Form>
    );
};

export default SignupForm;
