export const COLORS = {
    white: "#FFF",
    grey1: "#3b4355",
    grey3: "#9aa2b2",
    grey4: "#b5bcc9",
    grey5: "#e1e5eb",
    grey7: "#f8f9fb",
    grey8: "#9AA2B3",
    grey9: "#606671",
    brandBlue: "#2168cc",
    brandNavy: "#1a1a1a",
    brandGreen: "#149590",
    brandRed: "#c43351",
    visitedPurple: "#6774ed",
    darkmodeBg: "#161616",
    darkmodeGrey: "#222",
    darkmodeRed: "#fc486d",
    darkmodeBlue: "#83b7ff",
    searchExpanderGrey1: "#6b7079",
};
