// eslint-disable-next-line no-restricted-imports
import { useAuth as useReactOidc } from "react-oidc-context";
import { UserSubscription }        from "@/@types/custom";

declare module "oidc-client-ts" {
    interface IdTokenClaims {
        subscription?: UserSubscription;
    }
}

export const useAuth = () => {
    const auth = useReactOidc();
    const userProfile = auth?.user?.profile;

    const hasPremiumWithCurrentAccount =
        typeof userProfile?.subscription?.subscriptionExpiry === "number" &&
        userProfile.subscription.subscriptionExpiry > Math.floor(Date.now() / 1000);

    const hadPremiumWithCurrentAccount =
        typeof userProfile?.subscription?.subscriptionExpiry === "number" &&
        userProfile.subscription.subscriptionExpiry < Math.floor(Date.now() / 1000);

    const browsingViaMobileAppWthPremium = !!(window as any)?.__freespoke__?.HasPremium;

    return {
        ...auth,
        hasPremiumWithCurrentAccount,
        hadPremiumWithCurrentAccount,
        browsingViaMobileAppWthPremium,
    };
};
