import axios                from "axios";
import { stringify }        from "query-string";
import useSWR               from "swr";
import { ICampaignVariant } from "@/@types/custom";
import { useBackendAccess } from "@/api/useBackendAccess";
import { BACKEND_URL }      from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export interface useGetCampaignVariantParams {
    type: "download-popup";
    mkt_campaign: string;
}

export type UseGetCampaignVariantResponse = ICampaignVariant;

const useGetCampaignVariant = (params: useGetCampaignVariantParams) => {
    const { guestAccess } = useBackendAccess();
    const fetcher = (URL: string) => axios.get(URL, { headers: guestAccess?.authHeaders }).then(res => res.data);
    const url = `${BACKEND_URL}/v2/marketing/campaign_variant?${stringify(params)}`;
    const shouldFetch = guestAccess?.authHeaders && params.mkt_campaign && params.type;

    const { data, error, isValidating, isLoading, mutate } = useSWR<UseGetCampaignVariantResponse>(shouldFetch ? url : null, fetcher, config);

    return {
        data,
        isFetching: !guestAccess?.authHeaders || isValidating,
        isLoading,
        error,
        refresh: mutate,
    };
};

export default useGetCampaignVariant;
