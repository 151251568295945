import { useLocation }   from "react-router-dom";
import { useAuth }       from "@/hooks/useAuth";
import { useEffectOnce } from "@/hooks/useEffectOnce";
import FreespokeLoader   from "@/molecules/FreespokeLoader/FreespokeLoader";

type Props = {
    redirectPath?: string;
};

const Login = ({ redirectPath }: Props) => {
    const auth = useAuth();
    const location = useLocation();
    const redirectURL = new URLSearchParams(location.search).get("redirect_uri") || redirectPath;

    useEffectOnce(() => {
        auth.signinRedirect({ redirectMethod: "replace", ...(redirectURL ? { redirect_uri: redirectURL } : {}) });
    });

    return (
        <div className="Login">
            <FreespokeLoader />
        </div>
    );
};

export default Login;
