import axios               from "axios";
import { AuthHeaders }     from "@/api/useBackendAccess";
import { BACKEND_URL }     from "@/config";
import { extractAPIError } from "@/helpers/index";

interface Payload {
    name?: string;
    email: string;
    phone?: {
        country_code: number;
        local_number: string;
        country_short: string;
    };
    subscribe_to_freespoke_news: boolean;
    subscribe_to_breaking_news_texts: boolean;
    subscribe_to_spangld?: boolean;
    subscribe_to_election_2024?: boolean;
}

export async function signupAPI(payload: Payload, authHeaders?: AuthHeaders) {
    try {
        const { data } = await axios.post(`${BACKEND_URL}/forms/signup`, payload, { headers: authHeaders });
        return data;
    } catch (e) {
        throw new Error(extractAPIError(e));
    }
}
