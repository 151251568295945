/* eslint-disable no-mixed-operators */
import { format, formatDistanceToNowStrict, isValid as isValidDate } from "date-fns";
import { Location }                                                  from "history";
import { TWeekDay }                                                  from "@/@types/custom";
import { CONSTANTS }                                                 from "./constants";

export function uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0]! & (15 >> (c / 4)))).toString(16)
    );
}

export function isMobileWidth() {
    return window.innerWidth < 641;
}

export const isFreespokeBrowser = !!navigator?.userAgent?.toLowerCase().includes("freespoke"); // User is using our mobile app browser

export function getQueryParamsFromUrl(queryString: string) {
    const params = new URLSearchParams(queryString);
    return {
        q: params.get("q"),
        page: parseInt(params.get("page") || "1"),
        bias: params.get("bias"),
        place_id: params.get("place_id"),
    };
}

export function scrollToTop(options?: ScrollOptions) {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: options?.behavior || "smooth",
    });
}

export function toTimeAgo(date: string, short?: boolean) {
    const dateObj = new Date(date);
    if (!isValidDate(dateObj)) {
        return "invalid date";
    }
    let formattedDistance = formatDistanceToNowStrict(dateObj, { addSuffix: true });

    // Removes 'in' keyword from future dates (ex: "in 20 minutes" becomes "20 minutes" )
    if (formattedDistance.startsWith("in")) {
        formattedDistance = formattedDistance.slice(3);
    }

    if (short) {
        const distanceSplit = formattedDistance.split(" ");
        // "2 months ago" becomes "2mo ago"
        return `${distanceSplit[0]}${distanceSplit[1]?.substr(0, distanceSplit[1].includes("month") ? 2 : 1)} ${
            distanceSplit[2] ? distanceSplit[2] : ""
        }`;
    }
    return formattedDistance;
}

export function toMonthDayCommaYear(originalDate: string, excludeTimezone: boolean, shortMonth?: boolean) {
    const date = excludeTimezone && typeof originalDate === "string" ? originalDate?.replace("Z", "") : originalDate;

    const dateObj = new Date(date);
    if (!isValidDate(dateObj)) {
        return "invalid date";
    }

    const formattedDistance = shortMonth ? format(dateObj, "MMM d, y") : format(dateObj, "MMMM d, y");

    return formattedDistance;
}

export function toVideoDurationFormat(seconds: number): string {
    if (seconds < 0) return "Live";

    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const hrsStr = hrs > 0 ? `${hrs}:` : "";
    const minsStr = mins > 0 ? `${mins < 10 && hrs > 0 ? "0" : ""}${mins}:` : hrs > 0 ? "00:" : "0:";
    const secsStr = `${secs < 10 ? "0" : ""}${secs}`;

    return `${hrsStr}${minsStr}${secsStr}`;
}

export function kMilFormatter(num: number) {
    if (Math.abs(num) > 999) {
        if (Math.abs(num) > 999999) {
            return Math.sign(num) * (Math.round(Math.abs(num) / 100000) / 10) + "M";
        }
        return Math.sign(num) * (Math.round(Math.abs(num) / 100) / 10) + "K";
    }
    return Math.sign(num) * Math.abs(num);
}

const queryParam = (label: string, value: string) => (value ? `&${label}=${encodeURIComponent(value)}` : "");

// URL schemes taken from https://github.com/bradvin/social-share-urls
export const createShareUrl = {
    facebook: (url: string) => `http://www.facebook.com/sharer.php?u=${url}`,
    twitter: (url: string, options?: Partial<{ text: string; via: string; hashtags: string[] }>) =>
        `https://twitter.com/intent/tweet?url=${url}${queryParam("text", options?.text || "")}${queryParam("via", options?.via || "")}${queryParam(
            "hashtags",
            options?.hashtags?.join(",") || ""
        )}`,
    email: (options?: Partial<{ to: string; subject: string; body: string }>) =>
        `mailto:${options?.to ? options.to : ""}?${queryParam("subject", options?.subject || "")}${queryParam("body", options?.body || "")}`,
    linkedin: (url: string) => `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    reddit: (url: string, options?: Partial<{ title: string }>) => `https://reddit.com/submit?url=${url}${queryParam("title", options?.title || "")}`,
};

export const createFacebookUrl = (id: string) => `https://www.facebook.com/${id}`;
export const createInstagramUrl = (id: string) => `https://www.instagram.com/${id}`;
export const createTwitterUrl = (id: string) => `https://twitter.com/${id}`;

/* 
    Removes 'https://t.co/..' URLs from text
    Converts this text: "Louisiana probing facility where four nursing home residents died after Ida evacuation https://t.co/4YTYuOForz https://t.co/ZgTMt9MjEb"
    To this: "Louisiana probing facility where four nursing home residents died after Ida evacuation"
*/
export function removeTweetURLs(text: string) {
    if (typeof text === "string") {
        return text.replace(/https:\/\/t.co\S+/g, "").trim();
    }
    return text;
}

/**
 * Dash Class Builder
 * @param className Base class name
 * @param dashType Dash class(s).
 * @example dash("Product", "small") => "Product Product--small"
 * @example dash("Product", someBooleanExpression && "small") => "Product Product--small" or "Product"
 * @example dash("Product", ["small", someBooleanExpression && "fullWidth"]) => "Product Product--small" or "Product Product--small Product--fullWidth"
 * @returns Base class plus dash classes
 */
export const dash: (className: string, dashType?: boolean | string | Array<boolean | string>) => string = (className, dashType) =>
    (d => [className, ...d.map(item => (typeof item === "boolean" ? "" : `${className}--${item}`))].join(" "))(
        Array.isArray(dashType) ? dashType : [dashType]
    );

const USDFormat = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const toUSD = (price: number) => USDFormat.format(price);

export const isTruthy = <T>(x: T | false | undefined | null | "" | 0): x is T => !!x;

export const toTitleCase = (str?: string) =>
    str
        ?.replace(/\w\S*/g, text => capitalizeFirstLetter(text))
        .split("/")
        .map(txt => txt && capitalizeFirstLetter(txt))
        .join("/") || str;

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export function stripHTML(html: string) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
}

export function getAppStoreLink(type: "android" | "ios") {
    return type === "ios" ? CONSTANTS.LINKS.IOS_APP : CONSTANTS.LINKS.ANDROID_APP;
}

const browserLanguagePropertyKeys = ["language", "browserLanguage", "systemLanguage", "userLanguage"] as const;

function isCustomNavigator<T extends (typeof browserLanguagePropertyKeys)[number]>(nav: any, key: T): nav is Record<T, string> {
    return key in nav;
}

export function getFirstBrowserLanguage(): string | null {
    const nav = window.navigator;

    let i: number,
        shortLanguage: string | null = null,
        language: string,
        len: number;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i];
            len = language.length;
            if (!shortLanguage && len) {
                shortLanguage = language;
            }
            if (language && len > 2) {
                return language;
            }
        }
    }

    // support for other well known properties in browsers
    for (const key of browserLanguagePropertyKeys) {
        if (!isCustomNavigator(nav, key)) {
            continue;
        }
        language = nav[key];

        //skip this loop iteration if property is null/undefined.  IE11 fix.
        if (language == null) {
            continue;
        }
        len = language.length;
        if (!shortLanguage && len) {
            shortLanguage = language;
        }
        if (language && len > 2) {
            return language;
        }
    }

    return shortLanguage;
}

// NOTE: this function excludes query params with '', undefined and null values
export function toURLSearchParams(params: Record<string, string | number>) {
    const sanitizedParams = new URLSearchParams();

    Object.keys(params).forEach(key => {
        const value = params[key];
        if (value !== "" && value !== undefined && value !== null) {
            sanitizedParams.append(key, typeof value === "number" ? value?.toString() : value);
        }
    });

    return sanitizedParams;
}

export function getHostnameFromURL(url: string) {
    try {
        const helperUrl = url && new URL(url);
        const hostname = helperUrl && helperUrl.hostname;
        return hostname;
    } catch (e) {
        console.error("getHostnameFromURL: ", e);
    }
}

/**
 * @returns number of day of week (1-7)
 */
export function getCurrentDayNumber(date?: Date) {
    const day = (date ?? new Date()).getDay();
    return (day === 0 ? 7 : day) as TWeekDay;
}

/**
 * @param timeStr: string
 * @example convertToAmPm("1100") => "11:00 AM"
 * @returns 12 hour time format
 */
export function convertToAmPm(timeStr: string) {
    const hours = parseInt(timeStr.slice(0, 2));
    const minutes = parseInt(timeStr.slice(2, 4));
    let timeValue;

    if (hours > 0 && hours <= 12) {
        timeValue = "" + hours;
    } else if (hours > 12) {
        timeValue = "" + (hours - 12);
    } else if (hours === 0) {
        timeValue = "12";
    }

    let minutesFormatted;
    if (minutes === 0) {
        minutesFormatted = "";
    } else if (minutes < 10) {
        minutesFormatted = ":0" + minutes;
    } else {
        minutesFormatted = ":" + minutes;
    }

    timeValue += minutesFormatted;
    timeValue += hours >= 12 ? "pm" : "am";

    if (timeValue === "11:59pm") {
        return "12am";
    }

    return timeValue;
}

// https://stackoverflow.com/a/74823834/10356909
// Object.entries throws away type information when coming up with keys; this helper preserves the types
type Entries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];
export function getEntries<T extends object>(obj: T | undefined) {
    if (!obj) return undefined;
    return Object.entries(obj) as Entries<T>;
}

export function isNumber(value: unknown) {
    return typeof value === "number" && !isNaN(value);
}

export function createUpdatedURL(params: Record<string, string | boolean | null>, pathname: string, search: string | null) {
    const urlParams = new URLSearchParams(search || undefined);
    Object.keys(params).forEach(key => {
        const paramValue = params[key];
        if (paramValue === null || paramValue === "") {
            urlParams.delete(key);
        } else {
            const newValue = typeof paramValue === "boolean" ? paramValue.toString() : paramValue;
            urlParams.set(key, newValue!);
        }
    });
    return `${pathname}?${urlParams}`;
}

export const convertToDisplayURL = (url?: string) => {
    let newUrl = url?.replace(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g, "");

    if (newUrl?.endsWith("/")) {
        newUrl = newUrl.slice(0, -1);
    }
    return newUrl;
};

export const getRedirectURL = (location: Location): string => {
    const fullPath = `${window.location.origin}${location.pathname}${location.search}`;
    return encodeURIComponent(fullPath);
};

export const getObjectKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

export const extractAPIError = (error: any): string => {
    if (typeof error === "string") {
        return error;
    } else {
        const errorMessage = error?.response?.data?.message || error.message?.toString() || error?.toString();
        return errorMessage;
    }
};

export const abbreviateNumber = (number: number) => {
    const symbol = ["", "k", "M", "B", "T"];

    const tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number;

    // get suffix and determine scale
    const suffix = symbol[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return `${scaled.toFixed(2)}${suffix}`;
};

export const compareStrings = (str1: string, str2: string) => {
    return str1.trim().toLowerCase() === str2.trim().toLowerCase();
};

export const abbreviateDistanceUnit = (unit: string) => {
    if (unit === "miles") {
        return "mi";
    }

    if (unit === "kilometers") {
        return "km";
    }

    return unit;
};
