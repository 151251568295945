import axios                from "axios";
import { stringify }        from "query-string";
import useSWR               from "swr";
import { useBackendAccess } from "@/api/useBackendAccess";
import { BACKEND_URL }      from "@/config";

const config = { revalidateOnFocus: false, revalidateOnReconnect: false };

export interface UseGetCollectionsParams {
    page: number;
    per_page: number;
}

export interface UseGetCollectionsResponse {
    categories: {
        id: string;
        title: string;
        logo?: string;
        slug: string;
    }[];
    total: number;
}

const useGetShopCategories = (params: UseGetCollectionsParams) => {
    const { guestAccess } = useBackendAccess();

    const url = `${BACKEND_URL}/v2/shop/categories?${stringify(params)}`;

    const fetcher = (URL: string) => axios.get<UseGetCollectionsResponse>(URL, { headers: guestAccess?.authHeaders }).then(res => res.data);
    const { data, error, isValidating, isLoading, mutate } = useSWR(guestAccess?.authHeaders ? url : null, fetcher, config);

    return {
        data,
        isFetching: !guestAccess?.authHeaders || isValidating,
        isLoading,
        error,
        refresh: mutate,
    };
};

export default useGetShopCategories;
