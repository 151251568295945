import { useLayoutEffect } from "react";

export default function useLockBodyScroll(lock: boolean) {
    useLayoutEffect(() => {
        const originalOverflowStyle = window.getComputedStyle(document.body).overflow;
        const originalPositionStyle = window.getComputedStyle(document.body).position;
        let scrollPosition: number;
        // Prevent scrolling on mount
        if (lock) {
            // Get original body overflow
            document.body.style.overflow = "hidden";
            scrollPosition = window.scrollY;
            document.body.style.position = "fixed"; // fix for Safari (also scrolls to top each time)
        }
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalOverflowStyle;
            document.body.style.position = originalPositionStyle;
            lock && window.scrollTo(0, scrollPosition);
            // console.log("useLockBodyScroll unlocked");
        };
    }, [lock]); // Empty array ensures effect is only run on mount and unmount
}
