import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { COLORS }                                                                          from "@/components/colors";
import { StorageService }                                                                  from "@/helpers/storage";

const DarkModeContext = createContext({
    darkMode: false,
    toggleDarkMode: () => {},
});

type DarkModeProviderProps = {
    children: ReactNode;
    forceValue?: "light" | "dark";
};

export const DarkModeProvider = ({ children, forceValue }: DarkModeProviderProps) => {
    const [darkMode, setDarkMode] = useState(() => {
        const localStored = StorageService.DarkModePreference.get();
        if (localStored === "true") {
            return true;
        } else if (localStored === "false") {
            return false;
        }
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        return mediaQuery?.matches ?? false;
    });

    // Change dark mode when system changes
    useEffect(() => {
        const setDarkModeFromEvent = (e: MediaQueryListEvent) => {
            setDarkMode(e.matches);
        };

        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

        // Prevents "window.matchMedia("(prefers-color-scheme: dark)").addEventListener is not a function" error on Safari <14
        if (mediaQuery?.addEventListener) {
            mediaQuery.addEventListener("change", setDarkModeFromEvent);
        } else {
            mediaQuery.addListener(setDarkModeFromEvent);
        }

        return () => {
            mediaQuery?.removeEventListener("change", setDarkModeFromEvent);
        };
    }, []);

    const toggleDarkMode = useCallback(() => {
        setDarkMode(prev => {
            const newMode = !prev;
            StorageService.DarkModePreference.set(newMode);
            return newMode;
        });
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = darkMode ? COLORS.darkmodeBg : "transparent";
    }, [darkMode]);

    const renderDarkMode = useMemo(() => (forceValue === "dark" ? true : forceValue === "light" ? false : darkMode), [darkMode, forceValue]);

    return (
        <DarkModeContext.Provider value={{ darkMode: renderDarkMode, toggleDarkMode }}>
            <div className={renderDarkMode ? "ff-darkmode" : ""}>{children}</div>
        </DarkModeContext.Provider>
    );
};

export const useDarkModeContext = () => {
    return useContext(DarkModeContext);
};
