import { FC, Suspense, lazy, useEffect, useMemo, useState } from "react";
import { useMatomo }                                        from "@datapunt/matomo-tracker-react";
import clsx                                                 from "clsx";
import { Route, Switch, useLocation }                       from "react-router-dom";
import { getAnalyticsCategory }                             from "@/analytics/analytics";
import { usePageViewTracking }                              from "@/api/telemetry/usePageViewTracking";
import { useBackendAccess }                                 from "@/api/useBackendAccess";
import { A_B_VERSION }                                      from "@/config";
import { ScrollContextProvider }                            from "@/context/useScrollContext";
import { SearchTabsVisibilityContextProvider }              from "@/context/useSearchTabsVisibilityContext";
import { ProtectedRoute }                                   from "@/feature/auth/ProtectedRoute";
import { CONSTANTS }                                        from "@/helpers/constants";
import { scrollToTop }                                      from "@/helpers/index";
import { HINT_TYPES, StorageService }                       from "@/helpers/storage";
import { useRefreshTokenOnInit }                            from "@/hooks/auth/useRefreshTokenOnInit";
import { AppBarProvider }                                   from "@/hooks/useAppBar";
import { useAuth }                                          from "@/hooks/useAuth";
import { useMobileAppDetection }                            from "@/hooks/useMobileAppDetection";
import Footer                                               from "@/layout/Footer/Footer";
import { ExtensionModalVariants }                           from "@/molecules/ExtesnionModalVariants/ExtensionModalVariants";
import FreespokeLoader                                      from "@/molecules/FreespokeLoader/FreespokeLoader";
import { UninstallExtensionModal }                          from "@/molecules/UnistallExtensionModal/UninstallExtensionModal";
import AppBar                                               from "@/organisms/AppBar/AppBar";
import { EnvironmentBanner }                                from "@/organisms/EnvironmentBanner/EnvironmentBanner";
import ErrorModal                                           from "@/organisms/ErrorModal/ErrorModal";
import useAppHeight                                         from "@/utils/useAppHeight";
import "./Root.less";

/*
 * We can assume that the below components won't be needed for 90% of users,
 * so let's not include them in main.script and main.css assets.
 * It will be only loaded after user navigates to the routes that use these components
 */
const SignUp = lazy(() => import("@/pages/SignUp/SignUp"));
const Login = lazy(() => import("@/feature/auth/Login"));
const Register = lazy(async () => import("@/feature/auth/Register"));
const Logout = lazy(async () => import("@/feature/auth/Logout"));
const UserProfilePage = lazy(() => import("@/pages/UserProfilePage/UserProfilePage").then(module => ({ default: module.UserProfilePage })));
const DeleteProfilePage = lazy(() => import("@/pages/DetailProfilePage/DeleteProfilePage").then(module => ({ default: module.DeleteProfilePage })));

const EnjoyPremium = lazy(() => import("@/pages/EnjoyPremium/EnjoyPremiumPage"));
const GetPremium = lazy(() => import("@/pages/GetPremium/GetPremium"));
const SubscriptionsNotice = lazy(() => import("@/pages/SubscriptionsNoticePage/SubscriptionsNoticePage"));
const SubscriptionManagement = lazy(() =>
    import("@/pages/SubscriptionManagement/SubscriptionManagement").then(module => ({ default: module.SubscriptionManagement }))
);

const AboutV2 = lazy(() => import("../AboutV2/AboutV2"));
const AboutV3 = lazy(() => import("../AboutV3/AboutV3"));
const AboutShopUSA = lazy(() => import("@/pages/AboutShopUSA/AboutShopUSA"));

const NoticeOfCollection = lazy(() => import("@/pages/NoticeOfCollection/NoticeOfCollection"));
const PrivacyPolicy = lazy(() => import("@/pages/PrivacyPolicy/PrivacyPolicy"));
const TermsOfService = lazy(() => import("@/pages/TermsOfService/TermsOfService"));

const ElectionPage = lazy(() => import("@/pages/Election/ElectionPage"));
const Election2024Page = lazy(() => import("@/pages/Election2024/Election2024Page"));

const SearchPage = lazy(() => import("@/pages/SearchPage/SearchPage"));
const Category = lazy(() => import("@/pages/Category/Category"));
const CategoriesSearch = lazy(() => import("@/pages/CategoriesSearch/CategoriesSearch"));
const TopicSearch = lazy(() => import("@/pages/TopicSearch/TopicSearch"));
const Shop = lazy(() => import("@/pages/Shop/Shop"));
const StoryPageV2 = lazy(() => import("@/pages/StoryPageV2/StoryPageV2"));
const TopStoriesPage = lazy(() => import("@/pages/TopStoriesPage/TopStoriesPage"));
const NewsPage = lazy(() => import("@/pages/News/NewsPage"));
const Homepage = lazy(() => import("@/pages/Homepage/Homepage"));

const ComponentPreview = lazy(() => import("@/pages/ComponentPreview/ComponentPreview"));
const OpenSourcePage = lazy(() => import("@/pages/OpenSourcePage/OpenSourcePage").then(module => ({ default: module.OpenSourcePage })));

const SearchScreenV4 = lazy(() => import("@/components/organisms/SearchScreenV4/SearchScreenV4"));

const Root: FC<React.PropsWithChildren<unknown>> = () => {
    useAppHeight();
    useRefreshTokenOnInit();
    usePageViewTracking(); // page view analytics (node/core)

    const location = useLocation();
    const { hash } = useLocation();
    const auth = useAuth();
    const { isMobileApp: isFreespokeApp } = useMobileAppDetection();
    const [hasVisitedHomepage, setHasVisitedHomepage] = useState<boolean>(false);
    const { trackEvent, trackPageView: trackPageViewWithMatomo } = useMatomo();
    const pageName = getAnalyticsCategory(location.pathname);
    const hasLanded = StorageService.HasLandedOnFreeSpoke.get();

    // Auto refresh access token - keeps user logged in
    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        if (auth?.isAuthenticated) {
            // Set a hint in the local storage that user has an account, helps to determine when to display Login or Signup button
            if (!StorageService.HasHint.get(HINT_TYPES.HAS_ACCOUNT)) {
                StorageService.HasHint.set(HINT_TYPES.HAS_ACCOUNT, true);
            }

            // return auth.events.addAccessTokenExpiring(() => {
            //     console.log("Access token expiring, refreshing silently");
            //     auth.signinSilent();
            // });
        }
    }, [auth, auth.isAuthenticated]);

    // Global Matomo page tracking
    useEffect(() => {
        trackPageViewWithMatomo({ documentTitle: document.title, href: window.location.href });
        trackEvent({
            category: "pageview",
            action: `pageview ${getAnalyticsCategory(location.pathname)}`,
            name: "",
        });
    }, [location.pathname, location.search, trackEvent, trackPageViewWithMatomo]);

    useEffect(() => {
        if (!hasLanded) {
            // land001
            trackEvent({
                category: "Landed on",
                action: `land - ${pageName}`,
                name: "",
            });
            // land002
            trackEvent({
                category: "Landed on",
                action: `land ${A_B_VERSION} - ${pageName}`,
                name: "",
            });
            StorageService.HasLandedOnFreeSpoke.set(true);
        }
    }, [hasLanded, pageName, trackEvent]);

    // Save for future use
    // const joinMovementVisibilityRegex = /^\/?$|^(\/join)/ ;

    // Set mtm_campaign id in the storage if user came via marketing link
    // Only runs once, after the initial mount
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const mtm_campaign = urlParams.get("mtm_campaign");
        if (mtm_campaign) {
            StorageService.MarketingCampaignId.set(mtm_campaign);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // when someone lands on any page page other then homepage, we don't want to render 'Homepage' component
    useEffect(() => {
        if (location.pathname === "/" && !hasVisitedHomepage) {
            setHasVisitedHomepage(true);
        }
    }, [hasVisitedHomepage, location.pathname]);

    // Scroll to top on navigation stack push (or replace)
    useEffect(() => {
        // do not scroll if user navigated to a story page newsfeed tab or profile page
        if (!/^\/story\/.+\/.+\/.+/.test(location.pathname) && !location.pathname.startsWith("/account/profile")) {
            scrollToTop({ behavior: "auto" });
        }

        trackEvent({
            category: "entry",
            name: "",
            action: `enter - ${getAnalyticsCategory(location.pathname)}`,
        });
    }, [location.pathname, trackEvent]);

    // Error logic
    const [authHasTimedOut, setAuthHasTimedOut] = useState(false);
    const { guestAccess } = useBackendAccess();
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!guestAccess?.authHeaders) {
                setAuthHasTimedOut(true);
            }
        }, 10000);

        return () => {
            clearTimeout(timeout);
        };
    }, [guestAccess?.authHeaders]);
    const showErrorModal = useMemo(
        () =>
            (authHasTimedOut || !!guestAccess?.error) &&
            !location.pathname.startsWith("/") &&
            !location.pathname.startsWith("/story") &&
            !location.pathname.startsWith("/about") &&
            !location.pathname.startsWith("/login") &&
            !location.pathname.startsWith("/logout") &&
            !location.pathname.startsWith("/account") &&
            !location.pathname.startsWith("/signup"),
        [authHasTimedOut, guestAccess?.error, location.pathname]
    );

    return (
        <AppBarProvider>
            <ScrollContextProvider>
                <SearchTabsVisibilityContextProvider>
                    {/* {DISPLAY_OUTAGE_BANNER && <OutageBanner />} */}

                    <div className={clsx("Root", isFreespokeApp && "mobile-app")}>
                        <Suspense
                            fallback={
                                <div
                                    style={{
                                        flex: "1 1",
                                        display: "flex",
                                        height: "100%",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <FreespokeLoader />
                                </div>
                            }
                        >
                            {/* Save for future use */}
                            {/* {!joinMovementVisibilityRegex.test(location.pathname) && <JoinMovementCTA />} */}

                            {showErrorModal && <ErrorModal />}
                            {hash?.startsWith("#how-to-get-") && <ExtensionModalVariants />}
                            {hash === "#uninstall-extension" && <UninstallExtensionModal />}
                            <Switch>
                                <Route path="/open-source" component={OpenSourcePage} />
                                <Route path="/story/:story_id/:story_slug?" component={StoryPageV2} />
                                <Route path="/category/:category_name/:topic_id?" component={Category} />
                                <Route path="/search/categories/:category_id?" component={CategoriesSearch} />
                                <Route path="/search/topic/:topic_id?/:topic_name?" component={TopicSearch} />
                                <Route path="/search" component={SearchPage} />
                                <Route path="/shop" component={Shop} />
                                <Route path="/about/pete" component={() => <AboutV2 variant="pete" />} />
                                <Route path="/about/holiday" component={() => <AboutV2 variant="holiday" />} />
                                <Route path="/about/results" component={() => <AboutV2 variant="results" />} />
                                <Route path="/about/hunter" component={() => <AboutV2 variant="hunter" />} />
                                <Route path="/about/election2024" component={() => <AboutV3 variant="election2024" />} />
                                <Route path="/about" component={() => <AboutV2 variant="default" />} />
                                <Route path="/about-freespoke" component={() => <AboutV3 variant="default" />} />
                                <Route path="/darkhorse" component={() => <AboutV3 variant="darkhorse" />} />
                                <Route path="/privacyfiles" component={() => <AboutV3 variant="privacy_files" />} />
                                <Route path="/summer" component={() => <AboutV3 variant="summer" />} />
                                <Route path="/join" component={SignUp} />
                                <Route path="/extension-success" component={SearchScreenV4} />
                                <Route path="/what-is-hot" component={TopStoriesPage} />
                                <Route path="/notice-of-collection" component={NoticeOfCollection} />
                                <Route path="/privacy-policy" component={PrivacyPolicy} />
                                <Route path="/terms-of-service" component={TermsOfService} />
                                <Route path="/election/2022" component={ElectionPage} />
                                <Route path="/app/election/2022" component={ElectionPage} />
                                <Route path="/election/2024" component={Election2024Page} />
                                <Route path="/news" component={NewsPage} />
                                <Route path="/about-shop-usa" component={AboutShopUSA} />
                                <Route path="/_components/preview" component={ComponentPreview} />
                                <Route path="/subscriptions/notice" component={SubscriptionsNotice} />
                                <Route
                                    path="/newsletter"
                                    render={() => {
                                        window.location.reload();
                                        return null;
                                    }}
                                />

                                <Route path={CONSTANTS.LINKS.ACCOUNT.PROFILE}>
                                    <ProtectedRoute redirectPath={CONSTANTS.LINKS.ACCOUNT.PROFILE}>
                                        <UserProfilePage />
                                    </ProtectedRoute>
                                </Route>

                                <Route path="/account/delete">
                                    <ProtectedRoute redirectPath={CONSTANTS.LINKS.ACCOUNT.PROFILE}>
                                        <DeleteProfilePage />
                                    </ProtectedRoute>
                                </Route>
                                <Route path="/account/subscription/manage">
                                    <ProtectedRoute redirectPath={CONSTANTS.LINKS.ACCOUNT.PROFILE}>
                                        <SubscriptionManagement />
                                    </ProtectedRoute>
                                </Route>

                                <Route path={CONSTANTS.LINKS.PREMIUM.SUBSCRIBE} component={GetPremium} />
                                {/* <Route path={CONSTANTS.LINKS.PREMIUM.APP}>
                                        <ProtectedRoute redirectPath={CONSTANTS.LINKS.PREMIUM.APP}>
                                            <EnjoyPremium />
                                        </ProtectedRoute>
                                    </Route> */}
                                <Route path={CONSTANTS.LINKS.PREMIUM.APP} component={EnjoyPremium} />
                                <Route path="/premium" component={() => <AboutV3 variant="premium_offer" />} />

                                <Route path="/login" component={Login} />
                                <Route path="/signup" component={Register} />
                                <Route path="/logout" component={Logout} />
                            </Switch>
                            {hasVisitedHomepage && <Homepage />}
                        </Suspense>
                        <Footer />
                    </div>
                    <EnvironmentBanner />
                    <AppBar />
                </SearchTabsVisibilityContextProvider>
            </ScrollContextProvider>
        </AppBarProvider>
    );
};
export default Root;
