import { FunctionComponent, ReactNode, useCallback, useMemo, useState } from "react";
import uniq                                                             from "lodash-es/uniq";
import Image                                                            from "@/designSystem/Image/Image";
import PlaceHolderSVG                                                   from "@/static/icons/img_placeholder.svg";
import "./ImageLoader.less";

interface Props {
    className?: string;
    src?: string;
    srcList?: string[];
    alt: string;
    fallback?: boolean;
    fallbackComponent?: ReactNode;
    wrapperStyle?: React.CSSProperties;
    wrapperClassName?: string;
    imageClassName?: string;
    imageLoadingType?: "eager" | "lazy";
    onClick?: () => void;
    style?: React.CSSProperties;
    onLoad?: () => void;
    draggable?: boolean;
}

const defaultImage = PlaceHolderSVG;

const ImageLoader: FunctionComponent<React.PropsWithChildren<Props>> = ({
    children,
    className,
    src,
    srcList,
    alt,
    fallback = true,
    fallbackComponent,
    imageClassName,
    wrapperStyle,
    wrapperClassName,
    imageLoadingType,
    onClick,
    style,
    onLoad,
    draggable,
}) => {
    const [currentSrcIndex, setCurrentSrcIndex] = useState<number>(0);
    const sources = useMemo<string[]>(() => (src && [src]) || (Array.isArray(srcList) && uniq(srcList)) || [""], [src, srcList]);
    const [altText, setAltText] = useState(alt);
    const [hasError, setHasError] = useState(false);
    const onError = useCallback(() => {
        console.warn("Failed to load image: ", sources[currentSrcIndex]);

        if (sources[currentSrcIndex + 1]) {
            console.warn("Trying to load another image: ", sources[currentSrcIndex + 1]);
            setCurrentSrcIndex(currentSrcIndex + 1);
        } else {
            setAltText("");
            if (fallback) {
                setHasError(true);
            }
        }
    }, [currentSrcIndex, fallback, sources]);

    if (hasError && !fallbackComponent && !fallback) {
        return null;
    }

    return (
        <div onClick={onClick} className={`ImageLoader ${className ? className : ""}`}>
            {(!hasError || (fallback && !fallbackComponent)) && (
                <Image
                    className={imageClassName}
                    onError={onError}
                    placeholder={false}
                    {...(wrapperStyle && { wrapperStyle })}
                    {...(wrapperClassName && { wrapperClassName })}
                    preview={false}
                    loading={imageLoadingType || "lazy"}
                    src={sources[currentSrcIndex] || ""}
                    fallback={fallback ? defaultImage : undefined}
                    alt={altText}
                    style={style}
                    onLoad={onLoad}
                    draggable={draggable}
                    data-testid="image-loader"
                />
            )}

            {(hasError && fallbackComponent) || null}

            {children}
        </div>
    );
};

export default ImageLoader;
