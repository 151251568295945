import axios               from "axios";
import { AuthHeaders }     from "@/api/useBackendAccess";
import { BACKEND_URL }     from "@/config";
import { extractAPIError } from "@/helpers/index";
import { signupAPI }       from "./signupAPI";

export async function signUpForANewsletter(email: string, authHeaders?: AuthHeaders) {
    try {
        const { data } = await axios.post(`${BACKEND_URL}/forms/newsletter/register`, { email }, { headers: authHeaders });
        return data;
    } catch (e) {
        throw new Error(extractAPIError(e));
    }
}

export async function signUpForShopUsaNewsletter(email: string, authHeaders?: AuthHeaders) {
    try {
        const { data } = await axios.post(
            `${BACKEND_URL}/forms/newsletter/v2/shop/usa`,
            { email, subscribe_to_shop_usa_emails: true, subscribe_to_general_list: true },
            { headers: authHeaders }
        );
        return data;
    } catch (e) {
        throw new Error(extractAPIError(e));
    }
}

export async function signUpForElection2024(email: string, authHeaders?: AuthHeaders) {
    return signupAPI(
        {
            email,
            subscribe_to_freespoke_news: false,
            subscribe_to_breaking_news_texts: false,
            subscribe_to_spangld: false,
            subscribe_to_election_2024: true,
        },
        authHeaders
    );
}
