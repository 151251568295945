import { useEffect, useRef } from "react";

type UseClickOutsideParams = {
    onClickOutside: (target: EventTarget | null) => void;
};

// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
export function useClickOutside({ onClickOutside }: UseClickOutsideParams) {
    const ref = useRef<any>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutside(event.target);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClickOutside, ref]);

    return { ref };
}
