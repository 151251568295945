import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useInView }                                                                from "react-intersection-observer";
import { useLocation }                                                              from "react-router-dom";
import { CONSTANTS }                                                                from "@/helpers/constants";
import { useMobileAppDetection }                                                    from "./useMobileAppDetection";

interface AppBarContextProps {
    showAppBar: boolean;
    showHamburger: boolean;
    ref: (node?: Element | null | undefined) => void;
    openHamburger: VoidFunction;
    closeHamburger: VoidFunction;
}

const AppBarContext = createContext<Partial<AppBarContextProps>>({});

export const AppBarProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { inView, ref } = useInView({ initialInView: false });
    const [showHamburger, setShowHamburger] = useState<boolean>(false);
    const [showAppBar, setShowAppBar] = useState<boolean>(false);

    useEffect(() => {
        if (inView && !showHamburger) {
            setShowAppBar(false);
        } else {
            setShowAppBar(true);
        }
    }, [inView, showHamburger]);

    const openHamburger = useCallback<VoidFunction>(() => {
        if (inView) {
            setShowAppBar(true);
        }
        setShowHamburger(true);
    }, [inView]);

    const closeHamburger = useCallback<VoidFunction>(() => {
        setShowHamburger(false);
    }, []);

    const location = useLocation();

    const { isMobileApp } = useMobileAppDetection();

    const shouldShowAppBar = useMemo(() => {
        if (showHamburger) {
            return true;
        }

        // Never show on these paths
        if (neverShowAppBarStartsWithRoutes.some(route => location.pathname.startsWith(route))) {
            return false;
        }

        // Never show on mobile app except when on search
        if (isMobileApp) {
            return false;
        }

        // Show based on the internal state
        if (location.pathname === "/" || location.pathname.startsWith("/story")) {
            return showAppBar;
        }
        // Always show on the rest
        return true;
    }, [location.pathname, isMobileApp, showAppBar, showHamburger]);

    const value: AppBarContextProps = { showAppBar: shouldShowAppBar, showHamburger, ref, openHamburger, closeHamburger };

    return <AppBarContext.Provider value={value}>{children}</AppBarContext.Provider>;
};

type UseAppBar = () => Partial<AppBarContextProps>;

export const useAppBar: UseAppBar = () => useContext(AppBarContext);

const neverShowAppBarStartsWithRoutes = [
    "/join",
    "/app",
    "/login",
    "/logout",
    "/signup",
    "/extension-success",
    CONSTANTS.LINKS.PREMIUM.APP,
    CONSTANTS.LINKS.PREMIUM.SUBSCRIBE,
];
