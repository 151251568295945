import { useCallback, useState } from "react";
import { CloseOutlined }         from "@ant-design/icons";
import { REACT_APP_ENVIRONMENT } from "@/config";
import Button                    from "@/designSystem/Button/Button";
import "./EnvironmentBanner.less";

export const EnvironmentBanner = () => {
    const [shouldShow, setShouldShow] = useState(true);

    const hide = useCallback(() => {
        setShouldShow(false);
    }, []);
    if (!["staging", "development"].includes(REACT_APP_ENVIRONMENT as string)) {
        return null;
    }
    if (!shouldShow) {
        return null;
    }

    return (
        <div className="EnvironmentBanner">
            <span className="env-banner-text">You are in the {REACT_APP_ENVIRONMENT?.toLowerCase()} environment</span>
            <Button className="close-button" onClick={hide}>
                <CloseOutlined />
            </Button>
        </div>
    );
};
