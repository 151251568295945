declare global {
    interface Window {
        webkit: any;
    }
}

type EventForiOS = AuthEventLoginForiOSApp | AuthEventLogoutForiOSApp | AuthEventDeactivateAccountForiOSApp | AuthEventAccountUpdatedForiOSApp;

type AuthEventLoginForiOSApp = {
    event_name: "AuthEventLogin";
    data: {
        id_token: string;
        access_token: string;
        refresh_token: string;
    };
};

type AuthEventLogoutForiOSApp = {
    event_name: "AuthEventLogout";
    data?: never;
};

type AuthEventDeactivateAccountForiOSApp = {
    event_name: "AuthEventDeactivateAccount";
    data?: never;
};

type AuthEventAccountUpdatedForiOSApp = {
    event_name: "AuthEventAccountUpdated";
    data?: never;
};

// This function is used to send messages to the iOS app from the webview.
// https://developer.apple.com/documentation/webkit/wkusercontentcontroller#//apple_ref/occ/instm/WKUserContentController/addScriptMessageHandler:name:
export function sendMessageToiOS({ event_name, data }: EventForiOS) {
    // The window.webkit namespace only appears in webview with script message handlers.
    const messageHandlers = window.webkit?.messageHandlers;

    if (messageHandlers) {
        if (!messageHandlers?.[event_name]) {
            // DEBUG: Message.error(`${name} Message handler not found.`, 5);
            return;
        }
        // DEBUG: Message.info(`Sending message to ${name} handler...`, 5);
        window.webkit.messageHandlers[event_name].postMessage(data);
    }
}

// This function is used to send messages to the Android app from the webview. The Android app must have `FreespokeWebChannel` web extension enabled.
export function sendMessageToAndroid({ event_name, data }: EventForAndroid) {
    const isFreespokeAndroidApp = navigator.userAgent.toLowerCase().includes("freespoke");

    if (isFreespokeAndroidApp && typeof window !== "undefined") {
        const eventDetails = { event_name, data };
        window?.dispatchEvent(new CustomEvent("FreespokeWebEvent", { detail: JSON.stringify(eventDetails) }));
    }
}

type EventForAndroid =
    | AuthEventLoginForAndroidApp
    | AuthEventLogoutForAndroidApp
    | AuthEventDeactivateAccountForAndroidApp
    | AuthEventAccountUpdatedForAndroidApp;

type AuthEventLoginForAndroidApp = {
    event_name: "AuthEventLogin";
    data: {
        id_token: string;
        access_token: string;
        refresh_token: string;
    };
};

type AuthEventLogoutForAndroidApp = {
    event_name: "AuthEventLogout";
    data: {
        user_id: string;
    };
};

type AuthEventDeactivateAccountForAndroidApp = {
    event_name: "AuthEventDeactivateAccount";
    data: {
        user_id: string;
    };
};

type AuthEventAccountUpdatedForAndroidApp = {
    event_name: "AuthEventAccountUpdated";
    data: {
        user_id: string;
    };
};

export function isSessionNotActiveError(error: Error | null) {
    return error?.message?.toLowerCase() === "session not active";
}
