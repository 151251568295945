import React                                                                                                            from "react";
import { datadogRum }                                                                                                   from "@datadog/browser-rum";
import { MatomoProvider, createInstance }                                                                               from "@datapunt/matomo-tracker-react";
import { createRoot }                                                                                                   from "react-dom/client";
import { Helmet, HelmetProvider }                                                                                       from "react-helmet-async";
import { DATADOG_ENV, ENABLE_ANALYTICS, ENABLE_DATADOG, MATOMO_SITE_ID, MATOMO_SRC_URL, MATOMO_TRACKER_URL }            from "@/config";
import { MobileAppDetectionProvider }                                                                                   from "@/hooks/useMobileAppDetection";
import { DEFAULT_OGP_IMAGE_ABSOLUTE_PATH, OGP_DEFAULT_DESCRIPTION, OGP_DEFAULT_TITLE, TWITTER_OGP_IMAGE_ABSOLUTE_PATH } from "@/ogp/ogp";
import PackageJsonFile                                                                                                  from "@/root/package.json";
import App                                                                                                              from "@/src/App";
import "./index.css";

// import reportWebVitals from './reportWebVitals';

if (ENABLE_DATADOG) {
    const config = {
        applicationId: "c4c30171-3387-4b2e-9f98-eb1693574c12",
        clientToken: "pubadccc4eb5e12278f6d37393614badfaa",
        site: "datadoghq.com",
        service: "freespoke", // Specify a version number to identify the deployed version of your application in Datadog // version: '1.0.0',
        sessionSampleRate: 5, // 5%
        env: DATADOG_ENV,
        version: PackageJsonFile.version || "unknown contact admin",
        trackUserInteractions: false,
        trackResources: true,
        trackLongTasks: true,
        sessionReplaySampleRate: 0,
    };
    datadogRum.init(config);
}

const doNotTrackIsEnabled = navigator.doNotTrack === "1";
const isPlaywrightTestRunner = navigator.userAgent.toLowerCase().includes("playwright");
const isMobileApp = !!navigator.userAgent.toLowerCase().includes("freespoke");

// MATOMO Analytics
// prettier-ignore
const instance = createInstance({
    urlBase:            window.origin,
    siteId:             MATOMO_SITE_ID!,
    // userId:          '',                 // optional, default value: `undefined`.
    trackerUrl:         MATOMO_TRACKER_URL, // optional, default value: `${urlBase}matomo.php`
    srcUrl:             MATOMO_SRC_URL, // optional, default value: `${urlBase}matomo.js`
    disabled:           !ENABLE_ANALYTICS || doNotTrackIsEnabled || isPlaywrightTestRunner, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10, // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    // configurations: {    // optional, default value: {}
    //     // any valid matomo configuration, all below are optional
    //     disableCookies: true,
    //     setSecureCookie: true,
    //     setRequestMethod: 'POST'
    // }
    // setLinkTrackingTimer: 750,
});

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
    <React.StrictMode>
        <MatomoProvider value={instance}>
            <MobileAppDetectionProvider isMobileApp={isMobileApp}>
                <HelmetProvider>
                    <Helmet>
                        {/* Nested or latter components will override duplicate changes */}
                        <meta name="description" content={OGP_DEFAULT_DESCRIPTION} />
                        <meta property="og:type" content="website" />
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:title" content={OGP_DEFAULT_TITLE} />
                        <meta property="og:image" content={DEFAULT_OGP_IMAGE_ABSOLUTE_PATH} />
                        <meta property="twitter:image" content={TWITTER_OGP_IMAGE_ABSOLUTE_PATH} />
                        <meta property="og:logo" content={DEFAULT_OGP_IMAGE_ABSOLUTE_PATH} />
                        <meta property="og:url" content="https://freespoke.com" />
                        <meta property="og:description" content={OGP_DEFAULT_DESCRIPTION} />
                    </Helmet>
                    <App />
                </HelmetProvider>
            </MobileAppDetectionProvider>
        </MatomoProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
