import { FC, useCallback, useMemo }            from "react";
import Drawer                                  from "antd/lib/drawer";
import useBreakpoint                           from "antd/lib/grid/hooks/useBreakpoint";
import Paragraph                               from "antd/lib/typography/Paragraph";
import clsx                                    from "clsx";
import { isIOS }                               from "react-device-detect";
import { useLocation }                         from "react-router-dom";
import { useDarkModeContext }                  from "@/context/useDarkModeContext";
import Link                                    from "@/designSystem/Link/Link";
import Title                                   from "@/designSystem/Title/Title";
import { CONSTANTS }                           from "@/helpers/constants";
import { useGetExtensionLinkData }             from "@/hooks/extension/useGetExtensionLinkData";
import { useAnalyticsTracking }                from "@/hooks/useAnalyticsTracking";
import ShopUsaEmailSignupForm                  from "@/molecules/ShopUsaEmailSignupForm/ShopUsaEmailSignupForm";
import SignupForm                              from "@/organisms/SignupForm/SignupForm";
import { ReactComponent as CloseIcon }         from "@/static/icons/close.svg";
import { ReactComponent as TorchIcon }         from "@/static/icons/freespoke-torch-gradient.svg";
import { ReactComponent as TorchIconDarkmode } from "@/static/icons/freespoke_torch_solid.svg";
import "./NewsletterSignupDrawer.less";

interface StoryPageSignupDrawerProps {
    className?: string;
    isOpen: boolean;
    title: string;
    description: string;
    submitBtnLabel: string;
    cancelBtnLabel?: string;
    newsletterType: keyof typeof NEWSLETTER_MAP;
    analyticsPrefix: string;
    onDrawerClose?: () => void;
    maskClosable?: boolean;
}

const NEWSLETTER_MAP = {
    news: ({ submitBtnLabel, onSuccess }: Pick<StoryPageSignupDrawerProps, "submitBtnLabel"> & { onSuccess: () => void }) => (
        <SignupForm buttonLabel={submitBtnLabel} successButtonLabel="Subscribed" onSuccess={onSuccess} />
    ),
    shop: ({ submitBtnLabel, onSuccess }: Pick<StoryPageSignupDrawerProps, "submitBtnLabel"> & { onSuccess: () => void }) => (
        <ShopUsaEmailSignupForm className="signup-form" buttonLabel={submitBtnLabel} successButtonLabel="Subscribed" onSuccess={onSuccess} />
    ),
};

const NewsletterSignupDrawer: FC<React.PropsWithChildren<StoryPageSignupDrawerProps>> = ({
    className,
    isOpen,
    title,
    description,
    submitBtnLabel,
    cancelBtnLabel,
    newsletterType,
    analyticsPrefix,
    onDrawerClose,
    maskClosable = false,
}) => {
    const { trackEvent } = useAnalyticsTracking();
    const { darkMode } = useDarkModeContext();
    const { md } = useBreakpoint();
    const location = useLocation();
    const { howToInstallModalUrl } = useGetExtensionLinkData();

    const isShopUSA = location.pathname.startsWith("/shop");

    const handleClose = useCallback(() => {
        trackEvent({ action: `${analyticsPrefix} paywal dismiss`, name: "close" });
        onDrawerClose && onDrawerClose();
    }, [analyticsPrefix, onDrawerClose, trackEvent]);

    const handleFooterCTAClick = useCallback(() => {
        trackEvent({ action: `${analyticsPrefix} paywal dismiss`, name: "close" });
        onDrawerClose && onDrawerClose();
    }, [analyticsPrefix, onDrawerClose, trackEvent]);

    const onSubmitSuccess = useCallback(() => {
        trackEvent({ action: `${analyticsPrefix}paywall email submit`, name: "submit" });
        setTimeout(() => {
            onDrawerClose && onDrawerClose();
        }, 1000);
    }, [analyticsPrefix, onDrawerClose, trackEvent]);

    const renderAppExtensionLink = useMemo(() => {
        if (md) {
            return (
                <Link className="footer-cta" to={howToInstallModalUrl}>
                    Make Freespoke your default search engine
                </Link>
            );
        } else {
            return (
                <Link className="footer-cta" href={isIOS ? CONSTANTS.LINKS.IOS_APP : CONSTANTS.LINKS.ANDROID_APP}>
                    Get the official Freespoke App
                </Link>
            );
        }
    }, [howToInstallModalUrl, md]);

    return (
        <Drawer
            placement="bottom"
            width={500}
            onClose={handleClose}
            visible={isOpen}
            maskClosable={maskClosable}
            className={clsx("NewsletterSignupDrawer", className && className, darkMode && "ff-darkmode")}
            closeIcon={<CloseIcon className="icon-close" />}
        >
            <div className="container">
                {darkMode ? <TorchIconDarkmode className="icon-torch" /> : <TorchIcon className="icon-torch" />}
                <Title level={1} className="title">
                    {title}
                </Title>
                <Paragraph className="description">{description}</Paragraph>
                {NEWSLETTER_MAP[newsletterType]({ submitBtnLabel, onSuccess: onSubmitSuccess })}

                {cancelBtnLabel && (
                    <button className="footer-cta" onClick={handleFooterCTAClick}>
                        {cancelBtnLabel}
                    </button>
                )}

                {isShopUSA && renderAppExtensionLink}
            </div>
        </Drawer>
    );
};

export default NewsletterSignupDrawer;
