import { FC, memo, useCallback, useMemo } from "react";
import { useMatomo }                      from "@datapunt/matomo-tracker-react";
import { useLocation }                    from "react-router-dom";
import { AnalyticsMeta, IArticle }        from "src/@types/custom";
import { getAnalyticsCategory }           from "@/analytics/analytics";
import Avatar                             from "@/designSystem/Avatar/Avatar";
import Card                               from "@/designSystem/Card/Card";
import Link                               from "@/designSystem/Link/Link";
import Text                               from "@/designSystem/Text/Text";
import Title                              from "@/designSystem/Title/Title";
import { toTimeAgo }                      from "@/helpers/index";
import { ReactComponent as LeftIcon }     from "@/static/icons/democrat_flat.svg";
import { ReactComponent as RightIcon }    from "@/static/icons/republican_flat.svg";
import ImageLoader                        from "@/utils/ImageLoader";
import "./NewsSearchResult.less";

export interface NewsSearchResultArticle extends Omit<IArticle, "datePublished"> {
    datePublished?: string;
}

export interface NewsSearchResultProps {
    article: NewsSearchResultArticle;
    size: "small" | "large";
    showDescription?: boolean;
    showUrl?: boolean;
    publisherDisplayType: "blended-with-image" | "top";
    analyticsMeta?: Omit<AnalyticsMeta, "category">;
    reportClick?: (url: string) => void;
}

const NewsSearchResult: FC<React.PropsWithChildren<NewsSearchResultProps>> = ({
    article,
    size,
    showDescription,
    showUrl,
    analyticsMeta,
    publisherDisplayType,
    reportClick,
}) => {
    const { title, description, images, publisherName, publisherIcon, datePublished, url, bias, content } = article;
    const datePublishedFormatted = useMemo(() => datePublished && toTimeAgo(datePublished, true), [datePublished]);
    const titleMaxLines = size === "large" && description ? 2 : 3;
    const hasPublisherLabel = Array.isArray(article?.publisherTypes) && typeof article.publisherTypes[0] === "string";

    const location = useLocation();
    const { trackEvent } = useMatomo();

    // Get analytics category name - 'home', 'freespoke topic' etc
    const analytics = useMemo(
        () => ({
            category: getAnalyticsCategory(location.pathname),
        }),
        [location.pathname]
    );

    const handleLinkClick = useCallback(() => {
        // serps123
        trackEvent({
            category: analytics.category,
            name: title,
            action: analyticsMeta?.action ?? "news search result click",
        });
        if (typeof reportClick === "function") {
            reportClick(url);
        }
    }, [analytics.category, analyticsMeta?.action, reportClick, title, trackEvent, url]);

    return (
        <Card className="NewsSearchResult">
            <Link href={url} onClick={handleLinkClick}>
                <ImageLoader srcList={images} alt="News Article Image" className={`newsLetterImage ${size}`}>
                    {publisherDisplayType === "blended-with-image" && !!publisherIcon && (
                        <div className="avatar-wrapper" title={publisherName}>
                            <Avatar shape="circle" className="avatar" src={publisherIcon} alt={publisherName} />
                        </div>
                    )}
                </ImageLoader>
            </Link>
            <div className="titleContainer">
                {publisherDisplayType === "top" && (
                    <div className="publisherDetails">
                        {publisherIcon && <Avatar className="publisherIcon" size={20} src={publisherIcon} />}
                        <Text className="publisherName">{publisherName}</Text>
                    </div>
                )}
                <Link className="title-link" href={url} onClick={handleLinkClick}>
                    <Title level={3} ellipsis={{ rows: titleMaxLines }} className={`title ${size}`}>
                        {title}
                    </Title>
                </Link>
                {(size === "large" || showDescription) && (content || description) ? (
                    <span
                        className={`description ${showDescription && size === "large" ? "description--large" : ""}`}
                        dangerouslySetInnerHTML={{ __html: content || description }}
                    />
                ) : null}
                <div className="articleInfo">
                    {hasPublisherLabel && (
                        <div className={`label-section ${size}`}>
                            <Text className="label pro">{article.publisherTypes![0]?.toUpperCase()}</Text>
                        </div>
                    )}
                    {!!bias && !hasPublisherLabel && (
                        <div className={`label-section ${size}`} data-testid="news-result-label">
                            {bias === "left" && (
                                <>
                                    <LeftIcon className="donkey" />
                                    <Text className="label left">LEFT</Text>
                                </>
                            )}
                            {bias === "middle" && <Text className="label middle">MIDDLE</Text>}
                            {bias === "right" && (
                                <>
                                    <RightIcon className="elephant" />
                                    <Text className="label right">RIGHT</Text>
                                </>
                            )}
                        </div>
                    )}
                    {datePublishedFormatted && <span className={`datePublished ${size}`}>{datePublishedFormatted}</span>}
                    {showUrl && (
                        <Link className="url" href={url}>
                            {url}
                        </Link>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default memo(NewsSearchResult);
